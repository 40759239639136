import { Grid, Box, Paper, Tab, Tabs, withStyles, Button } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";
import CommonGrid from "../../../../components/CommonGrid";
import machineService from "../../../../services/machineService";
import productService from "../../../../services/productService";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { NumericEditor } from "../../../../components/cellRenderers/NumericEditor";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { format } from 'date-fns';



export default function MachineProductCycleTimeAdd(
    { Footer = () => { },
        closeForm = () => { },
    }
) {

    const [rows, setRows] = useState([]);
    const [partOptions, setPartOptions] = useState([]);
    const [partSelected, setPartSelected] = useState(null);
    const { globalPlant } = useContext(PlantContext);
    const { currentUser } = useContext(AuthContext);
    const [validFrom, setValidFrom] = useState(new Date());
    const [existingData, setExistingData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const { v4: uuidv4 } = require("uuid");


    useEffect(() => {
        productService.getAProductByPlant(globalPlant?.globalPlant?.id)
            .then(res => {
                setPartOptions(res?.data ?? []);
            })
        machineService.getMachineProductCT(
            {
                client_id: currentUser.client_id,
                plant_id: globalPlant?.globalPlant?.id
            })
            .then((res) => {
                const temp1 = res?.data ?? [];
                const temp2 = temp1.filter((item) => item?.enabled === 1);
                setExistingData(temp2 ?? [])
            })
    }, [])

    useEffect(() => {
        if (partSelected) {
            const machineIds = existingData
                .filter((item) => item?.part_id === partSelected?.id)

            machineService.getProductMachineDetail(partSelected?.id, currentUser?.client_id)
                .then(res => {
                    const temp = res?.data ?? [];
                    const temp2 = temp.filter((item) => item?.level === -1);

                    const temp3 = temp2.map((item) => {
                        const match = machineIds.find(
                            (machineItem) =>
                                machineItem.machine_id === item.machine_id &&
                                machineItem.part_id === item.part_id
                        );

                        return match
                            ? {
                                ...item,
                                cycle_time: match.cycle_time,
                                fully_automated: match.fully_automated
                            }
                            : item;
                    });


                    setRows(temp3);
                });
        }
    }, [partSelected])


    function onGridReady(params) {
        setGridApi(params?.api);
    }

    function handleSubmit() {

        let payload = [];
        gridApi.forEachNode((node) => {
            if (node.data && node.data.cycle_time != null) {
                payload.push({
                    client_id: currentUser.client_id,
                    valid_from: format(new Date(validFrom), 'yyyy/MM/dd'),
                    plant_id: globalPlant?.globalPlant?.id,
                    id: uuidv4(),
                    part_id: node.data.part_id,
                    machine_id: node.data.machine_id,
                    cycle_time: parseInt(node.data.cycle_time),
                    created_by: currentUser?.id,
                    fully_automated: node.data.fully_automated

                })
            };
        });


        machineService.createMachineProductCT(payload)
            .then((res) => {
                closeForm();
            })

    }

    const CheckboxRenderer = (props) => {
        const isChecked = props.value === 1;
        const handleChange = (event) => {
            props.setValue(event.target.checked ? 1 : 0);
        };
        return (<input
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
        />)
    };

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 120,
        },

        {
            field: "machine_name",
            headerName: "Machine Name",
            filter: "agTextColumnFilter",
            minWidth: 200,
            resizable: true,
            valueGetter: (params) => {
                return params?.data?.machine_detail
            }
        },

        {
            field: "cycle_time",
            headerName: "Cycle Time (In Sec)",
            minWidth: 200,
            resizable: true,
            editable: (params) => !params.data.cycle_time,
            cellEditor: "numericEditor",
        }
        ,

        {
            field: "fully_automated",
            headerName: "Fully Automated",
            minWidth: 100,
            resizable: true,
            editable: (params) => !params.data.cycle_time,
            cellRenderer: CheckboxRenderer
        }


    ]



    return (

        <Grid container spacing={2}>

            <Grid item xs={7}>

                <DGSelect
                    label="Part Number"
                    options={partOptions}
                    getOptionLabel={(option) => option.part_number + " - " + option.part_name}
                    value={partSelected}
                    onChange={(e) => { setPartSelected(e) }}

                />

            </Grid>


            <Grid item xs={5}>

                <DGDateInput
                    variant="outlined"
                    label="Valid From"
                    value={validFrom}
                    onChange={(value) => setValidFrom(value)}
                />

            </Grid>

            <Grid item xs={12} style={{ "padding": "5px", "margin-top": "10px" }}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    needExport={false}
                    gridheight={125}
                    onGridReady={onGridReady}
                />
            </Grid>

            <Footer onSave={handleSubmit} />

        </Grid>

    )

}