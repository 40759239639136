/* eslint-disable react-hooks/exhaustive-deps */
import {
  AppBar,
  Button,
  Divider,
  Grid,
  TextareaAutosize,
  Toolbar,
} from "@material-ui/core";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { array, date, object, string } from "yup";
import { NumericEditor } from "../../../../components/cellRenderers/NumericEditor";
import CommonGrid from "../../../../components/CommonGrid";
import { DGDateTimePicker } from "../../../../components/shared/DGDateTimePicker";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { AuthContext } from "../../../../context/AuthContextProvider";
import characterService from "../../../../services/characterService";
import dateService from "../../../../services/dateService";
import productionInspection from "../../../../services/productionInspection";
import plantService from "../../../../services/plantService";
import productionMgmtService from "../../../../services/productionMgmtService";
import { PlantContext } from "../../../../context/PlantContextProvider";
import userService from "../../../../services/userService";
import errorLog from "../../../../services/errorLog";
import machineService from "../../../../services/machineService";

export function RequestForm({
  Footer = () => null,
  closeForm = () => { },
  componentProps = null,
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const [gridApi, setGridApi] = useState(null);
  const [plantOptions] = useState([])
  const [plant_id_i, setPlant_id_i] = useState(null)
  const { globalPlant } = useContext(PlantContext);
  const [toolFlag, setToolFlag] = useState(null);
  const { v4: uuidv4 } = require("uuid");


  let form = useFormik({
    initialValues: {
      plant: null,
      part: null,
      plan: null,
      plant_id: null,
      production_date: new Date(),
      production_time: new Date(),
      plantOptions: [],
      partOptions: [],
      planOptions: [],
      gridData: [],
      plant_flag: null,
      tool: null,
      comments: "",
      machineOptions: [],
      machineSelected: null,
      tempOptions1: [],
      machineOptionsFlag: false,
      machineReleaseFlag: 0,
      intialData: [],


    },
    validationSchema: object({
      plant: object()
        .shape({ id: string().required(), name: string().required() })
        .required(),
      part: object()
        .shape({
          part_id: string().required(),
          part_name: string().required(),
          part_number: string().required(),
        })
        .required(),
      plan: object()
        .shape({
          id_plan: string().required(),
          plan_id: string().required(),
          plan_name: string().required(),
        })
        .required(),
      production_date: date().required(),
      production_time: date().required(),
      gridData: array().min(1).required(),

      machineSelected: object()
        .nullable()
        .when('machineReleaseFlag', {
          is: 1,
          then: object()
            .required('Machine selection is required')
            .typeError('Machine selection must be valid'),
          otherwise: object().nullable(),
        }),

    }),
    onSubmit: createRequest,
  });

  useEffect(() => {
    plantService.getDefaultPlant(currentUser?.id)
      .then((res) => {
        const plantOptions = (res?.data ?? []).slice(1);
        form.setFieldValue("plantOptions", plantOptions);
      })
  }, []);



  useEffect(() => {
    if (form.values.plantOptions.length < 2 || currentUser.user_role > 3) {
      form.setFieldValue("plant", (globalPlant?.globalPlant));
      form.setFieldValue("plant_flag", 0)

    } else {
      if (currentUser.default_plant) {
        const defaultPlant = form.values.plantOptions.find(plant => plant.default_flag === 1)
        form.setFieldValue("plant", defaultPlant)
        form.setFieldValue("plant_flag", 1)

      } else {
        const defaultPlant = form.values.plantOptions[0]
        form.setFieldValue("plant", defaultPlant)
        form.setFieldValue("plant_flag", 2)
      }
    }

    if (componentProps?.source === 3) {
      const temp1 = form.values.plantOptions.find(plant => plant.id === componentProps?.plant_id)
      form.setFieldValue("plant", temp1)
    }

  }, [form.values.plantOptions]);

  useEffect(() => {
    if (form.values.plant !== null && form.values.plant?.id !== undefined) {
      loadParts();
    }
  }, [form.values.plant]);

  useEffect(() => {
    if (form.values.part !== null) {
      loadPlans()
    }
  }, [form.values.part])

  useEffect(() => {
    if (form.values.plan !== null) {
      loadGridData()
      loadTool()
      mouldtoolFlag()

    }
  }, [form.values.plan])


  useEffect(() => {
    if (form.values.tool !== null) {
      mouldtoolFlag()
    }
  }, [form.values.tool])


  function loadParts() {
    productionInspection
      .getProductionFilter({
        client_id: currentUser?.client_id,
        plant_id: currentUser?.default_plant,
        query_id: 2
      })
      .then((res) => {
        form.setFieldValue("partOptions", res?.data ?? [])
        if (componentProps?.source === 3) {
          const temp = res?.data.find(part => part.part_id === componentProps?.part_id)
          form.setFieldValue("part", temp)
        }
      });
  }


  function spindleList() {
    machineService.getPlantMachineSpindleList(
      {
        plant_id: form.values.plant?.id,
        client_id: currentUser?.client_id,
        part_id: form.values.part?.part_id
      }
    ).then((res) => {
      form.setFieldValue("tempOptions1", res?.data)
    })


  }



  useEffect(() => {
    const tempOptions = [];


    form.values.gridData.forEach((item) => {
      const { machine_options } = item;
      if (machine_options) {
        tempOptions.push(machine_options);
      }
    });



    const mergedArray1 = tempOptions.reduce((acc, curr) => acc.concat(curr), []);
    const mergedArray = [...mergedArray1, ...form.values.tempOptions1];


    const uniqueArray = mergedArray.filter((item, index, self) =>
      index === self.findIndex((t) => t.machine_id === item.machine_id)
    );
    const sortedArray = uniqueArray.sort((a, b) => a.machine_name.localeCompare(b.machine_name));

    form.setFieldValue("machineOptions", sortedArray);

  }, [form.values.gridData])


  useEffect(() => {
    if (form.values.machineSelected !== null && form.values.machineSelected !== "") {

      const updatedGridData = form.values.intialData.map((item) => {
        const machineExists = Array.isArray(item.machine_options) &&
          item.machine_options.some(option =>
            option.machine_id === form.values.machineSelected.machine_id
          );


        if ((machineExists || form.values.machineReleaseFlag === 1) && item.product_char_flag === 1) {
          return {
            ...item,
            machine_default: form.values.machineSelected
          };
        } else {

          return {
            ...item,
            machine_default: null
          };
        }
      });


      const updatedGridData1 = updatedGridData.filter(
        (item) =>
          item.product_char_flag !== 0 ||
          item.machine_default1?.machine_id === form.values.machineSelected?.machineid_id
      );

      form.setFieldValue("gridData", updatedGridData1);
    }
  }, [form.values.machineSelected]);




  function createRequest(showMeasureForm = false) {
    if (!form.isValid) {
      return;
    }
    let payload = [];
    gridApi?.forEachNode((node) => {
      let exp = node?.data;
      if (exp.product_char_flag === 0) {
        if (form.values.machineReleaseFlag === 0 || form.values.machineSelected === null) {
          var temp1 = exp?.machine_default1?.machine_id
        }
        else {
          var temp1 = form.values.machineSelected?.machineid_id
        }
      }
      else {
        var temp1 = exp?.machine_default?.machine_id
      }


      payload.push({
        part_id: form.values.part?.part_id,
        plan_id: form.values.plan?.plan_id,
        batch_details: form.values.batch,
        machine_spindle_id: temp1,
        gauge_family_id: exp?.gauge_family_id,
        character_id: exp?.character_id,
        sample_size: exp?.sample_size,
        reduced_tolerance: exp?.reduced_tolerance,
        process_number: exp?.process_number,
        production_date: dateService.getDateInSqlFormat(
          form.values.production_date
        ),
        production_time: moment(form.values.production_date).format("HH:mm:ss"),
        created_by: currentUser?.id,
        prod_char_flag: exp?.product_char_flag,
        comments: form?.values.comments,
        machine_release_machineId: form.values.machineSelected?.machineid_id,
      });
    });

    productionInspection
      .createProductionInspection(payload)
      .then((res) => {
        if (componentProps?.source === 3) {
          productionMgmtService.createRecordInspection({
            production_record_id: componentProps?.production_record_id,
            production_inspection_id: res?.data,
            created_by: currentUser?.id,
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id,

          })

        }
        updateAlert({
          open: true,
          message: "Production Inspection request created.",
          type: "success",
        });
        if (showMeasureForm) {
          closeForm(false, res?.data);
        } else {
          closeForm(false, null);
        }
      })
      .catch((err) => {
        updateAlert({
          open: true,
          message: "Failed to create Production Inspection Request.",
          type: "success",
        })
        let payload = {
          id: uuidv4(),
          error_description: err.message,
          error_location: "createProductionInspection",
          client_id: currentUser.client_id,
          user_id: currentUser.id,
          entity_id: form.values.plan?.plan_id,
        };
        errorLog.createErrorLog(payload)
      });
  }

  function loadPlans() {
    productionInspection
      .getProductionFilter({
        part_id: form.values?.part?.part_id,
        query_id: 3
      })
      .then((res) => {
        form.setFieldValue("planOptions", res?.data ?? []);
        spindleList();

      });
  }

  function loadGridData(val) {
    productionInspection
      .getProductionFilter({
        part_id: form.values.part?.part_id,
        production_inspection_plan_id: form.values.plan?.plan_id,
        query_id: 4
      })
      .then((res) => {
        form.setFieldValue(
          "gridData",
          res?.data?.map((exp) => ({ ...exp, machine_spindle: {} })) ?? []
        );
        form.setFieldValue(
          "intialData",
          res?.data?.map((exp) => ({ ...exp, machine_spindle: {} })) ?? []
        );
        form.setFieldValue("machineReleaseFlag", res?.data[0]?.machine_release)
      })
  }



  function loadTool() {
    productionInspection
      .getProductionFilter({
        part_id: form.values.part?.part_id,
        production_inspection_plan_id: form.values.plan?.plan_id,
        query_id: 5
      })
      .then((res) => {
        form.setFieldValue("tool", res?.data[0])
        form.setFieldValue("tool", {
          tool_name: res?.data?.[0]?.tool_name,
          tool_number: res?.data?.[0]?.tool_number,
          machine_name: res?.data?.[0]?.machine_name,
          machine_number: res?.data?.[0]?.machine_number
        })
      })
      .catch((err) => {
        let payload = {
          id: uuidv4(),
          error_description: err.message,
          error_location: "getProductionFilter",
          client_id: currentUser.client_id,
          user_id: currentUser.id,
          entity_id: form.values.plan?.plan_id,
        };
        errorLog.createErrorLog(payload)

      })
  };



  function mouldtoolFlag() {
    // console.log(form.values.tool)
    if (form.values?.tool?.machine_name !== null) {
      setToolFlag(1);
    } else {
      setToolFlag(0);
    }
  }


  //Need to be covered in second Round
  function loadMachineSpindle(id) {
    return characterService.getCharacterMachine(id);
  }

  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
      resizable: true,
    },
    {
      field: "process_number",
      headerName: "Process Number",
      filter: "agTextColumnFilter",
      minWidth: 100,
      resizable: true,
    },
    {
      field: "character_name",
      headerName: "Characterstics",
      filter: "agTextColumnFilter",
      minWidth: 200,
      resizable: true,
    },
    {
      field: "specification",
      headerName: "Specification",
      filter: "agTextColumnFilter",
      minWidth: 200,
      resizable: true,
      valueGetter: (params) => {
        return ` ${params?.data?.specification ?? ""}  ${params?.data?.upper_specification ?? ""}  ${params?.data?.lower_specification ?? ""}`;
      },
    },

    {
      field: "measurement_type_name",
      headerName: "Measurement",
      filter: "agTextColumnFilter",
      minWidth: 100,
      resizable: true,
    },

    {
      field: "machine_default",
      headerName: "Machine Spindle",
      filter: "agTextColumnFilter",
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorPopup: true,
      valueFormatter: (params) => {
        return params?.value?.machine_name;
      },
      cellEditorParams: (params) => {
        return {
          values: params?.data?.machine_options ?? [],
          cellHeight: 20,
          formatValue: (value) => value?.machine_name,
          searchDebounceDelay: 500,
        };
      },
      minWidth: 200,
      resizable: true,
    },
    {
      field: "sample_size",
      headerName: "No of Samples",
      filter: "agTextColumnFilter",
      minWidth: 150,
      resizable: true,
      editable: true,
      cellEditor: NumericEditor,
    },
    {
      field: "gauge_name",
      headerName: "Gauge",
      filter: "agTextColumnFilter",
      minWidth: 150,
      resizable: true,
      valueGetter: (params) =>
        params?.data?.gauge_number
          ? `${params?.data?.gauge_number}-${params?.data?.gauge_name}`
          : "",
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item md={3} sm={3} lg={3}>
        <DGSelect
          id="plant"
          label="Plant"
          options={form.values.plantOptions}
          getOptionLabel={(option) => option.name}
          value={form.values.plant}
          onChange={(val) => {
            form.setFieldValue("plant", val)
            form.setFieldValue("part", null)
            form.setFieldValue("gridData", [])
          }}
          required
          disabled={form.values.plant_flag === 0 || componentProps?.source === 3}
        />

      </Grid>
      <Grid item md={3} sm={3} lg={3}>
        <DGSelect
          id="part"
          label="Part"
          options={form.values.partOptions}
          getOptionLabel={(opt) => `${opt?.part_number}-${opt?.part_name}`}
          value={form.values.part}
          onChange={(val) => {
            form.setFieldValue("part", val);
            if (val === null) {
              form.setFieldValue("gridData", [])
              form.setFieldValue("plan", null)
              form.setFieldValue("machineOptions", [])
              form.setFieldValue("machineSelected", null)
            }
          }}
          required
          error={form.touched.part && Boolean(form.errors.part)}
          disabled={componentProps?.source === 3}
        />
      </Grid>
      <Grid item md={3} sm={3} lg={3}>
        <DGSelect
          id="plan"
          label="Plan"
          options={form.values.planOptions}
          getOptionLabel={(opt) => `${opt?.id_plan}-${opt?.plan_name}`}
          value={form.values.plan}
          onChange={(val) => {
            form.setFieldValue("plan", val);
            form.setFieldValue("machineSelected", null)
            if (val === null) {
              form.setFieldValue("gridData", [])
              form.setFieldValue("machineOptions", [])
              form.setFieldValue("machineSelected", null)
              // form.setFieldValue("tool", [])
            }
          }}
          required
          error={form.touched.plan && Boolean(form.errors.plan)}
        />
      </Grid>
      <Grid item md={3} sm={3} lg={3}>
        <DGDateTimePicker
          label="Production Date"
          format="yyyy/MM/dd HH:mm"
          value={form.values.production_date}
          onChange={(val) => {
            form.setFieldValue("production_date", val);
          }}
          required
          error={
            form.touched.production_date && Boolean(form.errors.production_date)
          }
        />
      </Grid>

      <Grid item md={4} sm={4} lg={4}>
        <DGInput
          id="batch"
          label="Batch Details"
          value={form.values.batch}
          onChange={form.handleChange}
          isFormik
          error={form.touched.batch && Boolean(form.errors.batch)}
        />
      </Grid>

      <Grid item md={4} sm={4} lg={4}>

        <DGSelect
          id="machineSelected"
          label="Machine Release"
          value={form.values.machineSelected}
          //onChange={form.handleChange}
          options={form.values.machineOptions}
          getOptionLabel={(opt) => `${opt?.machinename_name ?? ''} - ${opt?.machine_name ?? ''}` || null}
          onChange={(val) => {
            form.setFieldValue("machineSelected", val);
            if (val === null) {
              form.setFieldValue("machineSelected", null)
            }
          }}
          disabled={form.values.machineReleaseFlag === 0}
        />
      </Grid>


      {
        toolFlag === 1 && (
          <Grid item md={4} sm={4} lg={4}>
            <DGInput
              label="Mould Details"
              value={
                `${form.values?.tool?.tool_name} ${form.values.tool?.tool_number}  
              ${form.values?.tool?.machine_number} ${form.values.tool?.machine_name}`
              }
              disabled
            />
          </Grid>

        )
      }



      <Grid item md={12} sm={12} lg={12}>
        <CommonGrid
          rows={form.values.gridData}
          columns={columns}
          needExport={false}
          onGridReady={(params) => setGridApi(params?.api)}
          getRowStyle={(params) => {
            if (params?.data?.product_char_flag === 0) {
              return { backgroundColor: "#E5E7E9" };
            }
          }}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <TextareaAutosize
          id={"comments"}
          maxRows={4}
          minRows={3}
          className="w-100 border h-100"
          aria-label="maximum height"
          placeholder="Comments"
          //defaultValue=""
          value={form.values.comments}
          onChange={form.handleChange}
        //  isFormik
        />
      </Grid>

      <AppBar position="fixed" style={{ width: 1150, top: "auto", bottom: 0 }}>
        <Divider />
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            style={{ margin: 4 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => closeForm()}
          >
            CANCEL
          </Button>
          {componentProps?.source === 0 || componentProps?.source === 3 && (
            <Button
              style={{ margin: 4 }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => createRequest(false)}
              disabled={!form.isValid}
            >
              REQUEST
            </Button>
          )}
          <Button
            style={{ margin: 4 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => createRequest(true)}
            disabled={!form.isValid}
          >
            MEASURE
          </Button>
        </Toolbar>
      </AppBar>
    </Grid >
  );
}
