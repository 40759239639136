import React, { useEffect, useState, useContext } from "react";
//import { ManualEntry } from "../../../depreciated/_ManualEntry";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Formik, useFormik } from "formik";
import {
    Box, Divider, Grid, Button, Step,
    StepLabel, Stepper, AppBar, Toolbar
} from "@material-ui/core";
import { CommonGrid } from "../../../../components/CommonGrid";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGDateTimePicker } from "../../../../components/shared/DGDateTimePicker";
import productService from "../../../../services/productService";
import characterService from "../../../../services/characterService";
import machineService from "../../../../services/machineService";
import csrTemplateService from "../../../../services/csrTemplateService";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { array, object, string, number } from "yup";
import moment from "moment";
import { AuthContext } from "../../../../context/AuthContextProvider";
import spcService from "../../../../services/spcService";
import Loader from "../../../../components/Loader";



export default function ControlLimitStudy({
    componentProps = null,
    onSuccess = () => { },
    setHeaderButtons = () => { },
    //closeForm = () => { },
}) {

    const { v4: uuidv4 } = require("uuid");
    const [activeStep, setActiveStep] = useState(0);
    const { currentUser } = useContext(AuthContext);
    const [cLEvalID, setCLEvalID] = useState(null)
    const [dataLoaded, setDataLoaded] = useState(false);

    let form = useFormik({
        initialValues: {
            loc_chart: "",
            loc_eval: "",
            dis_chart: "",
            dis_eval: "",
            guideline: "",
            machine_spindle: null,
            evaluation_date: null,
            approver_name: "",
            requested_by: "",
            part_detail: "",
            characterOptions: [],
            characteristics: null,
            machineOptions: [],
            evaluation_method_options: [],
            evaluation_method: null,
            client_id: null,
            csr_id: null,
            loc_chart_options: [],
            dis_chart_options: [],
            //cLEvalID: ""
        },
        validationSchema: object().shape({
            characteristics: object().required("Characteristics is required"),
            machine_spindle: object().required("Machine spindle is required"),
            evaluation_date: string().required("Evaluation date is required"),
            evaluation_method: object().required("Evaluation method is required"),
        }),
        // onSubmit: handleNext
    })


    useEffect(() => {

        if (componentProps) {
            productService.getPartDetails(componentProps).then((res) => {
                form.setFieldValue("part_detail", res?.data[0]?.part_number + "  " + res?.data[0]?.part_name)
                form.setFieldValue("client_id", res?.data[0]?.client_id)
                form.setFieldValue("csr_id", res?.data[0]?.evaluation_guidelines)

            })

            characterService.getACharactersByProductId(componentProps).then((res) => {

                console.log(res.data, "res.data")

                res.data = res.data.filter(
                    (item) => item.specification !== null && item.single_side_flag == 0
                );

                form.setFieldValue("characterOptions", res?.data)
            })

            csrTemplateService.getCSRPart(componentProps).then((res1) => {

                form.setFieldValue("template_name", res1?.data[0]?.template_name)
                form.setFieldValue("loc_chart",
                    {
                        id: res1?.data[0]?.loc_chart,
                        name: res1?.data[0]?.loc_chart_name
                    }
                )
                form.setFieldValue("dis_chart",
                    {
                        id: res1?.data[0]?.dis_chart,
                        name: res1?.data[0]?.dis_chart_name
                    }
                )
            })

            form.setFieldValue("evaluation_method_options", [
                { id: 1500, name: "Manual Entry of Control Limit" },
                { id: 1501, name: "Time-dependent distribution" },
                // { id: 1503, name: "User Defined Charts" }
            ])

            form.setFieldValue("loc_chart_options", [
                { id: 101, name: "Mean Chart" },
                { id: 102, name: "Median Chart" },
            ])

            form.setFieldValue("dis_chart_options", [
                { id: 151, name: "Standard Deviation Chart" },
                { id: 152, name: "Range Chart" },
            ])

            setCLEvalID(uuidv4())
        }

    }, [componentProps])

    useEffect(() => {

        if (form.values.characteristics) {
            machineService.getASpindleChar(form.values.characteristics.id).then((res) => {
                form.setFieldValue("machineOptions", res?.data)
            })
        }

    }, [form.values.characteristics])

    function saveForm() {
        let payload = {
            id: cLEvalID,
            character_id: form.values.characteristics.id,
            client_id: form.values.client_id,
            part_id: componentProps,
            machine_spindle: form.values.machine_spindle.machine_spindle_id,
            evaluation_date: form.values.evaluation_date,
            studies_id: form.values.evaluation_method.id,
            outlier_eval: 503,
            loc_eval: form.values.loc_chart.id,
            dis_eval: form.values.dis_chart.id,
            csr_id: form.values.csr_id,
            created_by: currentUser?.id,
            enabled: 1,
            id_result: null
        }

        spcService.createSPClimitseval(payload)
            .then((res) => {
                if (res.status !== 200) {
                    setDataLoaded(true);
                }
            })

    }



    function handleNext() {
        saveForm()
        onSuccess(true, cLEvalID, form.values.evaluation_method.id)
    }

    if (dataLoaded) {
        return <Loader />;
    }



    return (

        <>

            <Grid container spacing={3}>
                <Grid item md={6} sm={6} xs={12}>
                    <DGInput

                        label="Part Number"
                        value={form?.values?.part_detail}
                    />
                </Grid>

                <Grid item md={6} sm={6} lxs={12}>
                    <DGSelect
                        label="Characteristics"
                        value={form?.values?.characteristics}
                        options={form.values.characterOptions}
                        error={form?.touched?.characteristics && form?.errors?.characteristics}
                        getOptionLabel={(opt) =>
                            `${opt?.name} ${opt?.specification !== null ? opt?.specification : ""
                            } ${opt?.upper_specification !== null
                                ? opt?.upper_specification
                                : ""
                            } ${opt?.lower_specification !== null
                                ? opt?.lower_specification
                                : ""
                            } `
                        }
                        onChange={(val) => {
                            form?.setFieldValue("characteristics", val);
                            form?.setFieldValue("machine_spindle", null);
                        }}
                        required
                    />
                </Grid>

                <Grid item md={4} sm={4} xs={12}>
                    <DGSelect
                        label="Machine"
                        value={form?.values?.machine_spindle}
                        options={form.values.machineOptions}
                        error={form?.touched?.machine_spindle && form?.errors?.machine_spindle}
                        getOptionLabel={(opt) => `${opt?.machine_spindle_name}`}
                        onChange={(val) => {
                            form?.setFieldValue("machine_spindle", val);
                        }}
                    />
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                    <DGDateInput
                        error={form?.touched?.evaluation_date && form?.errors?.evaluation_date}
                        label="Evaluation Date"
                        value={form?.values?.evaluation_date}
                        format="dd/MM/yyyy"
                        onChange={(date) => {
                            form?.setFieldValue("evaluation_date", moment(date).format("YYYY-MM-DD"))
                        }}
                    />
                </Grid>
                <Grid item md={5} sm={5} xs={12}>
                    <DGInput
                        // disabled
                        label="Guidelines"
                        value={form?.values?.template_name}
                    />
                </Grid>

            </Grid>

            <Grid container spacing={3} style={{ marginTop: '10px' }}>

                <Grid item md={12} sm={12} xs={12}>
                    <DGSelect
                        label="Evaluation Method"
                        value={form?.values?.evaluation_method}
                        options={form.values.evaluation_method_options}
                        getOptionLabel={(opt) => `${opt?.name}`}
                        error={form?.touched?.evaluation_method && form?.errors?.evaluation_method}
                        onChange={(val) => {
                            form?.setFieldValue("evaluation_method", val);
                        }}


                    />
                </Grid>

            </Grid>

            <Grid container spacing={3} style={{ marginTop: '10px' }}>
                <Grid item md={6} sm={6} xs={12}>
                    <DGInput
                        label="Location Chart"
                        value={form?.values?.loc_chart.name}
                    />
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                    <DGInput
                        //disabled
                        label="Dispersion Chart"
                        value={form?.values?.dis_chart.name}
                    />
                </Grid>

            </Grid>


            <AppBar position="fixed" style={{ width: 800, top: "auto", bottom: 0 }}>
                <Divider />
                <Toolbar>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                        style={{ margin: 4 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => onSuccess(false)}
                    >
                        CANCEL
                    </Button>
                    <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleNext}
                        disabled={!form.isValid}

                    >
                        NEXT
                    </Button>
                </Toolbar>
            </AppBar>

        </>

    )

}