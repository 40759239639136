import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import React, { useEffect, useState } from "react";
import HC_more from "highcharts/highcharts-more";
import { Typography } from "@material-ui/core";
HC_more(Highcharts);
highchartsDumbbell(Highcharts);

export function GraphInfo({ graph_info = [] }) {
  useEffect(() => {
    if (graph_info) {
      setChartOptions((prev) => ({
        ...prev,
        series: [
          {
            name: "Measured Values",
            data: graph_info,
          },
        ],
      }));
    }
  }, [graph_info]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "dumbbell",
      inverted: false,
    },

    legend: {
      enabled: false,
    },
    title: {
      text: "",
    },

    tooltip: {
      shared: true,
    },

    xAxis: {
      type: "category",
      title: {
        text: "Part Number",
      },
    },
    yAxis: {
      title: {
        text: "Measured Values",
      },
    },

    series: [
      {
        name: "Part Number",
        data: graph_info,
      },
    ],
  });

  return (
    <div style={{
      border: "1px solid black",
      boxSizing: "border-box",
      height: "100%",
    }}>
      <div>
        <Typography variant="h4"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F8E6D8",
            padding: "5px",
          }}>
          <strong >
            Data Plot
          </strong>
        </Typography>
      </div>

      <div style={{ padding: "10px" }}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  );
}
