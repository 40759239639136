import React, { useEffect } from "react";
import { useState } from "react";
import CommonGrid from "../../../../../components/CommonGrid";
import {
  Typography, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Divider
} from "@material-ui/core";
import { padding } from "polished";

export function DataInfo({ data_info = [] }) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let data = JSON?.parse(data_info ?? "[]") ?? [];
    let rowData = [];
    let columnData = [];
    let formattedData = [];
    data.map((appraiser, i) => {
      appraiser?.trials?.map((trl, j) => {
        trl = trl?.map((exp) => {
          let obj = {};
          obj[`${appraiser["Operator name"]}-${i}-${j}`] = exp;
          return obj;
        });
        formattedData?.push(trl);
      });
    });
    for (let i = 0; i < formattedData[0]?.length ?? 0; i++) {
      formattedData.map((exp) => {
        rowData[i] = { ...rowData[i], ...exp[i] };
      });
    }
    data?.map((exp, k) => {
      let cl = { headerName: exp["Operator name"], children: [] };
      exp?.trials?.map((row, i) => {
        let childrens = [];
        childrens.push({
          field: `${exp["Operator name"]}-${k}-${i}`,
          headerName: `Trial ${i + 1}`,
          minWidth: 100,
        });
        cl["children"] = [...cl["children"], ...childrens];
      });
      columnData?.push(cl);
    });
    setColumns(columnData);
    setRows(rowData);
  }, [data_info]);

  return (
    <div style={{
      border: "1px solid black",
      boxSizing: "border-box",
      height: "100%",
    }}>
      <Typography variant="h4"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F8E6D8",
          padding: "5px",
        }}>
        <strong >
          Input Data
        </strong>
      </Typography>

      <div style={{ padding: "10px" }}>
        <CommonGrid
          needExport={true}
          rows={rows}
          columns={columns}
          gridheight={500}
          fixedHeight
          id="grr1"
        />
      </div>

    </div>
  );
}
