/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Paper } from "@material-ui/core";
import machineService from "../../../services/machineService";
import CommonGrid from "../../../components/CommonGrid";
import { Link } from "react-router-dom";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider"
import ActiveInActive from "../../../components/ActiveInActive";
import { AuthContext } from "../../../context/AuthContextProvider";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../components/DGDrawer";
import { PlantContext } from "../../../context/PlantContextProvider";
import { use } from "react";
import { set } from "date-fns";
import { pl } from "date-fns/locale";


const MachineProcessList = () => {
  const [rows, setRows] = useState([]);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [formOpen, setFormOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { setHeaderPageTitle } = useContext(PageTitleContext);
  const { globalPlant } = useContext(PlantContext);
  const [plant, setPlant] = useState(globalPlant);


  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: loadAllMachines,
    }));

  }, []);

  useEffect(() => {
    setHeaderPageTitle(`Machine Process Parameter`);

  }, [])

  useEffect(() => {

    setPlant(globalPlant?.globalPlant?.id);
    loadAllMachines(globalPlant?.globalPlant?.id);

  }, [globalPlant])



  function loadAllMachines(plantID) {
    return machineService.getMachines(currentUser?.client_id)
      .then((res) => {
        console.log(res.data.data);
        const temp = res.data.data
        if (plantID === currentUser?.client_id) {
          setRows(temp ?? []);
          return;
        }
        else {
          const temp1 = temp.filter((item) => item.plant_id === plantID)
          setRows(temp1 ?? []);
        }

      });
  }

  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      maxWidth: 150
    },
    {
      field: "machine_number",
      headerName: "Number",
      floatingFilter: true,
      filter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <>
            <Link
              style={{ color: "#2196f3" }}
              variant="body2"
              to={`/machine-parameter/${params.data.id}`}
            >
              {params.value}
            </Link>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      floatingFilter: true,
      filter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "plant.name",
      headerName: "Plant Name",
      floatingFilter: true,
      filter: true,
      filter: "agTextColumnFilter",
    }
  ];

  return (
    <>
      <Paper>
        <CommonGrid
          rows={rows}
          columns={columns}
          gridheight={100}
        />
      </Paper>
      {/* <DGDrawer
        Component={MachineForm}
        isOpen={formOpen}
        drawerWidth={500}
        setOpen={setFormOpen}
        title={"New Machine"}
        componentProps={{ loadAllMachines }}
        needSave
        subtitle={moment(new Date()).format("DD/MM/YYYY")}
      /> */}
    </>
  );
};

export default MachineProcessList;
