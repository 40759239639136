import React, { useContext, useEffect, useState } from 'react'
import CommonGrid from '../../../components/CommonGrid';
import gaugeService from '../../../services/gaugeService';
import { AuthContext } from '../../../context/AuthContextProvider';
import { Grid, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from '../../../components/DGDrawer';
import { rgba } from "polished";
import { blueGrey } from '@material-ui/core/colors';
import { ProductMachineDetailList } from "./productMachineList"
import { ProductMachineLink } from "./productMachineLink"
import { PlantContext } from "../../../context/PlantContextProvider";
import productService from '../../../services/productService';
import ActiveInActive from '../../../components/ActiveInActive';
import StatusFilter from '../../../components/shared/StatusFilter';




export function ProductMachine() {
    //style Definition
    //  const classes = useStyles();
    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);

    const [formOpen, setFormOpen] = useState(false);
    const [formLinkOpen, setFormLinkOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [subtitle, setSubtitle] = useState("");

    const globalPlantId = globalPlant?.globalPlant?.id

    useEffect(() => {
        productService.getAssetPartPlantList(
            globalPlantId,
            currentUser?.client_id)
            .then(res => {
                setRows(res?.data ?? []);
            })
    }, [globalPlantId])

    const columns = [{
        field: "serial_number",
        headerName: "#",
        valueGetter: "node.rowIndex + 1",
        maxWidth: 100,
        sorting: true,
    },
    {
        field: "part_name",
        headerName: "Part Name",
        minWidth: 200,
        resizable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sorting: true,
        cellRenderer: (params) => {
            return (
                <Button
                    size="small"
                    color="primary"
                    onClick={() => loadForm(params?.data)}
                >
                    {`${params?.data?.part_name}`}
                </Button>
            );
        },
    },
    {
        field: "plant_name",
        headerName: "Plant Name",
        minWidth: 200,
        resizable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        sorting: true,
    },

        /*     {
                field: "enabled",
                headerName: "Status",
                minWidth: 150,
                filter: "agNumberColumnFilter",
                floatingFilter: true,
                suppressMenu: true,
                floatingFilterComponent: StatusFilter,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: (params) => (
                    <ActiveInActive
                        status={params?.data?.enabled}
                    />
                ),
            }, */
    ]



    function ButtonComponent() {
        return (
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        LoadLinkForm();
                    }}
                    style={{ marginLeft: 3 }}
                >
                    ADD
                </Button>
            </>
        );
    }

    function LoadLinkForm() {
        setFormLinkOpen(true);
    }

    const getRowStyle = (params) => {
        const rowData = params.data;
        const customerFlag = rowData.customer_delivery;

        return customerFlag === 1
            ? { background: rgba(212, 230, 241, 0.1) }
            : {};
    };


    function loadForm(data) {
        setSubtitle(data?.request_name ?? "");
        setSelectedRow(data);
        setFormOpen(true);
    }



    return (
        <>
            <CommonGrid
                rows={rows}
                columns={columns}
                gridheight={90}
                ButtonComponent={ButtonComponent}
                getRowStyle={getRowStyle}
                suppressRowClickSelection={true}
                suppressCellSelection={true}
            />

            <DGDrawer
                Component={ProductMachineDetailList}
                title={selectedRow && selectedRow.part_name}
                isOpen={formOpen}
                setOpen={(state) => {
                    setSubtitle("Part Machine Details");
                    setFormOpen(state);
                }}
                subtitle={subtitle}
                drawerWidth={1000}
                componentProps={selectedRow}
            />

            <DGDrawer
                Component={ProductMachineLink}
                title="Link Product to Machine"
                isOpen={formLinkOpen}
                setOpen={(state) => {
                    setSubtitle("");
                    setFormLinkOpen(state);
                }}
                subtitle={subtitle}
                drawerWidth={600}
                needSave
            />


        </>
    );
}
