import { Button, Grid, makeStyles, Typography, Box, Divider } from "@material-ui/core";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { useFormik } from "formik";

import { StatusContext } from "../../../context/StatusContextProvider";
import { DGInput } from "../../../components/shared/DGInput";
import { format } from "date-fns";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import userService from "../../../services/userService";
import { DGSelect } from "../../../components/shared/DGSelect";
import CommonGrid from "../../../components/CommonGrid";
import cuttingToolService from "../../../services/cuttingToolService";






export function CToolReturn(
    {
        Footer = () => { },
        closeForm = () => { },
        componentProps = ''
    }
) {

    const { currentUser } = useContext(AuthContext)
    const { globalPlant } = useContext(PlantContext);
    const [rows, setRows] = useState([]);
    const { v4: uuidv4 } = require('uuid');
    const [requestId, setRequestId] = useState(0);



    const form = useFormik({
        initialValues: {
            returnDate: new Date(),
            returnQty: 0,
            returnReason: "",
            edgeLife: [],
            toolLife: 0,
            returnedByOptions: [],
            returnedBySelected: null,

            edgeLifeTotal: 0,
            ctoolStatusOptions: [
                { ctool_status_id: 101, ctool_status: "Active" },
                { ctool_status_id: 102, ctool_status: "Quarantine" },
                { ctool_status_id: 103, ctool_status: "Scrapped" },
            ],
            ctoolStatusSelected: null,

            returnComments: "",
            returnCommentsOptions: [],

        },

        onSubmit: onSave
    });


    useEffect(() => {
        userService.getOperatorTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,
        })
            .then((res) => {
                const data = res?.data;
                const data1 = data.map((item) => ({
                    user_name: `${item.operator_name} ${item.operator_id}`,
                    user_id: item.id,
                }));
                userService.getUsersByPlantId
                    (globalPlant?.globalPlant?.id,
                        currentUser.client_id
                    )

                    .then((res) => {
                        const data2 = res?.data
                        const data = [...data1, ...data2]
                        form.setFieldValue("returnedByOptions", data)

                        const data3 = data.filter((item) => item.user_id === componentProps?.issued_to)
                        form.setFieldValue("returnedBySelected", data3[0])

                    })

            })
        form.setFieldValue("returnCommentsOptions", ["Broken", "Worn Out", "End of Life",
            "Setup Change", "Others"]);

        cuttingToolService.getStatusMaxID({
            ctool_number_id: componentProps.cutting_tool_number,
            client_id: currentUser.client_id
        })
            .then((res) => {
                setRequestId(res.data[0].max_id)
            })

    }, []);

    useEffect(() => {
        if (componentProps.ctool_edges > rows.length) {
            const newRows = generateBlankRows(componentProps.ctool_edges);
            setRows((prevRows) => [...prevRows, ...newRows]);
        }
    }, [componentProps.ctool_edges]);

    const generateBlankRows = (numRows) => {
        return Array.from({ length: numRows }, () => ({}));
    };

    const columns = [
        {
            field: "serial_number",
            headerName: "Edge No",
            valueGetter: "node.rowIndex + 1",
        },

        {
            field: "edge_life",
            headerName: "Edge Life",
            editable: true,

        }

    ]

    function onCellValueChanged(params) {
        const { data } = params;
        let total = 0;
        rows.forEach((row) => {
            total += parseInt(row.edge_life) || 0;
        });
        form.setFieldValue("toolLife", total);
    }



    function onSave() {

        let payload = {
            return_date: format(new Date(form.values.returnDate), "yyyy/MM/dd"),
            return_time: format(new Date(form.values.returnDate), "HH:mm:ss"),
            returned_by: form.values.returnedBySelected.user_id,
            tool_life: form.values.toolLife,
            return_remarks: form.values.returnComments,
            ctool_edges_life: JSON.stringify(
                rows.map((row, index) => ({
                    edge_no: index + 1,
                    edge_life: row.edge_life,
                }))
            ),


        };

        let payload0 = {
            ctool_status: form.values.ctoolStatusSelected?.ctool_status_id,
            updated_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            comments: form.values.returnComments ?? ""

        };

        let payload2 = {
            id: uuidv4(),
            ctool_number_id: componentProps.ctool_number_id,
            ctool_status_id: form.values.ctoolStatusSelected?.ctool_status_id,
            ctoolnumber_newlife: null,
            user_id: currentUser.id,
            vendor_id: form.values.vendorSelected?.id ?? null,
            refurbish_initiated_by: null,
            refurbish_initiated: null,
            refurbish_approved_by: null,
            refurbish_approved: null,
            enabled: 1,
            request_id: (requestId + 1),
            comments: form.values.returnComments ?? ""

        };

        cuttingToolService.modifyCToolNumber(componentProps.ctool_number_id, payload0)
            .then((res) => {
                if (res.status === 200) {
                    cuttingToolService.updateCuttingToolIssue(componentProps.id, payload)
                        .then((res) => {
                            if (res.status === 200) {
                                cuttingToolService.createCtoolStatusChange(payload2)
                                    .then((res) => {
                                        closeForm();

                                    })
                            }
                        })

                }
            })

    }

    return (

        <Grid container spacing={2}>

            <Grid item xs={8}>
                <DGInput
                    label="Cuttin Tool"
                    value={componentProps.ctool_name}
                />
            </Grid>

            <Grid item xs={4}>
                <DGInput
                    label="Cuttin Tool Number"
                    value={componentProps.ctool_number}
                />
            </Grid>
            <Grid item xs={6}>
                <DGInput
                    label="Cuttin Tool Number"
                    value={`${componentProps.part_number} - ${componentProps.part_name}`}
                />
            </Grid>

            <Grid item xs={6}>
                <DGInput
                    label="Cuttin Tool Number"
                    value={`${componentProps.machine_number} - ${componentProps.machine_name}`}
                />
            </Grid>


            <Grid item xs={6}>
                <DGInput
                    label="Issue Date"
                    value={`${format(new Date(componentProps.issue_date), "MM/dd/yyyy")}     ${componentProps.issue_time.substring(0, 5)}`}
                />
            </Grid>

            <Grid item xs={6}>

            </Grid>

            <Grid item xs={6}>
                <DGInput
                    label="Issued By"
                    value={componentProps.issued_name}
                />
            </Grid>

            <Grid item xs={6}>
                <DGInput
                    label="Issued To"
                    value={componentProps.issued_to_name}
                />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Typography variant="h6">Return Details</Typography>

            </Grid>

            <Grid item xs={6}>
                <DGDateTimePicker
                    label="Return Date"
                    format="yyyy/MM/dd HH:mm"
                    value={form.values.issueDate}
                    onChange={(val) => {
                        form.setFieldValue("issueDate", val);
                    }}

                />
            </Grid>

            <Grid item xs={6}>
                <DGSelect
                    label="Returned By "
                    options={form.values.returnedByOptions}
                    getOptionLabel={(option) => `${option.user_name}`}
                    value={form.values.returnedBySelected}
                    onChange={(e) => form.setFieldValue("returnedBySelected", e)}
                />
            </Grid>

            <Grid item xs={3}>
                <DGInput
                    label="Tool Life"
                    value={form.values.toolLife}
                    id="toolLife"
                />
            </Grid>

            <Grid item xs={9}>
                <DGSelect
                    label="Move Tool to  "
                    options={form.values.ctoolStatusOptions}
                    getOptionLabel={(option) => `${option.ctool_status}`}
                    value={form.values.ctoolStatusSelected}
                    onChange={(e) => form.setFieldValue("ctoolStatusSelected", e)}
                />
            </Grid>

            <Grid item xs={12}>
                <CommonGrid
                    rows={rows}
                    columns={columns}
                    needExport={false}
                    gridheight={400}
                    onCellValueChanged={onCellValueChanged}
                />
            </Grid>

            <Grid item xs={12}>
                <DGSelect
                    label="Comments"
                    id="returnComments"
                    isFormik
                    value={form.values.returnComments}
                    options={form.values.returnCommentsOptions}
                    getOptionLabel={(option) => option}
                    onChange={(value) => form.setFieldValue("returnComments", value)}
                    freeSolo={true}
                />
            </Grid>


            <Footer onSave={form.handleSubmit} />

        </Grid>

    )


}

