import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography, Grid
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import React from "react";

export function EvaluationInfo({
  reportData = {}
}) {
  return (

    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="center"
      spacing={2}
      alignItems="stretch"
    >

      <Grid item xs={4}>
        <div style={{
          border: "1px solid black",
          boxSizing: "border-box",
          height: "100%",
        }}>
          <Typography variant="h4"

            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F8E6D8",
              padding: "5px",
            }}>
            <strong>
              Specification
            </strong>
          </Typography>
          <List>

            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Upper Specification"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={reportData?.drawing_info[0]?.["Upper Specification"]}
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Lower Specification"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={reportData?.drawing_info[0]?.["Lower Specification"]}
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Toleramce"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={reportData?.drawing_info[0]?.["Tolerance"]}
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>

          </List>
        </div>
      </Grid>

      <Grid item xs={4}>
        <div style={{
          border: "1px solid black",
          boxSizing: "border-box",
          height: "100%",
        }}>
          <Typography variant="h4"

            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F8E6D8",
              padding: "5px",
            }}>
            <strong>
              Input Data summary
            </strong>
          </Typography>

          <List>
            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Maximum Value"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={reportData?.part_info[0]?.["Maximum Value"]}
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Minimum Value"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={reportData?.part_info[0]?.["Minimum Value"]}
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Least Count"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={
                    reportData?.part_info[0]?.["Range"]
                      ? reportData.part_info[0]["Range"].toFixed(3)
                      : ""
                  }
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
      </Grid>

      <Grid item xs={4}>
        <div style={{
          border: "1px solid black",
          boxSizing: "border-box",
          height: "100%",
        }}>
          <Typography variant="h4"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F8E6D8",
              padding: "5px",
            }}>
            <strong>
              Evaluation Results
            </strong>
          </Typography>

          <List>
            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Part Variation(%)"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={
                    reportData?.evaluation_info[0]?.["Part Variation (%)"]
                      ? reportData.evaluation_info[0]["Part Variation (%)"].toFixed(2)
                      : ""
                  }
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Equipment Variation (%)"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={
                    reportData?.evaluation_info[0]?.["Equipment Variation (%)"]
                      ? reportData.evaluation_info[0]["Equipment Variation (%)"].toFixed(2)
                      : ""
                  }
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Appraisal Variation (%)"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={
                    reportData?.evaluation_info[0]?.["Appraisal Variation (%)"]
                      ? reportData.evaluation_info[0]["Appraisal Variation (%)"].toFixed(2)
                      : ""}
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              <ListItemText primary={"Interaction (%)"} />
              <ListItemSecondaryAction>
                <ListItemText
                  primary={
                    reportData?.evaluation_info[0]?.["Interaction (%)"]
                      ? reportData.evaluation_info[0]["Interaction (%)"].toFixed(2)
                      : ""}
                  primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
      </Grid>


    </Grid>

  );
}
