import { Box, Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
//import { array, number, object, string } from "yup";
import * as yup from "yup";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../components/CommonGrid";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";

import approvalService from "../../../services/approvalService";
import characterService from "../../../services/characterService";
import productService from "../../../services/productService";
import userService from "../../../services/userService";
import MaskedInput from "react-text-mask";
import { useParams } from "react-router-dom";

export function ProductCharacteristicsForm({
  Footer = () => { },
  closeForm = () => { },
  componentProps = null,
}) {
  const { v4: uuidv4 } = require("uuid");
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const [gridApi, setGridApi] = useState(null);
  let { id } = useParams();

  let form = useFormik({
    initialValues: {
      approver: null,
      gridData: [],
      part: "",
      plant: "",
      approvers: [],
      characterType: [],
      characterCriticality: [],
      characterUnits: [],
      singleSideFlagOptions: [

        { value: -1, name: "Maximum Limit" },
        { value: 1, name: "Minimum Limit" },
      ]

    },
    validationSchema: yup.object({
      approver: yup.object().required(),
      gridData: yup.array().min(1),
    }),
    onSubmit: onSave,
  });



  let detailForm = useFormik({
    initialValues: {
      character: "",
      characterType: null,
      positionDrawing: "",
      criticality: null,
      units: null,
      spec: "",
      upper: "",
      lower: "",
      angle_spec: "+",
      angle_lower: "+",
      angle_upper: "+",
      singleSideFlag: null
    },
    validationSchema: yup.object({
      character: yup.string().required('Mandatory Field'),
      characterType: yup.object().required('Mandatory Field'),
      criticality: yup.object().required('Mandatory Field'),
      units: yup.object().required('Mandatory Field'),

      upper: yup.number()
        .when(["characterType", "singleSideFlag"], {
          is: (characterType, singleSideFlag) => {
            const temp = !singleSideFlag &&
              characterType &&
              characterType.id !== 105 &&
              characterType.id < 600;
            return temp;
          },
          then: yup.number().required('Mandatory Field'),
          otherwise: yup.number().notRequired(),
        }),


      lower: yup.number()
        .when(["characterType", "singleSideFlag"], {
          is: (characterType, singleSideFlag) => {
            const temp = !singleSideFlag &&
              characterType &&
              characterType.id !== 105 &&
              characterType.id < 600;
            return temp;
          },
          then: yup.number().required('Mandatory Field'),
          otherwise: yup.number().notRequired()
        }),


      spec: yup.number()
        .when("characterType", {
          is: (characterType) =>
            characterType?.id !== 105 && characterType?.id < 600,
          then: yup.number().required('Mandatory Field'),
          otherwise: yup.number().notRequired()
        })
        .when(["singleSideFlag"], {
          is: (singleSideFlag) => singleSideFlag !== null,
          then: yup.number().test('greaterThanSum', 'Check Specification', function (value) {
            const { upper, lower, spec } = this.parent;
            if (upper === undefined || lower === undefined || spec === undefined) {
              return true;
            }
            return upper + spec > lower + spec;
          }),
          otherwise: yup.number().notRequired(),
        })


      ,
      angle_spec: yup.string().when("characterType", {
        is: (obj) => obj?.id === 105,
        then: yup.string().required('Mandatory Field'),
        otherwise: yup.string().notRequired(),
      }),
      angle_lower: yup.string().when("characterType", {
        is: (obj) => obj?.id === 105,
        then: yup.string().required('Mandatory Field'),
        otherwise: yup.string().notRequired(),
      }),
      angle_upper: yup.string().when("characterType", {
        is: (obj) => obj?.id === 105,
        then: yup.string().required('Mandatory Field'),
        otherwise: yup.string().notRequired(),
      }),


    }),
    onSubmit: onAdd,
  });

  console.log(detailForm.values.singleSideFlag)

  useEffect(() => {
    productService
      .getProductById(id)
      .then((res) => {
        form.setFieldValue("part", res?.data ?? []);
      });
    userService
      .getUserForApproval({
        client_id: currentUser.client_id,
        plant_id: currentUser.default_plant,
        current_user: currentUser.id
      })
      .then((res) => {
        form.setFieldValue("approvers", res?.data ?? []);
      });
    characterService
      .getCharacterType()
      .then((res) => {
        form.setFieldValue("characterType", res?.data?.data ?? []);
      });
    characterService
      .getCharacterCriticality()
      .then((res) => {
        form.setFieldValue("characterCriticality", res?.data?.data ?? []);
      });

    characterService
      .getCharacterUnits()
      .then((res) => {
        form.setFieldValue("characterUnits", res?.data?.data ?? []);
      });

  }, [])



  function onAdd() {
    if (
      detailForm?.values?.upper &&
      detailForm?.values?.lower &&
      detailForm?.values.upper < detailForm?.values.lower &&
      detailForm?.values?.characterType?.id !== 105
    ) {
      return detailForm?.setFieldError("upper", "Invalid input");
    }
    let data = [];
    let spec;
    let upperSpec;
    let lowerSpec;
    //debugger;
    if (detailForm.values.characterType?.id === 105) {
      spec = detailForm.values.angle_spec?.replaceAll("_", "");
      upperSpec = detailForm.values.angle_upper?.replaceAll("_", "");
      lowerSpec = detailForm.values.angle_lower?.replaceAll("_", "");
    } else {
      spec = detailForm.values.spec;
      upperSpec = detailForm.values.upper === "" ? null : detailForm.values.upper;
      lowerSpec = detailForm.values.lower === "" ? null : detailForm.values.lower;
    }
    data?.push({
      name: detailForm.values.character,
      character_type: detailForm.values.characterType,
      position_in_drawing: detailForm.values.positionDrawing,
      criticality: detailForm.values.criticality,
      character_unit: detailForm.values.units,
      specification: spec,
      upper_specification: upperSpec ?? null,
      lower_specification: lowerSpec ?? null,
      single_side_flag: detailForm.values.singleSideFlag,
    });
    form.setFieldValue("gridData", [...form.values.gridData, ...data]);
    detailForm.resetForm();
  }

  function onClear() {
    detailForm?.resetForm();
  }

  function onSave() {
    const approvalPayload = {
      id: uuidv4(),
      requested_by: currentUser.id,
      approver_id: form.values.approver?.id,
      gauge_character_id: null,
      entity_id: null,
      entity: null,
      approval_type_id: 1,
      status_id: 1,
      approved_date: null,
      entity_name: form.values.part.id,
    };
    let payload = [];
    gridApi.forEachNode((node) => {
      let data = node?.data;
      if (
        data?.criticality?.id &&
        data?.character_unit?.id &&
        data?.character_type?.id
      ) {
        payload.push({
          ...data,
          client_id: currentUser?.client_id,
          approval_id: "",
          id: uuidv4(),
          criticality_id: data?.criticality?.id,
          character_unit: data?.character_unit?.id,
          character_type_id: data?.character_type?.id,
          part_id: form?.values?.part?.id,
          created_by: currentUser.id,
          single_side_flag: data?.single_side_flag?.value,
        });
      }

    });

    if (payload?.length > 0) {
      characterService
        .characterApproval(approvalPayload)
        .then((ares) => {
          return characterService
            .createCharacter(
              payload.map((exp) => ({
                ...exp,
                approval_id: ares?.data?.id,
              }))
            )
            .then((cres) => {
              updateAlert({
                message: "Character Created SuccessFully",
                type: "success",
                open: true,
              });
              approvalService
                ?.updateApproval(ares?.data?.id, {
                  client_id: currentUser?.client_id,
                })
                .then((ures) => {
                  updateAlert({
                    message: "Characters Sent For Approval",
                    type: "info",
                    open: true,
                  });
                  closeForm(true);
                });
            })
            .catch((exp) => {
              updateAlert({
                message: "Failed To Create Character",
                type: "error",
                open: true,
              });
            });
        })
        .then((res) => {
          //  closeForm(true);
          // setLoading(false);
        });
    }
  }

  function onGridReady(params) {
    setGridApi(params?.api);
  }
  function removeDetailRow(rowIndex, data) {
    let chars = form.values.gridData;
    chars.splice(rowIndex, 1);
    gridApi?.updateRowData({ remove: [data] });
  }

  const columns = [
    {
      cellRenderer: DeleteRenderer,
      minWidth: 80,
      cellRendererParams: {
        onClick: (data, rowIndex) => {
          removeDetailRow(rowIndex, data);
        },
      },
      pinned: "left",
      resizable: true,
      hide: !!componentProps?.approvalId,
    },
    {
      field: "name",
      headerName: "Charcterstics",
      resizable: true,
      minWidth: 200,
      pinned: "left",
    },
    {
      field: "specification",
      headerName: "Specification",
      resizable: true,
      minWidth: 200,
    },
    {
      field: `${"single_side_flag.name"}`,
      headerName: "Single Sided",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "upper_specification",
      headerName: "Upper Tolerance",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "lower_specification",
      headerName: "Lower Tolerance",
      resizable: true,
      minWidth: 200,
    },
    {
      field: `${"character_type.name"}`,
      headerName: "Charcterstics Type",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "position_in_drawing",
      headerName: "Position in Drawing",
      resizable: true,
      minWidth: 200,
    },
    {
      field: `${"criticality.name"}`,
      headerName: "Criticality",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "character_unit.name",
      headerName: "Units",
      resizable: true,
      minWidth: 200,
    },
  ];



  return (
    <Grid container spacing={4}>
      <Grid item md={4} sm={4} lg={4}>
        <DGInput
          value={form.values.part ? `${form.values.part.plant.name}` : ''}
          label="Plant"
          disabled
        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGInput
          value={form.values.part ? `${form.values.part.part_number} ${form.values.part.part_name}` : ''}
          abel="Part"
          disabled
        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGSelect
          label="Approver"
          options={form.values.approvers}
          value={form.values.approver}
          onChange={(val) => form.setFieldValue("approver", val)}
          error={form.touched.approver && form.errors.approver}
          disabled={form.values.gridData.length > 0 && !form.values.approver}

        />
      </Grid>
      <Grid item container md={12} sm={12} lg={12} spacing={2}>
        <Grid item md={12} sm={12} lg={12}>
          <Box fontWeight="fontWeightBold" m={1}>
            Characteristics Details
          </Box>
        </Grid>

        <Grid item md={5} sm={5} lg={5}>
          <DGInput
            id="character"
            label="Characteristics"
            isFormik
            value={detailForm.values.character}
            onChange={detailForm.handleChange}
            error={detailForm.touched.character && detailForm.errors.character}
            helperText={detailForm.touched.character && detailForm.errors.character}
          />
        </Grid>

        <Grid item md={3} sm={3} lg={3}>
          <DGSelect
            label="Characteristics Type"
            options={form.values.characterType}
            value={detailForm.values.characterType}
            getOptionLabel={(opt) => `${opt?.name}`}
            onChange={(val) => {
              if (val?.id > 600) {
                detailForm.setFieldValue("spec", "");
                detailForm.setFieldValue("upper", "");
                detailForm.setFieldValue("lower", "");
              }
              detailForm.setFieldValue("characterType", val);
            }}
          // error={detailForm.touched.characterType && detailForm.errors.characterType}
          />
        </Grid>


        <Grid item md={4} sm={4} lg={4}>
          <DGSelect
            label="Single Side(No Natural Limit)"
            options={form.values.singleSideFlagOptions}
            value={detailForm.values.singleSideFlag}
            getOptionLabel={(opt) => `${opt?.name}`}
            disabled={detailForm?.values?.characterType?.id > 200 && detailForm?.values?.characterType?.id < 300}
            onChange={(val) => {
              detailForm.setFieldValue("singleSideFlag", val);
            }}
          />
        </Grid>

        <Grid item md={3} sm={3} lg={3}>
          <DGSelect
            id="units"
            label="Units"
            options={form.values.characterUnits}
            value={detailForm.values.units}
            getOptionLabel={(opt) => `${opt?.name}`}
            onChange={(val) => detailForm.setFieldValue("units", val)}
          // error={detailForm.touched.units && detailForm.errors.units}
          />
        </Grid>
        {detailForm?.values?.characterType?.id === 105 ? (
          <>
            <Grid item md={3} sm={3} lg={3}>
              <MaskedInput
                mask={[
                  /[+-]/,
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  "°",
                  "-",
                  /\d/,
                  /\d/,
                  "'",
                  "-",
                  /\d/,
                  /\d/,
                  "''",
                ]}
                className="form-control"
                placeholder="Specification"
                guide={true}
                id="my-input-id"
                onBlur={() => { }}
                onChange={(e) => {
                  detailForm.setFieldValue("angle_spec", e?.target?.value);
                }}
                type="text"
                value={detailForm.values.angle_spec}
                defaultValue={"+"}
                style={{
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  paddingTop: 20,
                  background: "#F7F9FC",
                  borderRadius: 0,
                  borderBottomWidth: 1,
                  borderBottomColor: "#9e9e9e",
                  ":focus": {
                    boxShadow: 0,
                  },
                }}
              />
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
              <MaskedInput
                mask={[
                  /[+-]/,
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  "°",
                  "-",
                  /\d/,
                  /\d/,
                  "'",
                  "-",
                  /\d/,
                  /\d/,
                  "''",
                ]}
                className="form-control"
                placeholder="Upper Tolerance"
                disabled={detailForm?.values.singleSideFlag?.value}
                guide={true}
                type="text"
                id="my-input-id"
                onChange={(e) => {
                  detailForm.setFieldValue("angle_upper", e?.target?.value);
                }}
                value={detailForm.values.angle_upper}
                defaultValue={"+"}
                style={{
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  paddingTop: 20,
                  background: "#F7F9FC",
                  borderRadius: 0,
                  borderBottomWidth: 1,
                  borderBottomColor: "#9e9e9e",
                  ":focus": {
                    boxShadow: 0,
                  },
                }}
              />
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
              <MaskedInput
                mask={[
                  /[+-]/,
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  "°",
                  "-",
                  /\d/,
                  /\d/,
                  "'",
                  "-",
                  /\d/,
                  /\d/,
                  "''",
                ]}
                className="form-control"
                placeholder="Lower Tolerance"
                guide={true}
                id="my-input-id"
                onChange={(e) => {
                  detailForm.setFieldValue("angle_lower", e?.target?.value);
                }}
                type="text"
                value={detailForm.values.angle_lower}
                defaultValue={"+"}
                style={{
                  borderLeft: 0,
                  borderRight: 0,
                  borderTop: 0,
                  paddingTop: 20,
                  background: "#F7F9FC",
                  borderRadius: 0,
                  borderBottomWidth: 1,
                  borderBottomColor: "#9e9e9e",
                  ":focus": {
                    boxShadow: 0,
                  },
                }}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={3} sm={3} lg={3}>
              <DGInput
                label="Specification"
                id="spec"
                isFormik
                type="number"
                onChange={detailForm.handleChange}
                value={detailForm.values.spec}
                disabled={detailForm?.values?.characterType?.id > 600}
                error={detailForm.touched.spec && detailForm.errors.spec}
              />
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
              <DGInput
                label="Upper Tolerance"
                id="upper"
                isFormik
                type="number"
                onChange={detailForm.handleChange}
                disabled={detailForm?.values?.characterType?.id > 600}
                value={detailForm.values.upper}
                error={detailForm.touched.upper && detailForm.errors.upper}
              />
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
              <DGInput
                label="Lower Tolerance"
                id="lower"
                isFormik
                type="number"
                onChange={detailForm.handleChange}
                value={detailForm.values.lower}
                disabled={detailForm?.values?.characterType?.id > 600}
                error={detailForm.touched.lower && detailForm.errors.lower}
              />
            </Grid>
          </>
        )}


        <Grid item md={4} sm={4} lg={4}>
          <DGInput
            label="Position in Drawing"
            value={detailForm.values.positionDrawing}
            id="positionDrawing"
            isFormik
            onChange={detailForm.handleChange}
            error={
              detailForm.touched.positionDrawing &&
              detailForm.errors.positionDrawing
            }
          />
        </Grid>
        <Grid item md={8} sm={8} lg={8}>
          <DGSelect
            label="Criticality"
            id="criticality"
            options={form.values.characterCriticality}
            value={detailForm.values.criticality}
            getOptionLabel={(opt) => `${opt?.name}`}
            isFormik
            onChange={(val) => detailForm.setFieldValue("criticality", val)}
            error={detailForm.touched.criticality && detailForm.errors.criticality}
            helperText={detailForm.touched.criticality && detailForm.errors.criticality}
          />
        </Grid>

        <Grid item md={12} sm={12} lg={12}>
          <Button
            style={{ paddingLeft: 40, paddingRight: 40 }}
            color="primary"
            variant="outlined"
            onClick={detailForm.handleSubmit}
            className="m-2"
          >
            Add
          </Button>
          <Button
            style={{ paddingLeft: 40, paddingRight: 40 }}
            color="primary"
            variant="outlined"
            onClick={onClear}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
      )
      <Grid md={12} sm={12} lg={12}>
        <CommonGrid
          columns={columns}
          rows={form.values.gridData?.length ? form.values.gridData : []}
          needExport={false}
          onGridReady={onGridReady}
          gridheight={componentProps?.approvalId ? 138 : 420}
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
