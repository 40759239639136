import { Grid, Box, Paper, Tab, Tabs, withStyles, Button } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import { DGDrawer } from "../../../../components/DGDrawer";
import LinkIcon from "@material-ui/icons/Link";
import MachineProductCycleTimeAdd from "./machineProductCycleListAdd";
import machineService from "../../../../services/machineService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import { m } from "framer-motion";
import { min } from "date-fns";


export default function MachineProductCycleTimeList({ }) {

    const [rows, setRows] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { setStatus } = useContext(StatusContext);


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
            //callback: changeStatus,
        }));
        reloadGridData();
    }, []);



    function reloadGridData() {
        machineService.getMachineProductCT(
            {
                client_id: currentUser.client_id,
                plant_id: globalPlant?.globalPlant?.id
            }
        )
            .then((res) => {
                setRows(res?.data ?? [])
            })
    }


    const columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: (params) => {
                if (params.node && !params.node.group && params.node.parent) {
                    const parentGroup = params.node.parent;
                    const childIndex = parentGroup.allLeafChildren.indexOf(params.node);
                    return childIndex + 1;
                }
                return '';
            },
            filter: "agTextColumnFilter",
            minWidth: 50,
        },

        {

            headerName: "Part",
            field: "part_name",
            filter: "agTextColumnFilter",
            flex: 1,
            rowGroup: true,
            hide: true,
            minWidth: 250,
            valueGetter: (params) => {
                return params.data?.part_number + " - " + params.data?.part_name
            }
        },

        {

            headerName: "Machine",
            field: "machine_name",
            filter: "agTextColumnFilter",
            flex: 1,
            minWidth: 250,
            valueGetter: (params) => {
                return `${params.data?.machine_number || ''} ${params.data?.machine_name || ''}`.trim() || '';

            },
        },

        {
            headerName: "Cycle Time (in Sec)",
            field: "cycle_time",
            minWidth: 200,
            resizable: true,
        }
        ,

        {
            headerName: "Fully Automated",
            field: "fully_automated",
            minWidth: 200,
            cellRenderer: (params) => {
                const value = params?.data?.fully_automated;
                if (value === 1) {
                    return <ResultRenderer value={1} />;
                }
                return null;
            }
        }






    ]


    function ButtonComponent() {
        return (
            <Button
                variant="contained"
                color="primary"
                startIcon={<LinkIcon />}
                onClick={() => {
                    setFormOpen(true);
                }}
            >
                Update Cycle Time
            </Button>
        )
    }


    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    ButtonComponent={ButtonComponent}
                    gridheight={150}
                    groupDefaultExpanded={-1}
                    groupDisplayType={"multipleColumns"}
                />
            </Grid>

            <DGDrawer
                Component={MachineProductCycleTimeAdd}
                isOpen={formOpen}
                drawerWidth={800}
                setOpen={(state) => {
                    setFormOpen(false);
                    reloadGridData()
                }}
                needSave
                title="Add New Cycle Time"
            />
        </Grid>

    )


}