import {
    Box,
    Paper,
    Tab,
    Tabs,
    withStyles,
} from "@material-ui/core";
import { PageTitleContext } from "../../../context/PageTitleContextProvider"
import { useContext, useEffect, useState } from "react";
import DefectClassificationList from "./defectClassificationList"
import DefectRecordList from "./defectRecordList";


export default function DefectManagement() {

    const [selectedTab, setSelectedTab] = useState(0);
    const { setHeaderPageTitle } = useContext(PageTitleContext);

    const buttons = [
        "Defect Record List",
        "Defect Master List"

    ];

    useEffect(() => {
        setHeaderPageTitle(`Defect Management`);
    }, []);


    return (
        <Paper square>
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, val) => setSelectedTab(val)}
                aria-label="disabled tabs example"
            >
                {buttons.map((btn, index) => (
                    <StyledTab label={btn} value={index} />
                ))}
            </Tabs>
            <Box m={2}>
                {selectedTab === 0 ? <DefectRecordList /> : null}
                {selectedTab === 1 ? <DefectClassificationList /> : null}


            </Box>
        </Paper>
    );
}

const StyledTab = withStyles((theme) => ({
    wrapper: {
        textTransform: "none",
    },
    root: {
        textTransform: "none",
    },
}))((props) => <Tab disableRipple {...props} />);