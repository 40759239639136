import { Box, Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { object, string } from "yup";
import CommonGrid from "../../../components/CommonGrid";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import disptachInspection from "../../../services/disptachInspection";
import { DateEditor } from "../../../components/cellRenderers/DateEditor";
const { v4: uuidv4 } = require("uuid");


export function CheckListFormView({
  Footer = () => { },
  closeForm = () => { },
  componentProps = null,
}) {
  const [rows, setRows] = useState([]);
  const [gridapi, setGridApi] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const { globalPlant } = useContext(PlantContext);


  let form = useFormik({
    initialValues: {
      plan_name: "",
    },
    validationSchema: object({
      plan_name: string().required(),
    }),
  });

  let detailForm = useFormik({
    initialValues: {
      checkpoint: "",
      requirement: null,
      defaultvalue: "",
    },
    validationSchema: object({
      checkpoint: string().required(),
      requirement: object().required(),
    }),
  });


  const columns = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      maxWidth: 75,
    },
    {
      headerName: "Check Point",
      field: "item_name",
      resizable: true,
    },
    {
      headerName: "Requirement",
      field: "item_mandatory",
      resizable: true,
      valueGetter: (params) => {
        if (params?.data?.item_mandatory === 1) {
          return "Mandatory";
        } else if (params?.data?.item_mandatory === 2) {
          return "Optional";
        } else if (params?.data?.item_mandatory === 3) {
          return "Not Required";
        } else {
          return null;
        }
      },
      filter: "agTextColumnFilter",
      valueSetter: (params) => {
        if (params?.newValue === "Mandatory") {
          params.data.item_mandatory = 1;
        } else if (params?.newValue === "Optional") {
          params.data.item_mandatory = 2;
        } else if (params?.newValue === "Not Required") {
          params.data.item_mandatory = 3;
        }
        return true;
      },

    },
    {
      headerName: "Default Value",
      field: "default_value",
      resizable: true,

    },
    {
      headerName: "Group",
      field: "item_group_id",
      resizable: true,
      valueGetter: (params) => `Header ${params?.data?.item_group_id}`,

    },
  ];


  useEffect(() => {
    form.setFieldValue("plan_name", componentProps?.plan_name);
    disptachInspection
      .getPDIStdPlan(componentProps?.pdi_standard_plan_id)
      .then((res) => {
        const temp1 = res.data.filter((item) =>
          item.item_mandatory !== 3 && item.item_mandatory !== null
        );
        setRows(temp1);
      });

  }, []);


  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          label="Plant Name"
          value={componentProps?.plant_name}
          id="plant_name"
        />
      </Grid>

      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          label="Plan Name"
          value={componentProps?.plan_name}
          id="plan_name"
        />
      </Grid>

      <Grid item md={12} sm={12} lg={12}>
        <CommonGrid
          needExport={false}
          columns={columns}
          rows={rows}
          gridheight={130}
          singleClickEdit
          onGridReady={(params) => setGridApi(params?.api)}
        />
      </Grid>
    </Grid>
  );
}
