import { useContext, useState, useEffect } from "react";
import { Grid, Radio, RadioGroup, Box, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from '@material-ui/core/Divider';
import { blue, brown } from "@material-ui/core/colors";
import { format, startOfWeek, startOfMonth, endOfMonth, endOfWeek } from 'date-fns'
import { DGDateInput } from "../../../components/shared/DGDateInput";
import productionMgmtService from "../../../services/productionMgmtService"
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import CommonGrid from "../../../components/CommonGrid";


export default function ProductionRecoedSummary() {

    const [dateDepth, setDateDepth] = useState(0);
    const [filterOptions, setFilterOptions] = useState(1);
    const { globalPlant } = useContext(PlantContext);
    const [partsSelected, setPartsSelected] = useState([]);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [dataLabel, setdataLabel] = useState('');
    const [Offset_index, setOffset_index] = useState(0);
    const [productionRecord, setProductionRecord] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const [filteredProductionRecord, setFilteredProductionRecord] = useState([]);
    const [productionSum, setProductionSum] = useState(0);





    useEffect(() => {

        switch (dateDepth) {
            case 1:
                setstartDate(new Date())
                setdataLabel("Day");
                setendDate(new Date())

                break;

            case 2:
                setstartDate(startOfWeek(new Date(), { weekStartsOn: 1 }))
                setdataLabel("Week");
                setendDate(endOfWeek(new Date(), { weekStartsOn: 1 }))
                break;

            case 3:
                setstartDate(startOfMonth(new Date()))
                setdataLabel("Month");
                setendDate(endOfMonth(new Date()))

                break;

            default:

                setdataLabel("User Defined");

                break;
        }



    }, [dateDepth]);



    function removeAllRowGroupColumns(columnApi) {
        const allGroupedColumns = columnApi.getRowGroupColumns();

        allGroupedColumns.forEach((col) => {
            columnApi.removeRowGroupColumn(col);
        });
    }

    useEffect(() => {

        productionMgmtService.getProductionRecordTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,

        })
            .then((response) => {
                const data = response.data;
                setProductionRecord(data);
                setDateDepth(2);

            })
    }, [])



    useEffect(() => {
        productionRecordTable(productionRecord);
    }, [dateDepth, endDate, filterOptions, startDate])




    function productionRecordTable(data) {
        const filteredData = data.filter((item) => {
            const productionDate = new Date(item.production_date);

            if (dateDepth === 1) {
                return productionDate.toDateString() === startDate.toDateString() &&
                    item.enabled === 1;
            } else {
                return productionDate >= startDate &&
                    productionDate <= endDate &&
                    item.enabled === 1;
            }
        });

        setFilteredProductionRecord(filteredData);

        const totalProductionQuantity = filteredData.reduce((sum, item) => {
            return sum + item.production_quantity;
        }, 0);

        setProductionSum(totalProductionQuantity);
    }




    const columns = [




        {
            field: "part_number",
            headerName: "Part Number",
            flex: 1,
            editable: false,
            resizable: true,
            minWidth: 200,
            enableRowGroup: true,
            hide: false,

            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                } else {
                    return (
                        <>
                            {params?.data?.part_number} - {params?.data?.part_name}
                        </>
                    );
                }
            },

            //cellRenderer: 'agGroupCellRenderer',

        }


        ,
        {
            field: "production_date",
            headerName: "Date",
            flex: 1,
            resizable: true,
            minWidth: 125,
            sort: "desc",
            sortable: true,
            enableRowGroup: true,

            valueFormatter: (params) => {
                if (params.value) {
                    const date = new Date(params.value);
                    if (!isNaN(date)) {
                        return format(date, 'dd-MM-yyyy');
                    }
                }
                return '';
            },

            valueGetter: (params) => {
                return params?.data?.production_date;
            },
            /* 
                        valueFormatter: (params) => {
                            return params.value ? format(new Date(params.value), 'dd/MM/yyyy') : '';
                        },
            
                        cellRenderer: (params) => {
                            if (params.node.group) {
                                return params.value;
                            } else {
                                return (
                                    <>
                                        {params?.data?.production_date}
                                    </>
                                );
                            }
                        } */
        },




        {
            field: "production_quantity",
            headerName: "Quantity",
            resizable: true,
            minWidth: 150,
            aggFunc: "sum",

        },

        {
            field: "machine_number",
            headerName: "Machine",
            flex: 1,
            editable: false,
            resizable: true,
            minWidth: 150,
            enableRowGroup: true,
            sortable: true,


            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                } else {
                    return (
                        <>
                            {params?.data?.machine_number}-{params?.data?.machine_name}
                        </>
                    );
                }
            },
        },

        {
            field: "operator_name",
            headerName: "Operator ID",
            flex: 1,
            editable: false,
            resizable: true,
            minWidth: 150,
            sortable: true,
            enableRowGroup: true,
            sortable: true,

            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                } else {
                    return (
                        <>
                            {params?.data?.operator_name}-{params?.data?.operator_eid}
                        </>
                    );
                }
            },
        },


        {
            field: "production_start_time",
            headerName: "Start Time:Production",
            flex: 1,
            editable: false,
            resizable: true,
            minWidth: 150,
            hide: false,

            valueGetter: (params) => {
                const productionStartTime = params?.data?.production_start_time;
                if (productionStartTime) {
                    const [hours, minutes] = productionStartTime.split(':');
                    return `${hours}:${minutes}`;
                }
                return '';
            }

        },
        {
            field: "production_end_time",
            headerName: " End Time : Production",
            flex: 1,
            editable: false,
            resizable: true,
            minWidth: 150,
            hide: false,
            valueGetter: (params) => {
                const productionEndTime = params?.data?.production_end_time;
                if (productionEndTime) {
                    const [hours, minutes] = productionEndTime.split(':');
                    return `${hours}:${minutes}`;
                }
                return '';
            }

        },

        {
            field: "production_rejections",
            headerName: "Rejection Quantity",
            resizable: true,
            minWidth: 150,
            aggFunc: "sum",
            sortable: true,
        },
    ]



    return (

        <>

            <Grid container spacing={2}>
                <Grid item xs={5} container justifyContent="center" alignItems="flex-left">
                    <RadioGroup row
                        style={{ gap: '12px' }}
                        value={dateDepth.toString()}
                        onChange={(event) => {
                            const newValue = parseInt(event.target.value);
                            setDateDepth(newValue);
                        }}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Day" />
                        <FormControlLabel value="2" control={<Radio />} label="Week" />
                        <FormControlLabel value="3" control={<Radio />} label="Month" />
                        <FormControlLabel value="4" control={<Radio />} label="User Defined" />
                    </RadioGroup>

                </Grid>

                <Grid item xs={7} container alignItems="flex-left">
                    {/*                     <Typography variant="h6" align="left"
                        style={{
                            paddingRight: '15px',
                            alignContent: 'space-evenly',
                        }}>

                        Filter
                    </Typography>

                    <RadioGroup
                        row
                        style={{ gap: '12px' }}
                        value={filterOptions.toString()}
                        onChange={(event) => {
                            const newValue = parseInt(event.target.value);
                            setFilterOptions(newValue);
                            // setGraphOptions(null)
                            // setSelectedRow(null)
                        }}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Machine" />
                        <FormControlLabel value="2" control={<Radio />} label="Part" />
                        <FormControlLabel value="3" control={<Radio />} label="Operator" />
                    </RadioGroup> */}

                    <Grid container spacing={2}>

                        <Grid item xs={4}>
                            <DGDateInput
                                type="date"
                                label="Start Date"
                                value={startDate}
                                onChange={(date) => {
                                    setstartDate(date)
                                    setDateDepth(4);
                                }}
                                disabled={dateDepth < 4}
                            />
                        </Grid>
                        <Grid item xs={4}>

                            <DGDateInput
                                type="date"
                                label="End Date"
                                value={endDate}
                                onChange={(date) => {
                                    setendDate(date);
                                }}
                                disabled={dateDepth < 4}
                            />
                        </Grid>
                    </Grid>

                </Grid>




            </Grid>


            <Divider
                variant="middle"
                style={{
                    backgroundColor: blue[900],
                    width: '100%',
                    height: '2px',
                    margin: '3px',

                }}
            />

            <Grid item xs={12}>
                <Box
                    sx={{
                        border: '1px solid grey',
                        width: '98%',
                        padding: '5px',
                        marginTop: '5px',
                        //height: '70%',
                        marginRight: '2%',
                        boxSizing: 'border-box',
                        overflowY: 'auto',
                        overflowX: 'auto',
                        height: "750px",
                    }}
                >

                    <CommonGrid
                        columns={columns}
                        rows={filteredProductionRecord}
                        rowGroupPanelShow={'always'}
                        showOpenedGroup={true}
                        suppressRowSticky={true}
                        gridheight={75}
                        groupDisplayType={"multipleColumns"}
                        suppressAggFuncInHeader={true}
                        suppressAggAtRootLevel={true}
                        onRowDataUpdated={(params) => {
                            removeAllRowGroupColumns(params.columnApi)

                        }}
                        HeaderComponent={() => (
                            <Typography
                                color="primary"
                                variant="h6"
                                style={{ fontSize: 18, fontWeight: "bolder", paddingLeft: 10 }}
                                align="left"
                            >
                                Production Sum : {productionSum}
                            </Typography>
                        )}
                    />
                </Box>
            </Grid>



            {/*     <Grid item xs={4}>
                <Box
                    sx={{
                        border: '1px solid grey',
                        width: '98%',
                        padding: '5px',
                        marginTop: '5px',
                        //height: '70%',
                        marginRight: '2%',
                        boxSizing: 'border-box',
                        overflowY: 'auto',
                        overflowX: 'auto',
                        height: "750px",
                    }}
                >


                    <DGDateInput
                        type="date"
                        label="Start Date"
                        value={startDate}
                        onChange={(date) => {
                            setstartDate(date)
                            setDateDepth(4);
                        }}
                        disabled={dateDepth < 4}
                    />

                    <DGDateInput
                        type="date"
                        label="End Date"
                        value={endDate}
                        onChange={(date) => {
                            setendDate(date);
                        }}
                        disabled={dateDepth < 4}
                    />


                </Box>
            </Grid> */}
        </>
    )


}
