import React, { useEffect, useState } from "react";
//import Highcharts from "highcharts";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import highchartsMore from "highcharts/highcharts-more";
import productionInspection from "../../../../services/productionInspection"
import errorLog from "../../../../services/errorLog";
import { startOfMonth, subMonths } from "date-fns";


highchartsMore(Highcharts);
highchartsDumbbell(Highcharts);


export function PIResultGraph({
  id = "",
  name = "",
  spec = "",
  specDisplay = "",
  gembaDisplay = "",
  monthSelected = "",
  spindleFilter = [],
  singleSideGraph = "",
}) {

  const { v4: uuidv4 } = require("uuid");
  const [startDate, setStartDate,] = useState(null)
  const [graphData, setGraphData] = useState(null);
  const [rawData, setRawData] = useState(null);


  let temp = spec.split(" ");
  let [specification, upper_specification, lower_specification] = temp.map(temp1 => parseFloat(temp1));


  lower_specification = specification + lower_specification
  upper_specification = specification + upper_specification


  const [chartOptions, setChartOptions] = useState({
    rangeSelector: {
      enabled: gembaDisplay !== 1,
      buttons: [
        {
          type: 'day',
          count: 2,
          text: '1d',
          title: 'View 1 day',
        },
        {
          type: 'week',
          count: 1,
          text: '1w',
          title: 'View 1 week',
        },
        {
          type: 'week',
          count: 2,
          text: '2w',
          title: 'View 2 week',
        },
        {
          type: 'month',
          count: 1,
          text: '1m',
          title: 'View 1 month',
        },

        {
          type: 'all',
          text: 'All',
          title: 'View all data',
        },


      ],
    },


    chart: {
      type: "dumbbell",
      inverted: false,
      marginRight: 20,
      zoomType: "x",
      panning: {
        enabled: true,
        type: 'x'
      },
      panKey: 'shift',
      boostThreshold: 5000


    },

    boost: {
      enabled: true,
    },


    tooltip: {
      enabled: true,
      formatter: function () {
        return (
          "<b>" + this.point.request_name + "</b> " +
          "<br>" +
          "Max: <b>" + this.point.high.toFixed(3) + "</b> " +
          "Min: <b>" + this.point.low.toFixed(3) + "</b> " +
          "Range: <b>" + (this.point.high - this.point.low).toFixed(4) + "</b>" +
          "<br>" +
          "Machine <b>" + this.point.machine_name + "</b>" +
          "<br>"

        );
      },
    },


    animation: true,
    title: {
      text: gembaDisplay !== 1 ? `${name}  ${spec ?? ''}` : undefined,
    },


    yAxis: {
      min: null,
      max: null,

      crosshair: false,
      title: {
        text: "Values",
      },
      plotLines: null
    },

    xAxis: {
      crosshair: false,
      ordinal: true,

    },

    credits: {
      enabled: false,
    },

    legend: false,



    series: [
      {
        name: "Product Inspection",
        data: [],
      },
    ],

  });

  useEffect(() => {
    const tempDate = subMonths(new Date(), monthSelected)
    setStartDate(startOfMonth(tempDate))

  }, [monthSelected])


  useEffect(() => {
    if (!startDate) {
      return
    }
    productionInspection.getPDCharGraph({
      character_id: id,
      start_date: startDate
    })
      .then((res) => {
        setRawData(res.data)
        setGraphData(res.data.slice(-999));

      })
  }, [startDate])



  useEffect(() => {
    if (!rawData) {
      return
    }
    const tempData = spindleFilter?.length
      ? rawData?.filter(item => spindleFilter.includes(item.machine_spindle_id))
      : rawData;



    setGraphData(tempData.slice(-999))
  }, [spindleFilter])


  //  console.log('graphData', graphData)

  useEffect(() => {
    if (!graphData) {
      return
    }

    const filteredData = graphData.filter((item) => {
      return (
        (item.low !== null && item.low !== undefined) ||
        (item.high !== null && item.high !== undefined)
      );
    });




    const formattedData = filteredData.map((item) => {

      let lowColor;
      let color

      if (item.character_result === 0) {
        lowColor = 'red';
        color = 'red';
      } else if (item.character_result === 2) {
        lowColor = 'yellow';
        color = 'yellow';
      } else {
        lowColor = item.connectorColor;
        color = item.connectorColor;
      }


      return {

        low: item.low,
        high: item.high,
        request_name: item.request_name,
        lowColor: lowColor,
        color: color,
        marker: item.marker,
        machine_name: item.machine_spindle_name,
        x: item.name_,
        connectorColor: item.connectorColor,
      }
    });


    const max = filteredData.length - 1
    let min
    if (filteredData.length > 30) {
      min = filteredData.length - 30
    } else {
      min = 0

    }


    setChartOptions((prev) => {
      let yAxisOptions = {}


      if (specDisplay) {

        if (singleSideGraph !== -1 && singleSideGraph !== 1) {

          yAxisOptions.max = upper_specification + 0.001;
          yAxisOptions.min = lower_specification - 0.001;

        }

        yAxisOptions.plotLines = [
          {
            value: specification,
            color: "green",
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: "Target",
              align: "right",
              x: -1,
            },
          },
        ];

        if (singleSideGraph !== -1 && singleSideGraph !== 1) {
          yAxisOptions.plotLines = [
            ...yAxisOptions.plotLines,
            {
              value: upper_specification,
              color: "green",
              dashStyle: "shortdash",
              width: 2,
              label: {
                text: "Specification",
                align: "right",
                x: -1,
              },
            },
            {
              value: lower_specification,
              color: "green",
              dashStyle: "shortdash",
              width: 2,
              label: {
                text: "Specification",
                align: "right",
                x: -1,
              },
            },
          ];
        }

      }
      else {
        yAxisOptions.plotLines = null;
        yAxisOptions.max = null;
        yAxisOptions.min = null
      }

      return {
        ...prev,
        xAxis: {
          type: 'datetime',
          title: { text: 'Production Date' },
          labels: {
            formatter: function () {
              return new Date(this.value).toLocaleDateString('en-US', {
                month: 'short', day: '2-digit'
              });
            },
          },
          // min: filteredData[min]?.name_,
          // max: filteredData[max]?.name_,

          min: filteredData[min]?.name_,
          max: filteredData[max]?.name_,



        },
        yAxis: yAxisOptions,
        series: [
          {
            data: formattedData,
            keys: ["x", "low", "high", "request_name"],
            showInNavigator: true,
          },
        ],
      };
    });


  }, [graphData, specDisplay]);


  return chartOptions ? (
    <HighchartsReact
      options={chartOptions}
      highcharts={Highcharts}

    />
  ) : null;


}
