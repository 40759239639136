import React, { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import CommonGrid from "../../../components/CommonGrid";
import { useFormik } from "formik";
import cuttingToolService from "../../../services/cuttingToolService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { grid } from "@material-ui/system";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import { object, number } from "yup";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import vendorService from "../../../services/vendorService";
import userService from "../../../services/userService";
import piService from "../../../services/piService";
import { format, parseISO, set } from "date-fns";


export function CtoolStatusManage({
    componentProps = {},
    Footer = () => { },
    closeForm = () => { },
}) {

    const { v4: uuidv4 } = require('uuid');
    const { currentUser } = useContext(AuthContext);
    const [vendorFlag, setVendorFlag] = useState(false)
    const [scrapComments, setScrapComments] = useState(false)
    const [row, setRow] = useState([])
    const [requestId, setRequestId] = useState()
    const { updateAlert } = useContext(AlertContext);
    const globalplant = useContext(PlantContext);

    //  console.log("plant", globalplant);


    let form = useFormik({
        initialValues: {
            ctoolStatusSelected:
            {
                ctool_status_id: componentProps?.ctool_status_id,
                ctool_status: componentProps?.ctool_status,
            },
            vendorOptions: [],
            vendorSelected: null,
            gridData: [],
            scrapComment: "",
            scrapCommentsOptions: [],
            ctoolStatusOptions: [
                { ctool_status_id: 101, ctool_status: "Active" },
                { ctool_status_id: 102, ctool_status: "Quarantine" },
                { ctool_status_id: 103, ctool_status: "Scrapped" },
                { ctool_status_id: 109, ctool_status: "Under Refurbishing" },
            ],
        },

        onSubmit: onSave

    })

    useEffect(() => {

        switch (componentProps?.ctool_status_id) {
            case 101:
                form.setFieldValue("ctoolStatusOptions",
                    [
                        { ctool_status_id: 102, ctool_status: "Quarantine" },
                        { ctool_status_id: 103, ctool_status: "Scrap" },
                        { ctool_status_id: 109, ctool_status: "Refurbish" },
                    ],
                )
                break;
            case 102:
                form.setFieldValue("ctoolStatusOptions",
                    [
                        { ctool_status_id: 101, ctool_status: "Active" },
                        { ctool_status_id: 103, ctool_status: "Scrap" },
                        { ctool_status_id: 109, ctool_status: "Refurbish" },

                    ],
                )
                break;
        }


    }, [])

    useEffect(() => {
        piService.getActivePIlist(currentUser.client_id, 25)
            .then((res) => {
                form.setFieldValue("vendorOptions", res.data)
            })
    }, [])

    useEffect(() => {
        cuttingToolService.getCToolNumberStatusTable
            ({
                ctool_number_id: componentProps.id
            })
            .then((res) => {
                setRow(res.data)
            })

    }, [])

    useEffect(() => {
        cuttingToolService.getStatusMaxID({
            ctool_number_id: componentProps.id,
            client_id: currentUser.client_id
        })
            .then((res) => {
                setRequestId(res.data[0].max_id)
            })
    }, [form.values.ctoolStatusSelected])


    async function onSave() {

        let payload = {
            ctool_status: form.values.ctoolStatusSelected?.ctool_status_id,
            updated_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            comments: form.values.scrapComment ?? ""

        };

        try {
            const res = await cuttingToolService.modifyCToolNumber(componentProps.id, payload);
            const apistatus = res.status;

            let payload2 = {
                id: uuidv4(),
                ctool_number_id: componentProps.id,
                ctool_status_id: form.values.ctoolStatusSelected?.ctool_status_id,
                ctoolnumber_newlife: null,
                user_id: currentUser.id,
                vendor_id: form.values.vendorSelected?.id ?? null,
                refurbish_initiated_by: null,
                refurbish_initiated: null,
                refurbish_approved_by: null,
                refurbish_approved: null,
                enabled: 1,
                request_id: (requestId + 1),
                comments: form.values.scrapComment ?? ""

            };

            if (apistatus <= 210) {
                const res2 = await cuttingToolService.createCtoolStatusChange(payload2);
                if (res2.status === 201) {
                    updateAlert({
                        open: true,
                        message: "Cutting Tool Status Updated",
                        type: "success"
                    });
                }
            }

            closeForm();
        } catch (error) {
            console.error("Error updating cutting tool status:", error);
        }
    }



    const columns = [
        {
            field: "request_id",
            headerName: "Request Number",
            filter: "agTextColumnFilter",
            maxWidth: 100,
            resizable: true,
        },


        {
            headerName: "Status",
            field: "ctool_status",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            resizable: true,
            minWidth: 150,
        },

        {
            headerName: "Intiated By",
            field: "name_",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            resizable: true,
            minWidth: 150,
        },

        {
            headerName: "Date",
            //field: "created_at",
            flex: 1,
            editable: false,
            floatingFilter: true,
            resizable: true,
            minWidth: 150,
            valueGetter: (params) =>
                format(new Date(params?.data?.date_), 'dd/MM/yyyy')
        },

        {
            headerName: "Vendor Name",
            field: "vendor_name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            resizable: true,
            minWidth: 150,
        },

        {
            headerName: "Tool Life",
            field: "ctoolnumber_life",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 150,
        },



        /*   {
              field: "enabled",
              headerName: "Status",
              minWidth: 150,
              resizable: true,
              filter: "agNumberColumnFilter",
              floatingFilter: true,
              suppressMenu: true,
              floatingFilterComponent: StatusFilter,
              floatingFilterComponentParams: {
                  suppressFilterButton: true,
              },
              cellRenderer: (params) => (
                  <ActiveInActive
                      status={params?.data?.enabled}
                      onClick={() =>
                          setStatus((prev) => ({
                              ...prev,
                              open: true,
                              callback: changeStatus,
                              reloadGrid: reloadGridData,
                              entityName: params?.data?.tool_name ?? "",
                              currentStatus: params?.data?.enabled,
                              rowId: params?.data?.id,
                          }))
                      }
                  />
              ),
          },
          {
              field: "enabled",
              headerName: "Status History",
              resizable: true,
              minWidth: 150,
              cellRenderer: (params) => {
                  return (
                      <Button
                          onClick={() =>
                              setStatusHistory((prev) => ({
                                  ...prev,
                                  isOpen: true,
                                  rowId: params?.data?.id,
                                  entity: params?.data?.part_name ?? "",
                              }))
                          }
                          size="small"
                          color="primary"
                      >
                          View
                      </Button>
                  );
              },
          }, */
    ];

    useEffect(() => {
        form.setFieldValue("scrapCommentsOptions", ["Broken", "Worn Out", "End of Life",
            "Setup Change", "Others"]);
    }, [scrapComments]);


    return (

        <>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <DGSelect
                        label="Cutting tool Status"
                        id="ctoolStatusSelected"
                        options={form.values.ctoolStatusOptions}
                        value={form.values.ctoolStatusSelected}
                        onChange={(value) => {
                            form.setFieldValue("ctoolStatusSelected", value);
                            if (value?.ctool_status_id === 109) {
                                setVendorFlag(true);
                                setScrapComments(false);
                            } else if (value?.ctool_status_id === 102) {
                                setScrapComments(true);
                                setVendorFlag(false);
                            } else {
                                setVendorFlag(false);
                                setScrapComments(false);
                            }
                        }}
                        getOptionLabel={(option) => option?.ctool_status ?? ""}
                        disabled={componentProps?.ctool_status_id === 103 || componentProps?.ctool_status_id === 109}
                    />

                </Grid>

                {vendorFlag && (
                    <Grid item xs={12}>
                        <DGSelect
                            label="Refurbishing Vendor"
                            id="ctoolStatusSelected"
                            options={form.values.vendorOptions}
                            value={form.values.vendorSelected}
                            onChange={(value) => form.setFieldValue("vendorSelected", value)}
                            getOptionLabel={(option) => option?.NABL_Agency ?? ""}
                        />

                    </Grid>
                )}

                {scrapComments && (
                    <Grid item xs={12}>
                        <DGSelect
                            id={"scrapComment"}
                            value={form.values.scrapComment}
                            label="Comments"
                            onChange={(value) => form.setFieldValue("scrapComment", value)}
                            required
                            options={form.values.scrapCommentsOptions}
                            getOptionLabel={(option) => option}
                            isFormik
                            freeSolo={true}
                        />
                    </Grid>
                )}




                <Grid item xs={12} style={{ marginTop: "40px" }}>
                    <CommonGrid
                        columns={columns}
                        rows={row}

                    />

                </Grid>





                <Footer onSave={form.handleSubmit} />
            </Grid>

        </>
    )


}