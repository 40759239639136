import React from "react";
import { ResultRenderer } from "../../../../../components/cellRenderers/ResultRenderer";
import CommonGrid from "../../../../../components/CommonGrid";
import {
  Grid, Typography
} from "@material-ui/core";


export function ResultInfo({
  reportData = [],
}) {
  const [rows, setRows] = React.useState([]);
  const [rawData, setRawData] = React.useState([]);
  const [finalResult, setFinalResult] = React.useState("");

  React.useEffect(() => {
    setRawData(reportData)
    const filteredArray = reportData.filter(item => item.hasOwnProperty("parameter"));
    setRows(filteredArray);
  }, [reportData]);


  React.useEffect(() => {
    if (!rawData) {
      return;
    }

    const lastItem = rawData[rawData.length - 1];

    if (lastItem?.result_grr_all === 1) {
      setFinalResult("Meets Requrirment");
    } else if (lastItem?.result_grr_all === 2) {
      setFinalResult("Conditionally meet requirement");
    }
    else {
      setFinalResult("Does Not meet Requirement");
    }

  }, [rows]);



  const columns = [
    {
      field: "parameter",
      headerName: "Parameter",
      resizable: true,
      minWidth: 270,
    },
    {
      field: "requirments",
      headerName: "Requirement",
      maxWidth: 150,
    },
    {
      field: "actual_result",
      headerName: "Result",
      maxWidth: 150,
      valueGetter: (params) => {
        const actualResult = parseFloat(params.data?.actual_result);

        if (isNaN(actualResult)) {
          return "";
        }

        if (params.data?.parameter !== "NDC (Atleast)") {
          return actualResult.toFixed(2);
        } else {
          return actualResult.toFixed(0);
        }
      }
    }
    ,
    {
      field: "conclusion",
      headerName: "Conclusion",
      resizable: true,
      minWidth: 340,
    },
    {
      field: "result",
      headerName: "Result",
      resizable: true,
      cellRenderer: (params) => {
        return <ResultRenderer value={params?.data?.result} />;
      },
    },
  ]

  return (

    <div style={{
      border: "1px solid black",
      boxSizing: "border-box",
      height: "100%",
    }}>
      <Typography variant="h4"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F8E6D8",
          padding: "5px",
        }}>
        <strong >
          Result : {finalResult}
        </strong>
      </Typography>

      <div style={{ padding: "10px" }}>

        <CommonGrid
          needExport={false}
          rows={rows}
          columns={columns}
          gridheight={185}
          fixedHeight
        />

      </div>
    </div>

  );
}
