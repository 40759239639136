import http from "./httpService";



const getCToolTable = async (client_id) => {
    return http.get("/cutting-tool-master", {
        params: {
            client_id: client_id,
        },
    });
};

const getCToolTable2 = async (data) => {
    return http.get("/ctool-master-table", {
        params: { ...data },
    });
};


const createCToolMaster = (data) => {
    return http.post("/cutting-tool-master", data);
}

const updateCuttingTool = async (id, newStatus) => {
    return http.patch(`/cutting-tool-master/${id}`, newStatus)
}


const getCToolProduct = async (data) => {
    return http.get("/ctool-product-table", { params: { ...data } })
}

const createCToolProduct = async (data) => {
    return http.post("/cutting-tool-product", data);
};

const updateCuttingToolProduct = async (id, newStatus) => {
    return http.patch(`/cutting-tool-product/${id}`, newStatus)
}


const createCToolNumber = async (data) => {
    return http.post("/cutting-tool-number", data);
};


const getCToolNumber = async (data) => {
    return http.get("/ctool-number-table", { params: { ...data } })
}

const updateCToolNumber = async (id, newStatus) => {
    return http.patch(`/cutting-tool-number/${id}`, newStatus)
}

const modifyCToolNumber = async (id, data) => {
    return http.patch(`/cutting-tool-number/${id}`, data)
}

const createCtoolStatusChange = async (data) => {
    return http.post("/ctool-number-status", data);
}

const updateCtoolStatusChange = async (id, data) => {
    return http.patch(`/ctool-number-status/${id}`, data);
}


const getCToolNumberStatusTable = async (data) => {
    return http.get("/ctool-status-table", { params: { ...data } })
}

const getCToolNumberRefurbishTable = async (data) => {
    return http.get("/ctool-refurbish-table", { params: { ...data } })
}

const getStatusMaxID = async (data) => {
    return http.get("/ctool-status-maxid", { params: { ...data } })
}


const createCuttingToolIssue = async (data) => {
    return http.post("/cutting-tool-issue", data);
}

const updateCuttingToolIssue = async (id, data) => {
    return http.patch(`/cutting-tool-issue/${id}`, data);
}


const getCuttingToolTable = async (data) => {
    return http.get("/ctool-issue-table", { params: { ...data } })
}


export default {

    createCToolMaster,
    getCToolTable,
    getCToolTable2,
    updateCuttingTool,
    getCToolProduct,
    createCToolProduct,
    updateCuttingToolProduct,
    createCToolNumber,
    getCToolNumber,
    updateCToolNumber,
    modifyCToolNumber,
    createCtoolStatusChange,
    getCToolNumberStatusTable,
    getCToolNumberRefurbishTable,
    updateCtoolStatusChange,
    getStatusMaxID,
    createCuttingToolIssue,
    getCuttingToolTable,
    updateCuttingToolIssue
}