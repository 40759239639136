import {
    Grid, Button, FormControlLabel,
    Radio, RadioGroup, Box, Typography
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid"
import oeeService from "../../../../services/oeeService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import {
    format, startOfWeek, startOfMonth, endOfMonth, endOfWeek,
    startOfDay, endOfDay
} from 'date-fns'
import { StackedBarGraph } from "../../../../components/graphs/StackedBarGraphs";



export default function OEELossSummary() {

    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [dateDepth, setDateDepth] = useState(3);
    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatus } = useContext(StatusContext);
    const { globalPlant } = useContext(PlantContext);
    const { gridApi, setGridApi } = useState(null);
    const [graphOptions, setGraphOptions] = useState([])
    const [filteredRows, setFilteredRows] = useState([]);


    useEffect(() => {
        reloadGridData()
    }, [startDate, endDate]);


    useEffect(() => {

        switch (dateDepth) {
            case 1:
                setstartDate(new Date())
                setendDate(new Date())

                break;

            case 2:
                setstartDate(startOfWeek(new Date(), { weekStartsOn: 1 }))
                if (endOfWeek(new Date(), { weekStartsOn: 1 }) > new Date()) {
                    setendDate(new Date())
                } else {
                    setendDate(endOfWeek(new Date(), { weekStartsOn: 1 }))
                }
                break;

            case 3:
                setstartDate(startOfMonth(new Date()))
                // setdataLabel("Month");

                if (endOfMonth(new Date(), { weekStartsOn: 1 }) > new Date()) {
                    setendDate(new Date())
                } else {
                    setendDate(endOfMonth(new Date(), { weekStartsOn: 1 }))
                }

                break;

            default:

                // setdataLabel("User Defined");

                break;
        }



    }, [dateDepth]);

    useEffect(() => {

        setGraphOptions({
            ...graphOptions,
            subtitle: {
                text:
                    `${format(new Date(startDate), 'dd/MM/yy')} - ${format(new Date(endDate), 'dd/MM/yy')} `
            },
            title: {
                text: `Losses Summary - ${globalPlant?.globalPlant?.name}`,
            },


            series: [
                {
                    // type: "bar",
                    xKey: "loss_description",
                    yKey: "pareto_data",
                    type: "column",
                },
            ],

            // data: aggregatedData ?? [],

            legend: {
                enabled: false,
            },
        });


    }, []);



    function reloadGridData() {
        oeeService.getOEELossesForPlant({
            client_id: currentUser.client_id,
            plant_id: globalPlant?.globalPlant?.id,
            start_date: startDate,
            end_date: endDate

        })
            .then((res) => {
                setRows(res.data);
                const aggregatedData = Object.values(
                    res.data.reduce((acc, item) => {
                        if (!acc[item.loss_id]) {
                            acc[item.loss_id] = {
                                loss_description: item.loss_description,
                                pareto_data: 0
                            };
                        }
                        acc[item.loss_id].pareto_data += item.loss_duration;
                        return acc;
                    }, {})
                ).sort((a, b) => b.pareto_data - a.pareto_data);


                setGraphOptions({
                    data: aggregatedData ?? [],
                });
            });
    }

    function onGridReady(params) {
        setGridApi(params.api);
    }

    const columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 80,
        },



        {
            field: "loss_type",
            headerName: "Loss Category",
            filter: "agMultiColumnFilter",
            minWidth: 150,
            flex: 1,

        },

        {
            field: "loss_description",
            headerName: "Loss Description",
            minWidth: 200,
            flex: 1,
        },

        {
            field: "loss_duration",
            headerName: "Loss Duration",
            flex: 1,
            minWidth: 150,

        },


        {
            field: "is_planned",
            headerName: "Planned loss",
            flex: 1,
            valueFormatter: (params) => {
                return params.value === 1 ? '✔️' : '';
            }


        },

        {
            field: "part_number",
            headerName: "Part Details",
            filter: "agMultiColumnFilter",
            minWidth: 250,
            valueGetter: (params) => {
                return params.data?.part_number + " - " + params.data?.part_name ?? ""
            }

        },

        {
            field: "machine_detials",
            headerName: "Machine",
            filter: "agMultiColumnFilter",
            minWidth: 250,
            flex: 1,
            valueGetter: (params) => {
                return params.data?.machine_number + " - " + params.data?.machine_name ?? ""
            }

        },

        {
            field: "record_counter",
            headerName: "Record Number",
            flex: 1,
            minWidth: 150,

            cellRenderer: (params) => {
                return (
                    /*      <Button
                             size="small"
                             color="primary"
                             onClick={() => {
                                 //     loadViewForm(params?.data);
                             }}
                         >
                             {'PREC/' + params?.data?.record_counter}
                         </Button> */

                    'PREC/' + params?.data?.record_counter
                );
            },

        },

        {
            field: "production_date",
            headerName: "Date:Production",
            flex: 1,
            editable: false,
            resizable: true,
            // floatingFilter: true,
            //  filter: "agMultiColumnFilter",
            minWidth: 150,
            sort: "desc",
            sortable: true,
            valueGetter: (params) => {
                return new Date(params?.data?.production_date);
            },
            valueFormatter: (params) => {
                return format(new Date(params.value), 'dd/MM/yyyy');
            }
        }



    ]

    const handleFilterApplied = (event) => {
        const allDisplayedNodes = event.api.getRenderedNodes();
        const displayedData = allDisplayedNodes.map(node => node.data);
        setFilteredRows(displayedData);

        const aggregatedData = Object.values(
            displayedData.reduce((acc, item) => {
                if (!acc[item.loss_id]) {
                    acc[item.loss_id] = {
                        loss_description: item.loss_description,
                        pareto_data: 0
                    };
                }
                acc[item.loss_id].pareto_data += item.loss_duration;
                return acc;
            }, {})
        ).sort((a, b) => b.pareto_data - a.pareto_data);

        setGraphOptions({
            ...graphOptions,
            data: aggregatedData ?? [],
            subtitle: {
                text:
                    `${format(new Date(startDate), 'dd/MM/yy')} - ${format(new Date(endDate), 'dd/MM/yy')} `
            },
        });
    };



    return (
        <Grid container spacing={3}>

            <Grid item xs={12}
                style={{
                    "margin-top": "20px",
                    "height": "400px"

                }
                }>
                <StackedBarGraph
                    options={graphOptions}
                />

            </Grid>

            <Grid item xs={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    gridheight={300}
                    needExport={false}
                    onGridReady={onGridReady}
                    onFilterChanged={handleFilterApplied}
                />
            </Grid>

            <Grid container spacing={2}
                style={{
                    "padding": "5px",
                    "margin-top": "10px",
                    "margin-bottom": "10px"
                }}>

                <Grid item xs={5} container justifyContent="center" alignItems="flex-left">
                    <RadioGroup row
                        style={{ gap: '12px' }}
                        value={dateDepth.toString()}
                        onChange={(event) => {
                            const newValue = parseInt(event.target.value);
                            setDateDepth(newValue);
                        }}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Day" />
                        <FormControlLabel value="2" control={<Radio />} label="Week" />
                        <FormControlLabel value="3" control={<Radio />} label="Month" />
                        <FormControlLabel value="4" control={<Radio />} label="User Defined" />
                    </RadioGroup>

                </Grid>

                <Grid item xs={7} container alignItems="flex-left">
                    <Grid container spacing={2}>

                        <Grid item xs={4}>
                            <DGDateInput
                                type="date"
                                label="Start Date"
                                value={startDate}
                                onChange={(date) => {
                                    setstartDate(startOfDay(date))
                                    setDateDepth(4);
                                }}
                                disabled={dateDepth < 4}
                            />
                        </Grid>
                        <Grid item xs={4}>

                            <DGDateInput
                                type="date"
                                label="End Date"
                                value={endDate}
                                onChange={(date) => {
                                    setendDate(endOfDay(date));
                                }}
                                disabled={dateDepth < 4}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>
    )

}