
import { AppBar, Button, Divider, Grid, Toolbar } from "@material-ui/core";
import { useFormik } from "formik";

import React, { useContext, useEffect, useState } from "react";
import disptachInspection from "../../../services/disptachInspection";
import studyInputService from "../../../services/studyInputService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { object } from "yup";
import { DGSelect } from "../../../components/shared/DGSelect";
import { DGInput } from "../../../components/shared/DGInput";
import { useParams } from "react-router-dom";
import CommonGrid from "../../../components/CommonGrid";
import { transparentize } from "polished";


export function DispatchPlanNew({
    Footer = () => null,
    closeForm = () => { },
    componentProps = null,
}) {

    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { updateAlert } = useContext(AlertContext);
    const [gridApi, setGridApi] = useState(null);
    const [rows, setRows] = useState([]);
    const [gaugeChar, setGaugeChar] = useState([]);
    let id = useParams();
    const { v4: uuidv4 } = require("uuid");



    let form = useFormik({
        initialValues: {
            static_header_1: [],
            static_header_2: [],
            user_header: [],
            footer_header: [],
            gridData: [],
            gridColumns: [],
            planOptions: [],
            planSelected: [],
            itemGroup1: [],
            itemGroup2: [],
            itemGroup3: [],
            itemGroup4: [],
            refSampleSize: "",
            measurementOptions: []

        },

        validationSchema: object({
            planSelected: object().required("Standard Plan is required"),

        }),

        onSubmit: savePlan

    });

    function onGridReady(params) {
        setGridApi(params?.api);
    }

    useEffect(() => {
        disptachInspection.getPDIStdListClient(
            currentUser?.client_id,
            globalPlant?.globalPlant?.id,
        ).then((res) => {
            const data1 = res?.data ?? [];
            const data2 = data1.filter((item) => item?.enabled === 1);
            form.setFieldValue("planOptions", data2 ?? []);
        })

        disptachInspection.getCharGauge(id.id).then((res) => {
            setRows(res.data ?? []);

        })


        studyInputService.getMeasurementType().then((res) => {
            form.setFieldValue("measurementOptions", res?.data?.data ?? []);
        });




    }, []);


    useEffect(() => {

        if (form.values.planSelected.length < 1) {
            return;
        }

        disptachInspection.getPDIStdPlan(form.values.planSelected?.pdi_standard_plan_id
        ).then((res) => {
            const temp = res?.data
            const temp1 = temp.filter((item) => item?.item_group_id === 1 && item?.item_mandatory !== 3);
            form.setFieldValue("itemGroup1", temp1);
            const temp2 = temp.filter((item) =>
                item?.item_group_id === 2 &&
                item?.item_mandatory !== 3 &&
                item?.item_mandatory !== null
            );
            form.setFieldValue("itemGroup2", temp2);

            const temp3 = temp.filter((item) =>
                item?.item_group_id === 3 &&
                item?.item_mandatory !== 3 &&
                item?.item_mandatory !== null
            );
            form.setFieldValue("itemGroup3", temp3);

            const temp4 = temp.filter((item) =>
                item?.item_group_id === 4 &&
                item?.item_mandatory !== 3 &&
                item?.item_mandatory !== null
            );
            form.setFieldValue("itemGroup4", temp4);

        });


    }, [form.values.planSelected]);


    useEffect(() => {

        const Urows = rows.map(item => ({
            ...item,
            sample_size: form.values.refSampleSize,
        }));

        setRows(Urows);

    }, [form.values.refSampleSize]);


    useEffect(() => {
        const mergedData = rows.map(character => {
            const characterGauges = gaugeChar.filter(gaugeChar => gaugeChar.character_id === rows.character_id);
            return {
                ...character,
                gauge_options: characterGauges
            };
        });

        setRows(mergedData);


    }, [gaugeChar]);


    let columns = [

        {
            field: "required_evaluation",
            headerName: "Evaluation Required",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            minWidth: 100,
            resizable: true,
            setSelected: false

        },
        {
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            resizable: true,
            maxWidth: 90,
        },
        {
            headerName: "Characterstics",
            field: "character_name",
            resizable: true,
            minWidth: 200,
        },
        {
            headerName: "Specification",
            field: "specification",
            resizable: true,
            minWidth: 200,
            valueGetter: (params) =>
                `  ${params?.data?.specification ? params.data.specification : ""}
                        ${params?.data?.upper_specification ? params.data.upper_specification : ""}
                        ${params?.data?.lower_specification ? params.data.lower_specification : ""}`,

        },

        {
            headerName: "Measurement Type",
            field: "measurement_type",
            resizable: true,
            minWidth: 150,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellStyle: (params) => {
                const val = params.data.measurement_type_id
                if (val === null || val === '' || isNaN(val)) {
                    return { 'background-color': transparentize(0.9, 'red') };
                }
                return null;
            },

            cellEditorParams: (params) => {
                return {
                    values: form.values.measurementOptions.map((item) => item.study),
                }
            },
            valueGetter: (params) => {
                const measurement = form.values.measurementOptions.find(
                    (item) => item.id === params.data.measurement_type_id
                );
                return measurement ? measurement.study : "";
            },

            valueSetter: (params) => {
                const selectedStudy = params.newValue;
                const selectedMeasurement = form.values.measurementOptions.find(
                    (item) => item.study === selectedStudy
                );
                if (selectedMeasurement) {
                    params.data.measurement_type_id = selectedMeasurement.id;
                    return true;
                }
                return false;
            }
        },
        {
            headerName: "Gauge",
            field: "gauge_name",
            resizable: true,
            minWidth: 250,
            editable: true,
            cellClass: "custom-inline-editing-cell",
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: (params) => {
                const gaugeOptions = params.data.gauge_options?.gauge_options || [];
                return {
                    values: gaugeOptions.map((item) => `${item.gauge_number} (${item.gauge_name})`),
                    allowTyping: true,
                    filterList: true,
                    highlightMatch: true,
                };
            },
            valueGetter: (params) => {
                if (!params.data || !params.data.gauge_options?.gauge_options) return "";
                const selectedGauge = params.data.gauge_options.gauge_options.find(
                    (item) => item.gauge_id === params.data.gauge_id
                );
                return selectedGauge ? `${selectedGauge.gauge_number} (${selectedGauge.gauge_name})` : "";
            },
            valueSetter: (params) => {
                if (!params.data || !params.data.gauge_options?.gauge_options) return false;
                const gaugeOptions = params.data.gauge_options.gauge_options;
                const selectedGauge = gaugeOptions.find(
                    (item) => `${item.gauge_number} (${item.gauge_name})` === params.newValue
                );

                if (selectedGauge) {
                    params.data.gauge_id = selectedGauge.gauge_id;
                    return true;
                }
                return false;
            },
        },

        {
            headerName: "Sample Size",
            field: "sample_size",
            resizable: true,
            minWidth: 50,
            editable: true,
            cellEditor: "agTextCellEditor",
            valueGetter: (params) => {
                return params.data.sample_size || '';
            },

            cellStyle: (params) => {
                const val = params.data.sample_size
                if (val === null || val === '' || isNaN(val)) {
                    return { 'background-color': transparentize(0.9, 'red') };
                }
                return null;
            },

            valueSetter: (params) => {
                const newValue = parseInt(params.newValue, 10);
                if (!isNaN(newValue)) {
                    params.data.sample_size = newValue;
                    return true;
                }
                return false;
            }
        }

    ];



    function savePlan() {
        const uID = uuidv4()

        const data1 = {

            id: uID,
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,
            part_id: id.id,
            pdi_standard_plan_id: form.values.planSelected?.pdi_standard_plan_id,
            dispatch_plan_name: form.values.planSelected?.plan_name,
            ref_id: componentProps + 1,
            created_by: currentUser?.id,
            enabled: 1
        }

        let data2 = [];

        gridApi?.forEachNode((node) => {
            if (node.data.required_evaluation === true

            ) {
                node.setSelected(true);
            }
        });

        gridApi?.forEachNode((node) => {
            if (node.data?.sample_size === undefined &&
                node.data?.measurement_type_id == undefined) {
                node.setSelected(false);
            }
        })

        gridApi?.forEachNode((node) => {
            if (node.isSelected()) {
                data2.push({
                    id: uuidv4(),
                    pdi_dispatch_plan_id: uID,
                    character_id: node.data.id,
                    pdi_sample_size: parseInt(node.data.sample_size),
                    gauge_id: node.data.gauge_id ?? null,
                    measurement_type: node.data.measurement_type_id,
                    order_number: parseInt(node.id) + 1,
                    created_by: currentUser?.id,
                    enabled: 1

                })
            }

        })

        disptachInspection.createPartPDI1(data1).then((res) => {
            if (res?.status === 201) {
                disptachInspection.createPartPDI2(data2).then((res) => {
                    if (res?.status === 201) {
                        updateAlert({
                            message: "Dispatch Plan Created Successfully",
                            open: true,
                            type: "success",
                        });
                        closeForm && closeForm(false);
                    } else {
                        updateAlert({
                            message: "Failed to create Dispatch Plan",
                            open: true,
                            type: "error",
                        });
                    }
                })
            }
            else {
                updateAlert({
                    message: "Failed to create Dispatch Plan",
                    open: true,
                    type: "error",
                });
            }
        })

    }

    return (

        <>
            <Grid container spacing={2}>


                <>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <DGInput
                                label="Plan Number"
                                value={componentProps + 1}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DGSelect
                                id="planSelected"
                                label="Standard Plan"
                                value={form.values.planSelected}
                                options={form.values.planOptions}
                                getOptionLabel={(option) => option.plan_name}
                                onChange={(value) => {
                                    form.setFieldValue("planSelected", value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DGInput
                                label="Sample Size"
                                value={form.values.refSampleSize}
                                isFormik
                                onChange={form.handleChange}
                                required
                                name="refSampleSize"
                                id="refSampleSize"
                            />
                        </Grid>
                    </Grid>

                </>

                <Grid container spacing={2} style={{ "margin-top": "5px" }}>
                    {form.values.itemGroup1.map((item, index) => (
                        <Grid item xs={true} key={index}>
                            <DGInput
                                label={item.item_name}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Grid container spacing={2} style={{ "margin-top": "10px" }}>
                    {form.values.itemGroup2.map((item, index) => (
                        <Grid item xs={true} key={index}>
                            <DGInput
                                label={item.item_name}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Grid container spacing={2} style={{ "margin-top": "10px" }}>
                    {form.values.itemGroup4.map((item, index) => (
                        <Grid item xs={true} key={index}>
                            <DGInput
                                label={item.item_name}
                            />
                        </Grid>
                    ))}
                </Grid>


                <Grid item xs={12} style={{ "margin-top": "10px" }}>

                    <CommonGrid
                        rows={rows}
                        columns={columns}
                        gridheight={150}
                        suppressRowClickSelection={true}
                        needExport={false}
                        onGridReady={onGridReady}
                        rowSelection={"multiple"}

                    />


                </Grid>


                <Grid container spacing={2} style={{ "margin-top": "10px" }}>
                    {form.values.itemGroup3.map((item, index) => (
                        <Grid item xs={true} key={index}>
                            <DGInput
                                label={item.item_name}
                            />
                        </Grid>
                    ))}
                </Grid>



                <AppBar position="fixed" style={{ width: 1200, top: "auto", bottom: 0 }}>
                    <Divider />
                    <Toolbar>
                        <div style={{ flexGrow: 1 }}></div>

                        <Button
                            style={{ margin: 4 }}
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => closeForm && closeForm(false)}
                        >
                            CANCEL
                        </Button>

                        <Button
                            style={{ margin: 4 }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={form.handleSubmit}
                            disabled={!form.isValid}
                        >
                            Save & Close
                        </Button>
                    </Toolbar>
                </AppBar>


            </Grid>
        </>

    );
}