/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, TextareaAutosize, FormControlLabel, Checkbox } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { array, object, string } from "yup";


import { DeleteRenderer } from "../../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import { ACTION_TYPE } from "../../../../components/shared/commonenum";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";

import { AuthContext } from "../../../../context/AuthContextProvider";
import { AlertContext } from "../../../../context/AlertContextProvider";

import productionInspection from "../../../../services/productionInspection";
import studyInputService from "../../../../services/studyInputService";
import { GridApi } from "ag-grid-community";
import errorLog from "../../../../services/errorLog";
import { set } from "date-fns";
import { te } from "date-fns/locale";

export function NewFromPlanForm({
  Footer = () => { },
  componentProps = null,
  closeForm = () => { },
}) {
  const { currentUser } = useContext(AuthContext);
  const [GridApiContext, setGridApi] = useState(null);
  const [refreshedData, setRefreshedData] = useState(null);
  const { updateAlert } = useContext(AlertContext);
  const [toolFlag, setToolFlag] = useState(null);
  const { v4: uuidv4 } = require("uuid");

  let deatilForm = useFormik({
    initialValues: {

      gaugeOptions: [],
      samples: "",
      tolerance: null,
      characterstics: null,
      gauge: "",
      measurement_options: null,
      process_number: "",
    },
    validationSchema: object({
      characterstics: object().required(),
      samples: string().required(),
      // measurement_type: object().required(),
    }),

    onSubmit: handlePlanDetailsAdd,
  });


  let form = useFormik({
    initialValues: {
      plant: "",
      part: "",
      plan: "",
      instructions: "",
      createdby: "",
      createddate: "",
      gridData: [],
      measurement_options: [],
      part_id: "",
      plant_id: "",
      tool: [],
      tool_id: "",
      measurement_id: "",
      machineRelease: 0,
      charactersticsOption: [],
      charactersticsOptionInitial: [],
      charSelected: null,

    },
    validationSchema: object({
      plan: string().required(),
      gridData: array().min(1),
    }),
    onSubmit: upsertForm,
  });

  useEffect(() => {
    productionInspection
      .getInspectPlanView(componentProps.selectedId)
      .then((res) => {
        form.setFieldValue("gridData", res?.data?.body ?? []);
        setRefreshedData(res?.data?.body ?? [])
        form.setFieldValue("plant", res?.data?.header[0].name ?? []);
        form.setFieldValue("plant_id", res?.data?.header[0].plant_id ?? []);
        form.setFieldValue("part_id", res?.data?.header[0].part_id ?? []);
        setToolFlag(res?.data?.header[0]?.toolFlag,)
        form.setFieldValue(
          "part",
          `${res?.data?.header[0].part_number ?? []}-${res?.data?.header[0].part_name ?? []}`
        );

        const updatedToolValue = {
          tool_name: res?.data?.header[0].tool_name ?? null,
          tool_number: res?.data?.header[0].tool_number ?? null,
          machine_name: res?.data?.header[0].machine_name ?? null,
          machine_number: res?.data?.header[0].machine_number ?? null,
          id: res?.data?.header[0].tool_product_id ?? null,

        };

        form.setFieldValue("tool", updatedToolValue);

        let temp1 = res.data.body
          .map((row) => row.character_id)
          .flat();

        form.setFieldValue("charSelected", temp1);

      })
  }, []);


  function onGridReady(params) {
    setGridApi(params);
    const allColumnIds = params.columnApi.getAllColumns().map(col => col.getId());
    // params.columnApi.autoSizeColumns(allColumnIds);

  }

  useEffect(() => {
    productionInspection
      .getInspectionPlanChar(form.values.part_id)
      .then((res) => {
        form.setFieldValue("charactersticsOptionInitial", res?.data ?? []);
        const temp1 = form.values.charSelected
        const filteredOptions = res.data.filter(
          (option) => !temp1.includes(option.character_id)
        );
        form.setFieldValue("charactersticsOption", filteredOptions);

      });
  }, [form.values.part_id]);

  function loadGauge(id, flag) {
    productionInspection
      .getInspectionPlanCharGauge(id, flag, currentUser?.client_id)
      .then((res) => {
        deatilForm.setFieldValue("gaugeOptions", res?.data ?? []);
      });
  }

  useEffect(() => {
    studyInputService.getMeasurementType().then((res) => {
      form.setFieldValue("measurement_options", res?.data?.data ?? []);
    });



  }, []);


  function upsertForm() {
    let payload = [];
    refreshedData.forEach((exp) => {
      payload.push({
        plant_id: form.values.plant_id,
        part_id: form.values.part_id,
        character_id: exp?.character_id,
        gauge_id: exp?.gauge_id,
        sample_size: exp?.sample_size,
        reduced_tolerance: exp?.reduced_tolerance,
        process_number: exp?.process_number,
        created_by: currentUser?.id,
        instructions: form.values.instructions,
        plan_name: form.values.plan,
        measurement_type: parseInt(exp.measurement_id, 10),
        product_char_flag: exp?.product_char_flag,
        tool_product_id: form.values.tool?.id,
        machine_release: form.values.machineRelease,

      });
    });
    productionInspection.createProductionInspectionPlan(payload)
      .then((res) => {
        updateAlert({
          open: true,
          message: "Plan Created Successfully",
          type: "success",
        });
        closeForm();
      })
      .catch((error) => {
        updateAlert({
          open: true,
          message: "Failed to create Plan",
          type: "error",
        });
        let payload = {
          id: uuidv4(),
          error_description: error.message,
          error_location: "createProductionInspectionPlan - New From",
          client_id: currentUser.client_id,
          user_id: currentUser.id,
          entity_id: null,
        };
        errorLog.createErrorLog(payload)

      });
  }

  function removeDetailRow(rowIndex, data) {
    let plans = form.values.gridData;
    plans.splice(rowIndex, 1);
    GridApiContext.api.updateRowData({ remove: [data] });
    const rowsToDisplay = GridApiContext.api.rowModel.rowsToDisplay;
    const rowDataArray = rowsToDisplay.map((rowNode) => rowNode.data);
    setRefreshedData(rowDataArray)

    let temp1 = plans
      .map((row) => row.character_id)
      .flat();

    form.setFieldValue("charSelected", temp1);

    const filteredOptions = form.values.charactersticsOptionInitial.filter(
      (option) => !temp1.includes(option.character_id)
    );
    form.setFieldValue("charactersticsOption", filteredOptions);
  }


  function onRowDragEnd(params) {
    const rowsToDisplay = params.api.rowModel.rowsToDisplay;
    const rowDataArray = rowsToDisplay.map((rowNode) => rowNode.data);
    setRefreshedData(rowDataArray)
  }

  //console.log(deatilForm.isValid)

  function handlePlanDetailsAdd(values) {
    if (!deatilForm.isValid) {
      return;
    }
    let data = form.values.gridData;
    // let chars = deatilForm.values.charactersticsOption;
    data = [
      ...data,
      {
        name: values?.characterstics?.character_name,
        lower_specification: values?.characterstics?.lower_specification,
        upper_specification: values?.characterstics?.upper_specification,
        specification: values?.characterstics?.specification,
        character_id: values?.characterstics?.character_id,
        gauge_name: values?.gauge?.name,
        gauge_number: values?.gauge?.number,
        gauge_id: values?.gauge?.gauge_id,
        sample_size: values?.samples,
        reduced_tolerance: values?.tolerance,
        type: values?.characterstics.type,
        measurement_type: values?.measurement_type,
        measurement_id: values?.measurement_type.id,
        product_char_flag: values?.characterstics?.product_char_flag,
        process_number: values?.process_number,

      },
    ];
    form.setFieldValue("gridData", data);
    setRefreshedData(data)
    deatilForm.resetForm();
    //deatilForm.setFieldValue("charactersticsOption", chars);
  }


  useEffect(() => {
    if (form.values.gridData.length === 0) {
      return
    }

    let temp1 = form.values.gridData
      .map((row) => row.character_id)
      .flat();

    form.setFieldValue("charSelected", temp1);

    const filteredOptions = form.values.charactersticsOptionInitial.filter(
      (option) => !temp1.includes(option.character_id)
    );
    form.setFieldValue("charactersticsOption", filteredOptions);

  }, [form.values.gridData]);


  const columns = [

    {
      field: "serial_number",
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 80,
      pinned: "left",

    },
    {
      headerName: "Delete",
      cellRenderer: DeleteRenderer,
      minWidth: 100,
      cellRendererParams: {
        onClick: (data, rowIndex) => {
          removeDetailRow(rowIndex, data);
        },
      },
    },


    {
      field: "process_number",
      headerName: "Process Number",
      filter: "agTextColumnFilter",
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Characteristics",
      minWidth: 400,
      flex: 1,
      resizable: true,
      autoHeight: true,
      tooltipShowDelay: 0,
      rowDrag: true,
      cellStyle: {
        whiteSpace: 'pre-wrap',
        lineHeight: '1.3'
      },

    },
    {
      field: "specification",
      headerName: "Specification",
      minWidth: 200,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      tooltipShowDelay: 0,

      tooltipValueGetter: (params) => `${params?.data?.name}`,
      valueGetter: (params) => {
        return ` ${params?.data?.specification ?? ""}  ${params?.data?.upper_specification ?? ""
          }  ${params?.data?.lower_specification ?? ""}`;
      },
    },
    {
      headerName: "Measurement",
      field: "measurement_type.study",
      minWidth: 150,
      resizable: true,
    },
    {
      field: "gauge_name",
      headerName: "Gauge",
      minWidth: 150,
      resizable: true,
      valueGetter: (params) =>
        params?.data?.gauge_number
          ? `${params?.data?.gauge_number}-${params?.data?.gauge_name}`
          : "",
    },
    {
      field: "sample_size",
      headerName: "Samples Nos",
      minWidth: 75,
      resizable: true,
    },

    {
      field: "reduced_tolerance",
      headerName: "Reduced Tolerance(%)",
      minWidth: 75,
      resizable: true,
    },
  ];


  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3} sm={3} lg={3}>
          <DGInput
            id="plant"
            label="Plant"
            disabled
            value={form.values.plant}
            onChange={form.handleChange}
            isFormik
          />
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <DGInput
            id="part"
            label="Part Number"
            disabled
            value={form.values.part}
            onChange={form.handleChange}
            isFormik
          />
        </Grid>
        <Grid item md={3} sm={3} lg={3}>
          <DGInput
            id="plan"
            label="Plan Name"
            value={form.values.plan}
            onChange={form.handleChange}
            isFormik
            error={form.touched.plan && Boolean(form.errors.plan)}
          />

        </Grid>
        <Grid item md={2} sm={2} lg={2}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.values.machineRelease}
                  onChange={(e) => {
                    form.setFieldValue("machineRelease", e.target.checked ? 1 : 0);
                  }}

                  name="machineRelease"
                  color="primary"
                />
              }
              label="Machine Release"
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            />
          </div>
        </Grid>


        {toolFlag === 1 && (
          <Grid item md={6} lg={6} sm={6}>
            <DGSelect
              id="tool"
              label="Form tool"
              options={form.values.toolOptions}
              value={form.values.tool}
              onChange={(val) => {
                form.setFieldValue("tool", val);
              }}
              isFormik
              disabled
              getOptionLabel={(opt) =>
                `${opt?.tool_name} ${opt?.tool_number}  ${opt?.machine_number} ${opt?.machine_name}`
              }
              error={
                form.touched.tool &&
                Boolean(form.errors.tool)
              }
            />
          </Grid>
        )
        }

        <Grid container spacing={2} item md={12} sm={12} lg={12}>
          <Grid item md={12} lg={12} sm={12}>
            <Box fontWeight="fontWeightBold" m={1}>
              Plan Details
            </Box>
          </Grid>

          <Grid item md={7} lg={7} sm={7}>
            <DGSelect
              id="characterstics"
              label="Characterstics"
              options={form.values.charactersticsOption}
              value={deatilForm.values.characterstics}
              onChange={(val) => {
                deatilForm.setFieldValue("characterstics", val);
                loadGauge(
                  val?.character_id,
                  val?.product_char_flag,
                  val?.character_type_id
                );
                deatilForm.setFieldValue("gauge", "");
              }}
              isFormik
              required
              getOptionLabel={(opt) =>
                `${opt?.character_name} ${opt?.specification !== null ? opt?.specification : ""
                } ${opt?.upper_specification !== null
                  ? opt?.upper_specification
                  : ""
                } ${opt?.lower_specification !== null
                  ? opt?.lower_specification
                  : ""
                } `
              }
              error={
                deatilForm.touched.characterstics &&
                Boolean(deatilForm.errors.characterstics)
              }
            />
          </Grid>
          <Grid item md={2} lg={2} sm={2}>
            <DGInput
              id="samples"
              label="Number of Samples"
              value={deatilForm.values.samples}
              onChange={deatilForm.handleChange}
              isFormik
              required
            />
          </Grid>
          <Grid item md={3} lg={3} sm={3}>
            <DGSelect
              id="measurement_type"
              label="Measurement Type"
              getOptionLabel={(opt) => opt?.study}
              options={form.values.measurement_options}
              value={deatilForm.values.measurement_type}
              onChange={(val) => {
                deatilForm.setFieldValue("measurement_type", val)
                deatilForm.setFieldValue("measurement_id", val?.id)
              }}
              isFormik
              required
            />
          </Grid>




          <Grid item md={2} lg={2} sm={2}>
            <DGInput
              id="tolerance"
              label="Reduced Tolerance"
              value={deatilForm.values.tolerance}
              onChange={deatilForm.handleChange}
              isFormik
              type="number"
            />
          </Grid>




          <Grid item md={3} lg={3} sm={3}>
            <DGInput
              id="process_number"
              label="Process Number"
              value={deatilForm.values.process_number}
              onChange={deatilForm.handleChange}
              isFormik
            />
          </Grid>
          <Grid item md={4} lg={4} sm={4}>
            <DGSelect
              id="gauge "
              label="Gauge"
              options={deatilForm.values.gaugeOptions}
              getOptionLabel={(opt) =>
                opt?.number ? `${opt?.number}-${opt?.name}` : null
              }
              value={deatilForm.values.gauge}
              onChange={(val) => deatilForm.setFieldValue("gauge", val)}
              isFormik
            />
          </Grid>

          <Grid
            item md={3} lg={3} sm={3}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              color="primary"
              variant="outlined"
              disabled={!deatilForm.isValid}
              onClick={deatilForm.handleSubmit}
              style={{
                width: '75%',
              }}

            >
              Add
            </Button>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} lg={12}
          style={{ marginTop: "10px" }}>
          <CommonGrid
            onGridReady={onGridReady}
            rows={form.values.gridData?.length ? form.values.gridData : []}
            columns={columns}
            onRowDragEnd={onRowDragEnd}
            rowModelType="clientSide"
            needExport={false}
            rowDragManaged={true}
            getRowStyle={(params) => {
              if (params?.data?.product_char_flag === 0) {
                return { backgroundColor: "#E5E7E9" };
              }
            }}
          />
        </Grid>

        <Grid item md={6} lg={6} sm={6}>
          <TextareaAutosize
            maxRows={2}
            className="w-100 border h-100"
            aria-label="maximum height"
            placeholder="Instructions"
            defaultValue=""
          />
        </Grid>

        <Grid item md={3} sm={3} lg={3}>
          <DGInput
            id="createdby"
            label="Created By"
            value={form.values.createdby}
            disabled
            isFormik
          />
        </Grid>
        <Grid item md={3} sm={3} lg={3}>
          <DGDateInput id="createddate" label="Created Date" disabled />
        </Grid>
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </>
  );
}
