import {
    Typography, Grid
} from "@material-ui/core";
import React from "react";
import { format } from "date-fns";


export function StaticApproval({
    reportData = {}

}) {

    return (

        <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="flex-end"
            marginTop="10px"
        >

            <Grid item xs={6}
                style={{ paddingLeft: "10px" }} >
                <div>
                    <Typography variant="h4" align="left">
                        Initiated : {reportData?.approval_info?.initiated_by}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={6} >
                <div>
                    <Typography variant="h4" align="left">
                        Approval: {
                            reportData?.approval_info?.approved_by ? (
                                <>
                                    {reportData?.approval_info?.approved_by} Dated: {format(new Date(reportData?.approval_info?.approved_date), "MM/dd/yyyy")}
                                </>
                            ) : (
                                ""
                            )
                        }

                    </Typography>

                </div>
            </Grid>


            <Grid item xs={3} >
                <div>
                </div>
            </Grid>
        </Grid>

    )

}