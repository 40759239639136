import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";

import { StatusContext } from "../../../context/StatusContextProvider";
import { useFormik } from "formik";
import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import cuttingToolService from "../../../services/cuttingToolService";
import { PlantContext } from "../../../context/PlantContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import userService from "../../../services/userService";
import machineService from "../../../services/machineService";
import { DGSelect } from "../../../components/shared/DGSelect";
import productService from "../../../services/productService";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import { object, number } from "yup";
import { format } from "date-fns";
import { AlertContext } from "../../../context/AlertContextProvider";


export function CToolIssue(
    {
        Footer = () => { },
        closeForm = () => { },
    }
) {

    const { currentUser } = useContext(AuthContext)
    const { globalPlant } = useContext(PlantContext);
    const { updateStatus } = useContext(StatusContext);
    const [requestId, setRequestId] = useState(0);
    const [data1, setData1] = useState([]);
    const { v4: uuidv4 } = require('uuid');
    const { updateAlert } = useContext(AlertContext);



    const form = useFormik({
        initialValues: {
            issuedToOptions: [],
            issuedToSelected: null,

            machineOptions: [],
            machineSelected: null,

            partOptions: [],
            partSelected: null,

            cuttingToolNumberOptions: [],
            cuttingToolNumberSelected: null,

            cuttingToolMasterOptions: [],
            cuttingToolMasterSelected: null,

            issueDate: new Date(),

            issueComments: "",

            issuedTools: [],

            gridData: [],
        },
        validationSchema: object().shape({
            cuttingToolMasterSelected: object().required("Select a cutting Tool"),
        }),
        onSubmit: onSave
    })


    useEffect(() => {

        cuttingToolService.getCToolTable(currentUser.client_id)
            .then((res) => {
                form.setFieldValue("cuttingToolMasterOptions", res.data.data)

            });


        productService.getAProductByPlant
            (
                globalPlant?.globalPlant?.id,
            )
            .then((res) => {
                form.setFieldValue("partOptions", res.data)
            })


        userService.getUsersByPlantId
            (globalPlant?.globalPlant?.id,
                currentUser.client_id
            )

            .then((res) => {
                const data = res?.data
                setData1(data)
            })

        userService.getOperatorTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,
        })
            .then((res) => {
                const data = res?.data;
                const data1 = data.map((item) => ({
                    user_name: `${item.operator_name} ${item.operator_id}`,
                    user_id: item.id,
                }));
                userService.getUsersByPlantId
                    (globalPlant?.globalPlant?.id,
                        currentUser.client_id
                    )

                    .then((res) => {
                        const data2 = res?.data
                        const data = [...data1, ...data2]
                        form.setFieldValue("issuedToOptions", data)

                    })

            })

        cuttingToolService.getCuttingToolTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        }).then((response) => {
            const data1 = response.data
            const data2 = data1.filter((item) => item.return_date === null)
            const data3 = data2.map((item) => item.ctool_number_id);
            form.setFieldValue("issuedTools", data3)


        })



    }, [])


    useEffect(() => {
        if (form.values.cuttingToolMasterSelected) {
            cuttingToolService.getCToolNumber
                ({
                    plant_id: globalPlant?.globalPlant?.id,
                    client_id: currentUser.client_id,
                })
                .then((res) => {
                    const data = res.data
                    const data1 = data.filter((item) => item.ctool_id === form.values.cuttingToolMasterSelected.id)
                    const data2 = data1.filter((item) => !form.values.issuedTools.includes(item.id));
                    form.setFieldValue("cuttingToolNumberOptions", data2)

                })
        }
    }, [form.values.cuttingToolMasterSelected])

    useEffect(() => {

        if (form.values.cuttingToolNumberSelected) {

            cuttingToolService.getStatusMaxID({
                ctool_number_id: form.values.cuttingToolNumberSelected.id,
                client_id: currentUser.client_id
            })
                .then((res) => {
                    setRequestId(res.data[0].max_id)
                })
        }


    }, [form.values.cuttingToolNumberSelected])

    useEffect(() => {
        if (form.values.partSelected !== null) {
            machineService.getProductMachineDetail(
                form.values.partSelected.id,
                currentUser.client_id
            )
                .then((res) => {
                    const data = res.data
                    const data1 = data
                        .map((item) => ({
                            machine_number: item.machine_detail,
                            machine_id: item.machine_id,
                        }))
                        .filter(
                            (item, index, self) =>
                                index === self.findIndex((t) => t.machine_id === item.machine_id)
                        );



                    form.setFieldValue("machineOptions", data1)
                })
        }



    }, [form.values.partSelected])



    function onSave() {

        //console.log(form.values)

        const payload = {
            id: uuidv4(),
            client_id: currentUser.client_id,
            plant_id: globalPlant?.globalPlant?.id,
            ctool_id: form.values.cuttingToolMasterSelected.id,
            ctool_number_id: form.values.cuttingToolNumberSelected.id,
            part_id: form.values.partSelected.id,
            machine_id: form.values.machineSelected.machine_id,
            issued_to: form.values.issuedToSelected.user_id,
            issued_by: currentUser.id,
            issue_date: format(new Date(form.values.issueDate), "yyyy/MM/dd"),
            issue_time: format(new Date(form.values.issueDate), "HH:mm:ss"),
            tool_life: null,

            returned_by: null,
            return_date: null,
            return_time: null,
            return_remarks: null,
            received_by: null,

        }

        let payload0 = {
            ctool_status: 104,
            updated_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            comments: form.values.issueComments ?? ""

        };

        let payload2 = {
            id: uuidv4(),
            ctool_number_id: form.values.cuttingToolNumberSelected.id,
            ctool_status_id: 104,
            ctoolnumber_newlife: null,
            user_id: currentUser.id,
            vendor_id: form.values.vendorSelected?.id ?? null,
            refurbish_initiated_by: null,
            refurbish_initiated: null,
            refurbish_approved_by: null,
            refurbish_approved: null,
            enabled: 1,
            request_id: (requestId + 1),
            comments: form.values.issueComments ?? ""

        };


        cuttingToolService.modifyCToolNumber(form.values.cuttingToolNumberSelected.id, payload0)
            .then((res) => {
                if (res.status === 200) {
                    cuttingToolService.createCuttingToolIssue(payload)
                        .then((res) => {
                            if (res.status === 201) {
                                cuttingToolService.createCtoolStatusChange(payload2)
                                    .then((res) => {
                                        updateAlert({
                                            open: true,
                                            message: "Issued Cutting Tool",
                                            type: "success",
                                        });
                                        closeForm();

                                    })
                                    .catch(() => {
                                        updateAlert({
                                            open: true,
                                            message: "Failed to issue ",
                                            type: "error",
                                        });
                                    });
                            }
                        })

                }
            })
    }


    return (

        <Grid container spacing={2}>

            <Grid item xs={12}>
                <DGSelect
                    label="Cutting Tool Master"
                    options={form.values.cuttingToolMasterOptions}
                    getOptionLabel={(option) => `${option.ctool_name}`}
                    value={form.values.cuttingToolMasterSelected}
                    onChange={(e) => form.setFieldValue("cuttingToolMasterSelected", e)}
                />

            </Grid>

            <Grid item xs={12}>
                <DGSelect
                    label="Cutting Tool "
                    options={form.values.cuttingToolNumberOptions}
                    getOptionLabel={(option) => `${option.ctool_name} ${option.ctool_number}`}
                    value={form.values.cuttingToolNumberSelected}
                    onChange={(e) => form.setFieldValue("cuttingToolNumberSelected", e)}
                />

            </Grid>

            <Grid item xs={12}>
                <DGSelect
                    label="Part Number "
                    options={form.values.partOptions}
                    getOptionLabel={(option) => `${option.part_number} ${option.part_name}`}
                    value={form.values.partSelected}
                    onChange={(e) => form.setFieldValue("partSelected", e)}
                />

            </Grid>

            <Grid item xs={12}>
                <DGSelect
                    label="Machine "
                    options={form.values.machineOptions}
                    getOptionLabel={(option) => `${option.machine_number} `}
                    value={form.values.machineSelected}
                    onChange={(e) => form.setFieldValue("machineSelected", e)}
                />

            </Grid>


            <Grid item xs={12}>
                <DGSelect
                    label="Issued To "
                    options={form.values.issuedToOptions}
                    getOptionLabel={(option) => `${option.user_name}`}
                    value={form.values.issuedToSelected}
                    onChange={(e) => form.setFieldValue("issuedToSelected", e)}
                />

            </Grid>

            <Grid item xs={12}>
                <DGDateTimePicker
                    label="Production Date"
                    format="yyyy/MM/dd HH:mm"
                    value={form.values.issueDate}
                    onChange={(val) => {
                        form.setFieldValue("issueDate", val);
                    }}

                />
            </Grid>

            <Footer onSave={form.handleSubmit} />

        </Grid>
    )

}