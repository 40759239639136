import http from "./httpService";


const createDefectMaster = (data) => {
    return http.post("/defects-master-list", data);
};


const getDefectMasterTable = (data) => {
    return http.get("/defect-master-table", { params: { ...data } })
};


const updateDefectMasterTable = (id, data) => {
    return http.patch(`/defects-master-list/${id}`, data);
}


const createDefectClassification = (data) => {
    return http.post("/defects-record", data);
};

const getDefectRecordTable = (data) => {
    return http.get("/defect-record-table", { params: { ...data } })
};

const updateDefectRecordTable = (id, data) => {
    return http.patch(`/defects-record/${id}`, data);
}

const getDefectChipList = async (data) => {
    return http.get('/defect-record-select1', { params: { ...data } })
}

const getDefectSummaryTable = async (data) => {
    return http.get('/defect-summary', { params: { ...data } })
}


export default {
    createDefectMaster,
    getDefectMasterTable,
    updateDefectMasterTable,
    createDefectClassification,
    getDefectRecordTable,
    updateDefectRecordTable,
    getDefectChipList,
    getDefectSummaryTable
}