import {
    Typography, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Divider
} from "@material-ui/core";
import React from "react";
import CommonGrid from "../../../../../components/CommonGrid";

export function ResultSummaryInfo({
    result_summary_info = {}

}) {


    const inputData = result_summary_info?.data_info[0];


    const parsedData = JSON.parse(inputData?.input_values);
    const operatorNames = parsedData[1].map(operator => operator["Operator name"]);

    const kappa_data = result_summary_info?.kappa_info;
    const efff_data = result_summary_info?.effectiveness_info;

    const rowData = kappa_data.within.map((value, index) => ({
        appriser: operatorNames[index],
        within: value,
        with_reference: kappa_data.with_reference[index]
    }));


    const rowData2 = efff_data.effectiveness.map((effectiveness, index) => ({
        appriser: operatorNames[index],
        effectiveness,
        miss_rate: efff_data.miss_rate[index],
        false_alarm_rate: efff_data.false_alarm_rate[index]
    }));


    const columns = [
        {
            field: "appriser",
            headerName: "Appraiser",

        },
        {
            field: "within",
            headerName: "Within",
            valueGetter: (params) => {
                const value = parseFloat(params?.data?.within * 1 ?? 0).toFixed(2);
                return `${value}`;
            }
        },
        {
            field: "with_reference",
            headerName: "With Reference",
            valueGetter: (params) => {
                const value = parseFloat(params?.data?.with_reference * 1 ?? 0).toFixed(2);
                return `${value}`;
            }
        },
    ];

    const columns2 = [
        {
            field: "appriser",
            headerName: "Appraiser",

        },
        {
            field: "effectiveness",
            headerName: "Effectiveness",
            valueGetter: (params) => {
                const value = parseFloat(params?.data?.effectiveness * 1 ?? 0).toFixed(1);
                return `${value}%`;
            }
        },
        {
            field: "miss_rate",
            headerName: "Miss Rate",
            valueGetter: (params) => {
                const value = parseFloat(params?.data?.miss_rate * 1 ?? 0).toFixed(1);
                return `${value}%`;
            }
        },
        {
            field: "false_alarm_rate",
            headerName: "False Alaram",
            valueGetter: (params) => {
                const value = parseFloat(params?.data?.false_alarm_rate * 1 ?? 0).toFixed(1);
                return `${value}%`;
            }
        },
    ];



    return (

        <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="stretch"
        >

            <Grid item xs={6}>
                <div style={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    height: "100%",
                }}>
                    <Typography variant="h4"

                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            //height: "100px",
                            backgroundColor: "#F8E6D8",
                            //  marginTop: "5px",
                            padding: "5px",
                        }}>
                        <strong>
                            Kappa
                        </strong>
                    </Typography>
                    <CommonGrid
                        rows={rowData}
                        columns={columns}
                        needExport={false}
                        fixedHeight

                    />

                </div>
            </Grid>

            <Grid item xs={6}>
                <div style={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    height: "100%",
                }}>
                    <Typography variant="h4"

                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#F8E6D8",
                            padding: "5px",
                        }}>
                        <strong>
                            Effectiveness
                        </strong>
                    </Typography>
                    <CommonGrid
                        rows={rowData2}
                        columns={columns2}
                        needExport={false}
                        fixedHeight
                    />
                </div>
            </Grid>







        </Grid>

    )

}