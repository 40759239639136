import {
    Box, Button, Grid, makeStyles, Typography,
    AppBar, Divider, Toolbar, Checkbox, FormControlLabel
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { array, object, string, number } from "yup";

import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import plantService from "../../../services/plantService"
import CommonGrid from "../../../components/CommonGrid";
import { PlantContext } from "../../../context/PlantContextProvider";
import HourChips from "../../../components/DGHourChips";

import errorLog from "../../../services/errorLog";
import productionMgmtService from "../../../services/productionMgmtService";
import productService from "../../../services/productService";
import { format, set } from 'date-fns';
import Chip from '@material-ui/core/Chip';
import DialPadComponentOEE from "../../../components/DialPadComponentOEE";
import DialPadComponent from "../../../components/DialPadComponent";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import qualityAnalyticsService from "../../../services/qualityAnalyticsService";
import oeeService from "../../../services/oeeService";
import { transparentize } from 'polished'
import { get } from "react-hook-form";
import { is } from "date-fns/locale";


export function AddProductionRecord({
    Footer = () => null,
    closeForm = () => { },
    componentProps = null

}) {
    const { v4: uuidv4 } = require("uuid");
    const { currentUser } = useContext(AuthContext);
    const { updateAlert } = useContext(AlertContext);
    const [gridApi, setGridApi] = useState(null);
    const [rows, setRows] = useState([])
    const { globalPlant } = useContext(PlantContext)
    const [fieldFlag, setFieldFlag] = useState(11)
    const [actionStatus, setActionStatus] = useState("")
    const [shiftFlag, setShiftFlag] = useState(0)
    const [shiftSelectFlag, setShiftSelectFlag] = useState(1)
    const [entryDate, setEntryDate] = useState(new Date())
    const [dailPad, setDailPad] = useState(false)




    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: fieldFlag === 5 ? 'column' : 'row',
            justifyContent: 'left',
            flexWrap: 'wrap',
            gap: '10px',
            padding: '5px',
            width: 'fit-content',

            '& > *': {
                fontSize: '15px',
                padding: "15px",
                justifyContent: 'left',
                width: 'fit-content',
            },
        },
    }));

    const chipclasses = useStyles()

    let form = useFormik({
        initialValues: {
            vsOptions: [],
            vsSelected: "",

            operatorOptions: [],
            operatorChipOptions: [],
            operator: null,

            machineOptions: [],
            machine: null,
            historyGrid: [],
            productionDate: new Date(),
            productionStartTime: "",
            productionEndTime: "",
            quantity: "",
            rejections: "",

            plant: null,
            plantOptions: [],

            partOptions: [],
            partChipOptions: [],
            part: null,

            toolOptions: [],
            tool: null,
            shots: "",

            chipOptionsList: [],


            shiftOptions: [],
            shiftStart: null,
            shiftSelected: null,

            partDayTarget: null,

            cumTime: 0,
            planCumTime: 0,
            cumQuantity: 0,

            oeeLossOptions: [],
            oeeChipOptions: [],

            oeeQuantity: "",



        },

        validationSchema: object({

            vsSelected: object().shape({ id: string().required('VS is required') }),
            machine: object().shape({ machine_id: string().required('Machine is required') }),
            operator: object().shape({ operator_id: string().required('Operator is required') }),
            productionStartTime: string().required(),
            productionEndTime: string().required(),
            part: object().shape({ id: string().required('Part is required') }),

            quantity: number().required("Quantity is required"),
            rejections: number().required("Rejections is required"),


        }),


        onSubmit: saveForm

    });


    let form2 = useFormik({

        initialValues: {

            oeeLossSelected: null,
            lossQuantity: '',
            plannedLoss: false

        }

    });


    function onGridReady(params) {
        setGridApi(params.api);

    }


    useEffect(() => {
        getVSTableList()
    }, [globalPlant]);


    useEffect(() => {
        if (form.values.shiftSelected) {
            setFieldFlag(3)
            setDailPad(false)
        }
    }, [form.values.shiftSelected]);


    useEffect(() => {
        if (form.values.productionStartTime) {
            setFieldFlag(4)
            setDailPad(false)
        }
    }, [form.values.productionStartTime]);


    useEffect(() => {
        if (form.values.productionEndTime) {
            setFieldFlag(0)
            setDailPad(false)
        }
    }, [form.values.productionEndTime]);


    useEffect(() => {
        if (form.values.vsSelected) {
            getMachineList()
            setFieldFlag(1)
            setDailPad(false)
        }

    }, [form.values.vsSelected]);

    useEffect(() => {
        if (form.values.machine) {
            getProductionDetails()

        }
    }, [form.values.machine]);

    useEffect(() => {
        if (Object.keys(form.values.chipOptionsList).length > 0) {
            setFieldFlag(2)
            getOperatorList()
        }
    }, [form.values.chipOptionsList]);


    useEffect(() => {
        if (form.values.operator) {
            getPartList()
            setFieldFlag(5)
        }
    }, [form.values.operator]);

    useEffect(() => {
        if (form.values.part) {
            getToolList((hasToolOptions) => {
                if (hasToolOptions) {
                    setFieldFlag(6);
                } else {
                    setFieldFlag(8);
                    setDailPad(true)
                }
            });
            getOEELossOptions()
            // getMasterDefects()
        }
    }, [form.values.part]);

    useEffect(() => {
        if (form.values.tool) {
            setFieldFlag(7);
            setDailPad(true)
        }
    }, [form.values.tool]);

    useEffect(() => {
        if (form.values.shots !== null && form.values.shots !== "") {
            setFieldFlag(8);
            setDailPad(true)
        }
    }, [form.values.shots]);

    useEffect(() => {
        if (form.values.rejections !== null && form.values.rejections !== "") {
            setFieldFlag(10);
        }
    }, [form.values.rejections]);

    useEffect(() => {
        if (form.values.quantity !== null && form.values.quantity !== "") {
            setFieldFlag(9);
            getPartDayTarget()
        }
    }, [form.values.quantity])





    const columns = [

        {
            cellRenderer: DeleteRenderer,
            minWidth: 80,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
            pinned: "left",
            resizable: true,
        },

        {
            field: "loss_type",
            headerName: "Category",
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 150,

        },

        {
            field: "loss_description",
            headerName: " Description",
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 150,

        },

        {
            field: "units",
            headerName: "Units",
            flex: 1,
            editable: true
        },

        {
            field: "loss_time",
            headerName: " Time/Pieces",
            flex: 1,
            editable: true

        },

        {
            field: "plannedLoss",
            headerName: "Planned",
            flex: 1,
            cellRenderer: (params) => {
                return params.value ? "✔️" : "";
            },
        }

    ];

    function removeDetailRow(rowIndex, data) {
        const updatedGridData = [...rows];
        updatedGridData.splice(rowIndex, 1);
        setRows(updatedGridData);
        gridApi?.updateRowData({ remove: [data] });

        if (data.loss_type_id === 15003) {
            form.setFieldValue("cumQuantity", form.values.cumQuantity - data.loss_time);
        } else {
            form.setFieldValue("cumTime", form.values.cumTime - data.loss_time)
        }
    }

    function getVSTableList() {
        productionMgmtService.getVSTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        }
        ).then((response) => {
            form.setFieldValue("vsOptions", response.data)
        }).catch((error) => {
            console.log(error)
        })

        productionMgmtService.getShiftDetailsPlant({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id,
            break_flag: 0
        })
            .then((res) => {
                const temp = res?.data ?? []
                form.setFieldValue("shiftOptions", temp);

                setFieldFlag(11);
                if (temp.length === 1 || temp.length === 0) {
                    setShiftSelectFlag(0)
                    setShiftFlag(1)
                    form.setFieldValue("shiftSelected", temp[0]);
                }
            });
    }

    function getMachineList() {
        productionMgmtService.getVSMachineTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        })
            .then((res) => {
                const temp1 = res?.data ?? []
                const filtList = temp1.filter(list =>
                    list.enabled === 1 &&
                    list.production_valueStream_id === form.values.vsSelected.id

                );
                form.setFieldValue("machineOptions", filtList);
            });
    }

    function getPartList() {
        const temp5 = form.values.chipOptionsList?.part_id ?? []
        productService
            .getProductTableByClient(
                globalPlant?.globalPlant?.id,
                currentUser.client_id
            )
            .then((res) => {
                const temp1 = res?.data ?? []
                const filtList = temp1.filter(list => list.enabled === 1);
                form.setFieldValue("partOptions", filtList ?? []);

                if (temp5.length === 0) {
                    form.setFieldValue("partChipOptions", filtList);
                    return;
                }
                const filteredList = filtList.filter(item => temp5.includes(item.id));
                form.setFieldValue("partChipOptions", filteredList);
            });
    }

    function getOperatorList() {
        const temp5 = form.values.chipOptionsList?.operator_id ?? []
        productionMgmtService.getVSOperatorTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id

        })
            .then((res) => {
                const temp1 = res?.data ?? []
                const filtList = temp1.filter(list =>
                    list.enabled === 1 &&
                    list.production_valueStream_id === form.values.vsSelected.id
                );
                form.setFieldValue("operatorOptions", filtList);

                if (temp5.length === 0) {
                    form.setFieldValue("operatorChipOptions", filtList);
                    return;
                }
                const filteredList = filtList.filter(item => temp5.includes(item.operator_id));
                form.setFieldValue("operatorChipOptions", filteredList);
            })
    }

    function getToolList(callback) {
        productionMgmtService.getToolListforRecord({
            part_id: form.values.part?.id
        })
            .then((res) => {
                const temp = res?.data ?? [];
                form.setFieldValue("toolOptions", temp);

                if (callback) {
                    callback(temp.length > 0);
                }
            });
    }



    function handleMeasuredValue(value) {
        switch (fieldFlag) {
            case 8:
                form.setFieldValue("quantity", value);
                break;
            case 9:
                form.setFieldValue("rejections", value);
                break;
            case 7:
                form.setFieldValue("shots", value);
                break;

            case 10:
                onAdd(value)
                break;
            default:
                console.warn("Unhandled fieldFlag:", fieldFlag);
        }
    }






    function getProductionDetails() {
        productionMgmtService.getProductionHistory({
            machine_id: form.values.machine?.machine_id
        })
            .then((res) => {
                const temp = res?.data ?? []
                form.setFieldValue("chipOptionsList", temp);
            });
    }


    const labels = [
        "Select Value Stream", //0
        "Select Machine", //1
        "Select Operator", //2
        "Select Production Start Time", //3
        "Select Production End Time", //4
        "Select Part", //5
        "Select Tool", //6
        "Enter Shots", //7
        "Enter Production Quantity", //8
        "Enter Defect Quantity", //9
        "Enter Loss Details", //10
        "Select Shift",  //11


    ];

    function saveDefects(tempID) {
        if (rows.length > 0) {
            let payloadD = []
            rows.forEach(exp => {
                if (exp.loss_type_id === 15003) {
                    payloadD.push({
                        id: exp.id,
                        plant_id: globalPlant?.globalPlant?.id,
                        client_id: currentUser?.client_id,
                        part_id: form.values.part?.id,
                        production_record_id: tempID,
                        defect_master_id: exp.loss_id,
                        production_quantity: null,
                        defect_quantity: parseInt(exp.loss_time),
                        measurement_type: null,
                        basic_eval_id: null,
                        comments: null,
                        ps_project_id: null,
                        machine_id: form.values.machine?.machine_id,
                        machine_spindle_id: null,
                        production_date: format(new Date(form.values.productionDate), 'yyyy/MM/dd'),
                        production_start_time: null,
                        production_end_time: null,
                        defect_incident_id: null,
                        operator_id: form.values.operator.operator_id,
                        enabled: 1
                    })
                }
            })

            qualityAnalyticsService.createDefectClassification(payloadD)
                .then((res) => {
                    updateAlert({
                        open: true,
                        message: "Defects recorded Successfully",
                        type: "success",
                    });
                    closeForm();
                })
                .catch((error) => {
                    updateAlert({
                        open: true,
                        message: "Failed to record defects",
                        type: "error",
                    });
                    let payload = {
                        id: uuidv4(),
                        error_description: error.message,
                        error_location: "createDefectClassification",
                        client_id: currentUser.client_id,
                        user_id: currentUser.id,
                        entity_id: null,
                    };
                    errorLog.createErrorLog(payload)

                });
        }
    }


    function saveLoss(tempID) {
        if (rows.length > 0) {
            let payload = []
            rows.forEach(exp => {
                if (exp.loss_type_id !== 15003) {
                    payload.push({
                        plant_id: globalPlant?.globalPlant?.id,
                        client_id: currentUser?.client_id,
                        part_id: form.values.part?.id,
                        machine_id: form.values.machine?.machine_id,
                        production_record_id: tempID,
                        loss_type_id: exp.loss_type_id,
                        loss_id: exp.loss_id,
                        is_planned: exp.plannedLoss,
                        loss_duration: parseInt(exp.loss_time),
                        enabled: 1,
                        ct_id: form.values.partDayTarget?.ct_id
                    })
                }
            })

            oeeService.createLossData(payload)
                .then((res) => {
                    updateAlert({
                        open: true,
                        message: "Loss recorded Successfully",
                        type: "success",
                    });
                    closeForm();
                })
                .catch((error) => {
                    updateAlert({
                        open: true,
                        message: "Failed to record Losses",
                        type: "error",
                    });
                    let payload = {
                        id: uuidv4(),
                        error_description: error.message,
                        error_location: "createLossData",
                        client_id: currentUser.client_id,
                        user_id: currentUser.id,
                        entity_id: null,
                    };
                    errorLog.createErrorLog(payload)

                });
        }
    }


    function saveForm() {
        const tempID = uuidv4()
        let payload = {
            id: tempID,
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser?.client_id,
            part_id: form.values.part?.id,
            form_tool_id: form.values.tool?.tool_id ?? null,
            machine_id: form.values.machine?.machine_id,
            production_quantity: form.values.quantity,
            rejections: form.values.rejections,
            tool_shots: form.values?.shots || 0,
            production_date: format(new Date(form.values.productionDate), 'yyyy/MM/dd'),
            production_start_time: `${form.values.productionStartTime}:00`,
            production_end_time: `${form.values.productionEndTime}:00`,
            operator_id: form.values.operator.operator_id,
            created_by: currentUser?.id,
            enabled: 1,
            record_counter: componentProps.maxID + 1,
            total_loss_time: form.values.partDayTarget?.time_loss_min ?? null,


        }

        productionMgmtService.createProductionRecord(payload)
            .then((res) => {
                if (actionStatus === "close") {
                    saveDefects(tempID)
                    saveLoss(tempID)
                    closeForm()
                    updateAlert({
                        open: true,
                        message: "Production record added successfully.",
                        type: "success",
                    });
                } else {

                    form.resetForm()
                    setFieldFlag(0)
                    getVSTableList()
                    form.setFieldValue("productionDate", entryDate)
                    updateAlert({
                        open: true,
                        message: "Production record added successfully.",
                        type: "success",
                    });
                }
            })
            .catch((err) => {
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "createProductionRecord",
                    client_id: currentUser.client_id,
                    user_id: currentUser.id,
                    entity_id: "",
                };
                errorLog.createErrorLog(payload)
                updateAlert({
                    open: true,
                    message: "Failed to create Production record.",
                    type: "error",
                });
            });

    }


    function getOEELossOptions() {
        const masterLoss = []

        qualityAnalyticsService.getDefectMasterTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id
        })
            .then((res) => {
                const data1 = res?.data ?? [];
                const data = data1.map(item => ({
                    ...item,
                    loss_type_id: 15003,
                    loss_type: "Quality"
                }));
                masterLoss.push(...data.filter(list => list.enabled === 1));

            });

        oeeService.getOEELossList(currentUser.client_id)
            .then((res) => {
                masterLoss.push(...res.data)
            });

        form.setFieldValue("oeeLossOptions", masterLoss ?? []);
        form.setFieldValue("oeeChipOptions", masterLoss ?? []);

    }


    function onAdd(val) {
        const value = parseInt(val)
        form2.setFieldValue("lossQuantity", value);

        const data = [
            {
                id: uuidv4(),
                loss_description: form2.values.oeeLossSelected?.loss_description,
                loss_time: value,
                loss_id: form2.values.oeeLossSelected?.loss_id,
                loss_type: form2.values.oeeLossSelected?.loss_type,
                loss_type_id: form2.values.oeeLossSelected?.loss_type_id,
                plannedLoss: form2.values.plannedLoss,
                units: form2.values.oeeLossSelected.loss_type_id === 15003
                    ? "Quantity"
                    : "Minutes",
            }
        ];


        if (form2.values.oeeLossSelected.loss_type_id === 15003) {
            if ((form.values.cumQuantity + value) > form.values.rejections) {
                updateAlert({
                    open: true,
                    message: "This defect quantity cannot exceed Total defects",
                    type: "error",
                });
                return;
            } else {
                form.setFieldValue("cumQuantity", form.values.cumQuantity + value);
            }

        } else {
            if ((form.values.cumTime + value) > form.values?.partDayTarget?.time_loss_min) {
                updateAlert({
                    open: true,
                    message: "Loss time cannot exceed total loss time",
                    type: "error",
                });
                return;
            } else {
                form.setFieldValue("cumTime", form.values.cumTime + value);
                if (form2.values.oeeLossSelected.loss_type_id === 15002) {
                    const temp = form.values.partDayTarget?.total_time - (form.values.planCumTime * 60) - (value * 60)
                    form.setFieldValue("planCumTime", form.values.planCumTime + value)
                    // console.log("temp", temp, form.values.partDayTarget?.total_time, form.values.planCumTime, value)
                    const temp1 = Math.floor(temp / form.values.partDayTarget?.cycle_time)
                    form.setFieldValue("oeeQuantity", temp1)
                }


            }
        }

        setRows([...rows, ...data]);

        form2.resetForm()




    }


    function getdefectChips() {
        qualityAnalyticsService.getDefectChipList({
            part_id: form.values.part?.id,
            machine_id: form.values.machine?.machine_id,
        })
            .then((res) => {
                const temp = res?.data ?? []
                form.setFieldValue("defectChipMasterOptions", temp);
            });
    }


    function getPartDayTarget() {
        oeeService.getPartDayTarget({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id,
            part_id: form.values.part?.id,
            start_time: `${form.values.productionStartTime}:00`,
            end_time: `${form.values.productionEndTime}:00`,
            machine_id: form.values.machine?.machine_id,
            parts_produced: form.values.quantity,
        })
            .then((res) => {
                const temp = res?.data ?? []
                form.setFieldValue("partDayTarget", temp);
                form.setFieldValue("oeeQuantity", temp.target_parts)
            });
    }




    return (

        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>

                    {/* Production Date and time */}
                    <Grid container xs={12} direction="row" spacing={2}
                        style={{ "margin-bottom": "3px" }}>
                        <Grid item xs={3}>
                            <DGDateInput
                                label="Production Date"
                                id="productionDate"
                                defaultValue={new Date()}
                                format="dd/MM/yyyy"
                                onChange={(date) => {
                                    form.setFieldValue("productionDate", date);
                                    setEntryDate(date)
                                }}
                                value={form.values.productionDate}
                                required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DGInput
                                label=" Start Time"
                                value={form.values.productionStartTime}
                                onChange={(value) =>
                                    form.setFieldValue("productionStartTime", value)
                                }
                                type="time"
                                error={form.errors.productionStartTime && form.touched.productionStartTime}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DGInput
                                label=" End Time"
                                value={form.values.productionEndTime}
                                onChange={(value) =>
                                    form.setFieldValue("productionEndTime", value)
                                }
                                type="time"
                                error={form.errors.productionEndTime && form.touched.productionEndTime}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <DGSelect
                                id="vs"
                                label="Value Stream"
                                options={form.values.vsOptions}
                                getOptionLabel={(option) => option.valueStream_name}
                                value={form.values.vsSelected}
                                onChange={(value) => {
                                    form.setFieldValue("vsSelected", value);
                                }}
                                required
                                error={form.errors.vsSelected && form.touched.vsSelected}

                            />
                        </Grid>
                    </Grid>

                    {/*  Machine and Part */}
                    <Grid container xs={12} direction="row" spacing={2}
                        style={{ "margin-bottom": "3px" }}>


                        <Grid item xs={4}>
                            <DGSelect
                                id="machine"
                                label="Machine"
                                options={form.values.machineOptions}
                                getOptionLabel={(option) => `${option.machine_number ?? ""} ${option.machine_name ?? ""}`}
                                value={form.values.machine}
                                error={form.errors.machine && form.touched.machine}
                                onChange={(val) => {
                                    form.setFieldValue("machine", val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <DGSelect
                                id="operator"
                                label="Operator"
                                options={form.values.operatorOptions}
                                getOptionLabel={(option) => option.operator_list_id ? `${option.operator_list_name} ${option.operator_list_id}` : `${option.operator_list_name}`}
                                value={form.values.operator}
                                error={form.errors.operator && form.touched.operator}
                                onChange={(val) => {
                                    form.setFieldValue("operator", val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <DGSelect
                                id="part"
                                label="Part"
                                options={form.values.partOptions}
                                getOptionLabel={(option) => `${option.part_number} ${option.part_name}`}
                                value={form.values.part}
                                onChange={(val) => {
                                    form.setFieldValue("part", val);
                                }}

                                required
                                error={form.errors.part && form.touched.part}
                            />
                        </Grid>
                    </Grid>

                    {/*Production Quantity and Rejections*/}
                    <Grid container xs={12} direction="row" spacing={2}
                        style={{ "margin-bottom": "3px" }}>


                        <Grid item xs={5}>
                            <DGSelect
                                id="formtool"
                                label="Form Tool"
                                options={form.values.toolOptions}
                                getOptionLabel={(option) => `${option.tool_name} ${option.tool_number}`}
                                value={form.values.tool}
                                error={form.errors.tool && form.touched.tool}
                                disabled={form.values.toolOptions.length === 0}
                                onChange={(val) => {
                                    form.setFieldValue("tool", val);

                                }}
                            />
                        </Grid>


                        <Grid item xs={2}>
                            <DGInput
                                id="shots"
                                isFormik
                                label="Shots"
                                onChange={form.handleChange}
                                value={form.values.shots}
                                error={form.errors.shots && form.touched.shots}
                                required
                                disabled={form.values.toolOptions.length === 0}
                            />
                        </Grid>



                        <Grid item xs={3}>
                            <DGInput
                                id="quantity"
                                isFormik
                                label="Quantity"
                                onChange={form.handleChange}
                                value={form.values.quantity}
                                required
                                error={form.errors.quantity && form.touched.quantity}
                            />
                        </Grid>


                        <Grid item xs={2}>
                            <DGInput
                                id="rejections"
                                isFormik
                                label="Rejections"
                                onChange={form.handleChange}
                                value={form.values.rejections}
                                required
                                error={form.errors.rejections && form.touched.rejections}
                            />
                        </Grid>

                    </Grid>

                    {/* OEE */}
                    <Grid container xs={12} direction="row" spacing={2}
                        style={{ "margin-bottom": "3px" }}>



                        <Grid item xs={3} >
                            <DGInput
                                id="target_production"
                                label="Target Quantity"
                                value={form.values?.oeeQuantity ?? ""}
                            />
                        </Grid>


                        <Grid item xs={3} >
                            <DGInput
                                id="loss"
                                label="Loss In minuted"
                                value={form.values?.partDayTarget?.time_loss_min ?? ""}
                            />
                        </Grid>

                        <Grid item xs={3} >
                            <DGInput
                                id="loss"
                                label="Loss Un-classified"
                                value={form.values?.partDayTarget?.time_loss_min - form.values.cumTime}
                            />
                        </Grid>

                        <Grid item xs={3} >
                            <DGInput
                                id="loss"
                                label="Defects Un-classified"
                                value={form.values.rejections - form.values.cumQuantity}
                            />
                        </Grid>

                    </Grid>

                    {/* Loss description */}
                    <Grid container xs={12} direction="row" spacing={2}>

                        <Grid item xs={3} >

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={form2.values.plannedLoss}
                                            onChange={(e) => {
                                                form2.setFieldValue("plannedLoss", e.target.checked);
                                            }}
                                            name="plannedLoss"
                                            color="primary"
                                        />
                                    }
                                    label="Planned"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                    }}
                                />
                            </div>

                        </Grid>

                        <Grid item xs={7} >
                            <DGSelect
                                id="loss_description"
                                label="Loss Description"
                                value={form2.values.oeeLossSelected}
                                options={form.values.oeeLossOptions}
                                getOptionLabel={(option) =>
                                    option.loss_type + " - " + option.loss_description}
                                onChange={(val) => {
                                    form2.setFieldValue("oeeLossSelected", val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <DGInput
                                id="IncidentQuantity"
                                isFormik
                                label="Loss"
                                onChange={form2.handleChange}
                                value={form2.values.lossQuantity}

                            />
                        </Grid>



                        {/*   <Grid item xs={2} >
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                style={{
                                    marginTop: '5px',
                                    width: '100%',
                                    backgroundColor: '#1F618D'
                                }}
                                onClick={onAdd}
                            >
                                Add
                            </Button>
                        </Grid> */}
                    </Grid>

                    {/* Grid */}
                    <Grid container xs={12} direction="row" spacing={2}
                        style={{ "margin-bottom": "3px" }}>

                        <Grid item md={12} sm={12} lg={12}>
                            <CommonGrid
                                rows={rows}
                                columns={columns}
                                needExport={false}
                                gridheight={400}
                                onGridReady={onGridReady}
                            />
                        </Grid>

                    </Grid >

                </Grid>

                <Grid item xs={4}>
                    <Box
                        sx={{
                            border: '1px solid grey',
                            width: '98%',
                            padding: '5px',
                            marginTop: '5px',
                            height: '95%',
                            marginRight: '2%',
                            boxSizing: 'border-box',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            height: "290px",
                        }}
                    >

                        <div style={{
                            marginBottom: '3px',
                            position: 'sticky',
                            top: '0',
                            zIndex: '100',
                            backgroundColor: 'white',
                            padding: '5px'
                        }}>

                            <Typography variant="body2"
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                    textAlign: "center",
                                }}>

                                {labels[fieldFlag] ?? null}

                            </Typography>

                        </div>

                        <div className={chipclasses.root}>
                            {fieldFlag === 0 ? (
                                form.values.vsOptions.map((item) => (
                                    <Chip
                                        key={item.valueStream_name}
                                        label={item.valueStream_name}
                                        variant="outlined"
                                        onClick={() => form.setFieldValue("vsSelected", item)}
                                    />
                                ))

                            ) : fieldFlag === 1 ? (
                                form.values.machineOptions.map((item) => (
                                    <Chip
                                        key={item.machine_number}
                                        label={item.machine_number + " - " + item.machine_name}
                                        variant="outlined"
                                        onClick={() => form.setFieldValue("machine", item)}
                                    />
                                ))
                            ) : fieldFlag === 2 ? (
                                form.values.operatorChipOptions.map((item) => (
                                    <Chip
                                        key={item.operator_list_id}
                                        label={item.operator_list_name + " - " + item.operator_list_id}
                                        variant="outlined"
                                        onClick={() => form.setFieldValue("operator", item)}
                                    />
                                ))
                            ) : fieldFlag === 3 ? (
                                <HourChips
                                    onTimeSelect={(time) => { form.setFieldValue('productionStartTime', time) }}
                                    shiftStart={parseInt(form.values?.shiftSelected?.shift_start_time)}
                                    shiftName={form.values?.shiftSelected?.shift_name}
                                    shiftEnd={parseInt(form.values?.shiftSelected?.shift_end_time)}
                                    firstShift={parseInt(form.values?.shiftOptions[0]?.shift_start_time)}
                                />
                            ) : fieldFlag === 4 ? (
                                <HourChips
                                    onTimeSelect={(time) => { form.setFieldValue('productionEndTime', time) }}
                                    blockfrom={parseInt(form.values.productionStartTime)}
                                    shiftStart={parseInt(form.values?.shiftSelected?.shift_start_time)}
                                    shiftName={form.values?.shiftSelected?.shift_name}
                                    shiftEnd={parseInt(form.values?.shiftSelected?.shift_end_time)}
                                    firstShift={parseInt(form.values?.shiftOptions[0]?.shift_start_time)}
                                />
                            ) : fieldFlag === 5 ? (
                                form.values.partChipOptions.map((item) => (
                                    <Chip
                                        key={item.part_id}
                                        label={item.part_number + " - " + item.part_name}
                                        variant="outlined"
                                        onClick={() => form.setFieldValue("part", item)}
                                    />
                                ))
                            ) : fieldFlag === 6 ? (
                                form.values.toolOptions.map((item) => (
                                    <Chip
                                        key={item.tool_id}
                                        label={item.tool_number + " - " + item.tool_name}
                                        variant="outlined"
                                        onClick={() => form.setFieldValue("tool", item)}
                                    />
                                ))
                                //  ) : fieldFlag === 7 || fieldFlag === 8 || fieldFlag === 9 ? (
                            ) : fieldFlag === 10 ? (
                                <>


                                    <Chip
                                        label="Planned"
                                        onClick={() => form2.setFieldValue("plannedLoss", !form2.values.plannedLoss)}
                                        style={{
                                            backgroundColor: form2.values.plannedLoss
                                                ? transparentize(.2, '#BA4A00')
                                                : transparentize(0.01, '#BA4A00'),
                                            color: 'white',
                                        }}
                                    />


                                    {form.values.oeeChipOptions.map((item) => (
                                        <Chip
                                            key={item.id}
                                            label={item.loss_description}
                                            variant="outlined"
                                            onClick={() => form2.setFieldValue("oeeLossSelected", item)}
                                            style={{
                                                backgroundColor:
                                                    item.loss_type_id === 15001 ? '#AED6F1' :
                                                        item.loss_type_id === 15002 ? '#FCF3CF' :
                                                            item.loss_type_id === 15003 ? '#FDEDEC' :
                                                                'transparent',  // Default color if no match

                                            }}
                                        />

                                    ))}
                                </>
                            ) : fieldFlag === 11 ? (
                                form.values.shiftOptions.map((item) => (
                                    <Chip
                                        key={item.shift_name}
                                        label={`${item.shift_name} Shift - ${item.shift_start_time}`}
                                        color={form.values.shiftSelected?.shift_name === item.shift_name ? "primary" : "default"}
                                        variant={form.values.shiftSelected?.shift_name === item.shift_name ? "default" : "outlined"}
                                        onClick={() => {
                                            form.setFieldValue("shiftSelected", item);
                                            form.setFieldValue("shiftStart", item.shift_start_time);
                                            setShiftFlag(1);
                                        }}
                                    />
                                ))
                            ) : (
                                null
                            )
                            }
                        </div>
                    </Box>
                    <Box
                        sx={{
                            border: '1px solid grey',
                            width: '98%',
                            padding: '5px',
                            marginTop: '5px',
                            height: '95%',
                            marginRight: '2%',
                            boxSizing: 'border-box',
                            height: "245px",
                        }}
                    >
                        <div style={{
                            transform: 'scale(.97)',
                            transformOrigin: 'top',
                            display: 'inline-block'
                        }}>
                            <DialPadComponentOEE
                                measuredValue={handleMeasuredValue}
                                dailPadActive={dailPad}
                            />
                        </div>
                    </Box>
                </Grid>


            </Grid >

            <AppBar position="fixed" style={{ width: 1200, top: "auto", bottom: 0 }}>
                <Toolbar
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >

                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        style={{
                            width: '25%',
                        }}
                        onClick={() => {
                            setActionStatus("close");
                            form.handleSubmit();
                        }}


                    >
                        Record
                    </Button>

                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        style={{
                            width: '25%',
                        }}
                        onClick={() => {
                            setActionStatus("restart");
                            form.handleSubmit();
                        }}
                    >
                        Save & Record Another
                    </Button>

                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        style={{
                            width: '25%',
                        }}
                        onClick={() => {
                            form.resetForm()
                            form2.resetForm()
                            setFieldFlag(11)
                            setShiftFlag(0)
                            getVSTableList()
                            setRows([])
                            setShiftSelectFlag(1)
                        }}

                    >
                        Reset
                    </Button>

                </Toolbar>
            </AppBar>

        </>

    );

}
export default AddProductionRecord;