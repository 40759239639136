import {
    Typography, Grid
} from "@material-ui/core";
import React from "react";

export function StaticHeader({
    reportData = {}

}) {

    return (

        <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
        >

            <Grid item xs={3} >
                <div>
                    <img
                        src={reportData?.company_info[0]?.logo}
                        style={{ height: "20mm", objectFit: "contain" }}
                        className="p-1"
                    />
                </div>
            </Grid>
            <Grid item xs={6} >
                <div>
                    <Typography variant="h4" align="center">
                        {reportData?.company_info[0]?.business_name} - {reportData?.company_info[0]?.plant_name} <br />
                        <strong>{reportData?.study_info[0]?.study}</strong>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={3} >
                <div>
                    <Typography variant="h4" align="center">
                        {reportData?.study_info[0]?.report_number}
                    </Typography>

                </div>
            </Grid>
        </Grid>

    )

}