import { AppBar, Button, Divider, Grid, Toolbar } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { DGSelect } from "../../../../../components/shared/DGSelect";
import { DGDateInput } from "../../../../../components/shared/DGDateInput";
import initiateStudyService from "../../../../../services/initiateStudyService";
import { useFormik } from "formik";
import productService from "../../../../../services/productService";
import { AuthContext } from "../../../../../context/AuthContextProvider";
import characterService from "../../../../../services/characterService";
import gaugeService from "../../../../../services/gaugeService";
import { array, date, object, string } from "yup";

export function StudySelection({
  selectedStudy = null,
  setSelectedStudy = () => { },
  setSelectedGauge = () => { },
  setSelectedGaugeItem = () => { },
  closeForm = () => { },
  handleNext = () => { },
  setcharacterId = () => { },
  setPartId = () => { },
  setEvaluationDate = () => { }
}) {
  const form = useFormik({
    initialValues: {
      product: { part_name: "", part_number: "" },
      character: null,
      gauge: null,
      gauge_item: null,
      study_id: null,
      productOptions: [],
      characterOptions: [],
      gaugeOptions: [],
      gaugeItemOptions: [],
      studyOptions: [],
      evaluationDate: new Date()
    },
    validationSchema: object({
      selectedStudy: string().required('Study is not required'),
    })
  });

  const { currentUser } = useContext(AuthContext);


  useEffect(() => {
    productService.getProducts(currentUser.client_id).then((res) => {
      form.setFieldValue("productOptions", res?.data?.data ?? []);
    });

    setEvaluationDate(form.values.evaluationDate);
  }, []);



  function loadCharacters(val) {
    characterService.getCharactersByProductId(val?.id).then((res) => {
      const temp1 = res?.data?.data ?? [];
      const temp2 = temp1.filter((item) => item.single_side_flag === 0);
      form.setFieldValue("characterOptions", temp2 ?? []);
    });
  }


  function loadGauges(val) {
    gaugeService.getGaugeCharacterById(val?.id).then((res) => {
      form.setFieldValue("gaugeOptions", res?.data?.data ?? []);
    });
  }

  function loadGaugeItem(val) {
    gaugeService.getActiveGaugeItemsByGaugeId(val?.gauge_id).then((res) => {
      form.setFieldValue("gaugeItemOptions", res?.data?.data ?? []);
    });
  }

  function loadStudies(val) {
    initiateStudyService
      .getMSAtestList({ id: val?.id, filter_name: "msa_study" })
      .then((res) => {
        form.setFieldValue("studyOptions", res?.data ?? []);
      });
    loadCharacters(val);
  }


  return (
    <Grid container spacing={4}>
      <Grid item md={4} sm={4} lg={4}>
        <DGSelect
          label="Part Number"
          options={form.values.productOptions}
          value={form.values.product}
          getOptionLabel={(opt) => `${opt?.part_number} ${opt?.part_name}`}
          onChange={(val) => {
            form.setFieldValue("product", val);
            loadCharacters(val);
            loadStudies(val);
            setPartId(val?.id);
          }}
        />
      </Grid>
      <Grid item md={5} sm={5} lg={5}>
        <DGSelect
          label="Characterstics"
          options={form.values.characterOptions}
          value={form.values.character}
          disabled={form.values.characterOptions.length === 0}
          getOptionLabel={(params) =>
            `${params?.name} ${params?.specification ?? ""} ${params?.upper_specification ?? ""} ${params?.lower_specification ?? ""}`
          }
          onChange={(val) => {
            form.setFieldValue("character", val);
            loadGauges(val);
            setcharacterId(val?.id);
          }}
        />
      </Grid>



      <Grid item md={3} sm={3} lg={3}>
        <DGDateInput
          label="Evaluation Date"
          id="evaluationDate"
          format="dd/MM/yyyy"
          onChange={(date) => {
            form.setFieldValue("evaluationDate", date);
            setEvaluationDate(date)
          }}
          value={form.values.evaluationDate}
          required
          error={
            form.touched.evaluationDate && Boolean(form.errors.evaluationDate)
          }

        />
      </Grid>

      <Grid item md={5} sm={5} lg={5}>
        <DGSelect
          options={form.values.gaugeOptions}
          label="Gauge Family"
          value={form.values.gauge}
          getOptionLabel={(opt) => `${opt?.gauge?.number}-${opt?.gauge?.name}`}
          disabled={form.values.gaugeOptions.length === 0}
          onChange={(val) => {
            form.setFieldValue("gauge", val);
            setSelectedGauge(val);
            loadGaugeItem(val)
          }}


        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGSelect
          label="Gauge Number"
          options={form.values.gaugeItemOptions}
          value={form.values.gauge_item}
          getOptionLabel={(opt) => `${opt?.serial_number}`}
          disabled={form.values.gaugeItemOptions.length === 0}
          onChange={(val) => {
            form.setFieldValue("gauge_item", val);
            setSelectedGaugeItem(val);
          }}

        />
      </Grid>
      <Grid item md={3} sm={3} lg={3}>
        <DGSelect
          options={form.values.studyOptions}
          label="Study"
          //onChange={setSelectedStudy}
          value={selectedStudy}
          getOptionLabel={(opt) => opt?.studies_name}
          disabled={form.values.gauge === null}
          onChange={(val) => {
            form.setFieldValue("study_id", val.studies_id);
            setSelectedStudy(val);
          }}
        />
      </Grid>
      <AppBar position="fixed" style={{ width: 1150, top: "auto", bottom: 0 }}>
        <Divider />
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            style={{ margin: 4 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => closeForm()}
          >
            CANCEL
          </Button>
          {form.values.study_id !== null ? (
            <Button
              style={{ margin: 4 }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleNext()}
            >
              NEXT
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>
    </Grid>
  );
}
