import {
    Typography, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Divider
} from "@material-ui/core";
import React from "react";
import { format } from "date-fns";

export function StaticPart({
    reportData = {}

}) {


    return (

        <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            spacing={2}
            alignItems="stretch"
        >

            <Grid item xs={4}>
                <div style={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    height: "100%",
                }}>
                    <Typography variant="h4"

                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            //height: "100px",
                            backgroundColor: "#F8E6D8",
                            //  marginTop: "5px",
                            padding: "5px",
                        }}>
                        <strong>
                            Part Information
                        </strong>
                    </Typography>
                    <List>
                        <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                            <ListItemText primary={"Part"} />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={reportData?.product_info[0]?.Part}
                                    primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                            <ListItemText primary={"Character"} />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={reportData?.product_info[0]?.Character}
                                    primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                            <ListItemText primary={"Specification"} />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={reportData?.product_info[0]?.Specification}
                                    primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </div>
            </Grid>

            <Grid item xs={4}>
                <div style={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    height: "100%",
                }}>
                    <Typography variant="h4"

                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#F8E6D8",
                            padding: "5px",
                        }}>
                        <strong>
                            Gauge Information
                        </strong>
                    </Typography>
                    <List>
                        <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                            <ListItemText primary={"Gauge"} />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={reportData?.gauge_info[0]?.["Gauge Family"]}
                                    primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                            <ListItemText primary={"Gauge #"} />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={reportData?.product_info[0]?.["Gauge Number"]}
                                    primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                            <ListItemText primary={"Least Count"} />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={reportData?.product_info[0]?.["Least Count"]}
                                    primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </div>
            </Grid>

            <Grid item xs={4}>
                <div style={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    height: "100%",
                }}>
                    <Typography variant="h4"

                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#F8E6D8",
                            padding: "5px",
                        }}>
                        <strong>
                            Evaluation Information
                        </strong>
                    </Typography>
                    <List>
                        <ListItem style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                            <ListItemText primary={"Study Date"} />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={format(new Date(reportData?.test_info[0]?.["'Study Date'"]), 'dd/MM/yyyy')}
                                    primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemText primary={"Comments"} />
                            <ListItemSecondaryAction>
                                <ListItemText
                                    primary={reportData?.test_info[0]?.["Comments"]}
                                    primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                    </List>
                </div>
            </Grid>






        </Grid>

    )

}