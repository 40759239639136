import { Box, Button, Typography, Grid } from "@material-ui/core";
import React from "react";
import { useRef, useContext, useState, useEffect } from "react";
import { DataInfo } from "./DataInfo";
import { ResultInfo } from "./ResultInfo";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { DrawingInfo } from "./DrawingInfo";
import { Evaluationfo } from "./EvaluationInfo";
import reportService from "../../../../../services/reportService";
import { AuthContext } from "../../../../../context/AuthContextProvider";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { StatusContext } from "../../../../../context/StatusContextProvider";
import Loader from "../../../../../components/Loader";
import { AlertContext } from "../../../../../context/AlertContextProvider";
import approvalService from "../../../../../services/approvalService";
import initiateStudyService from "../../../../../services/initiateStudyService";
import { StaticHeader } from "../genralItems/staticHeader";
import { StaticApproval } from "../genralItems/staticApproval";
import { StaticPart } from "../genralItems/staticPart";
import { ResultSummaryInfo } from "./ResultSummaryInfo";

export function KappaReport({
  study_id = "",
  disabledDownload = false,
} = {}) {
  const componentRef = useRef();

  let { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const [reportData, setReportData] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false);
  const [approveFlag, setApproveFlag] = useState(0)
  const { setStatus } = useContext(StatusContext);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: () => reloadGridData(),
      callback: determineApproveFlag(),
    }));
    reloadGridData()
  }, []);



  function reloadGridData() {
    initiateStudyService.MSAReport(study_id, 12)
      .then((res) => {
        setReportData(res?.data);
        determineApproveFlag(res?.data)
        setDataLoaded(true)

      });
  }

  if (!dataLoaded) {
    return <Loader />;
  }


  function ApproveORReject(statusId) {
    let payload = {
      approval_id: reportData?.approval_info.approve_id,
      user_id: currentUser?.id,
      status_id: statusId,
    };
    approvalService.createApprovalDecision(payload)
      .then((res) => {
        let message;
        if (statusId === 2) {
          message = "Request Approved";
        } else { message = "Request Rejected" }
        updateAlert({
          open: true,
          type: "success",
          message: message
        })
      })
      .catch((err) => {
        updateAlert({
          open: true,
          type: "error",
          message: "Approval decision failed to update",
        });
      })
    reloadGridData()

  }


  function determineApproveFlag(rData) {
    if (
      !rData?.approval_info?.approved_by ||
      rData?.approval_info?.approved_by === ""
    ) {
      if (currentUser.id === rData?.approval_info?.approver_id) {
        var approve_flag = 1;
      } else if (currentUser.id === rData?.approval_info?.requested_by) {
        var approve_flag = 0;
      } else if (currentUser.user_role < 4) {
        var approve_flag = 1;
      }
    }
    setApproveFlag(approve_flag)
  }

  const handlePrint = function () {
    reportService.kappaReport(reportData).then((res) => {

      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(res.data).buffer])
      );

      const link = document.createElement("a");
      link.href = url;
      const reportNumber = reportData?.study_info[0]?.report_number || 'Attribute Report';
      link.setAttribute("download", `${reportNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ backgroundColor: "#fff" }}
      ref={componentRef}
      style={{ marginLeft: 10 }}
    >

      <Grid
        item
        xs={12}
        direction="row"
        justifyContent="center"
        alignItems="left"
        style={{ marginBottom: 20 }}
      >
        {approveFlag === 1 && (

          <Button
            //className={classes.button}
            variant="outlined"
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={() => { ApproveORReject(2) }}
            style={{ marginRight: 9 }}
          >
            APPROVE
          </Button>
        )}

        {approveFlag === 1 && (
          <Button
            //className={classes.button}
            variant="outlined"
            color="secondary"
            startIcon={<CancelOutlinedIcon />}
            onClick={() => { ApproveORReject(3); }}
          >
            REJECT
          </Button>
        )}


        <Button
          variant="outlined"
          color="primary"
          startIcon={<CloudDownloadOutlinedIcon />}
          style={{ margin: 2, marginRight: 4 }}
          onClick={handlePrint}
          disabled={disabledDownload}
        >
          Download
        </Button>
      </Grid>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="left"
        sx={{ backgroundColor: "#fff" }}
        ref={componentRef}
      >

        <Grid
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="left"
          style={{ marginBottom: 3 }}
        >
          <StaticHeader reportData={reportData} />
        </Grid>

        <Grid
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="left"
          style={{ marginBottom: 10 }}
        >
          <StaticPart reportData={reportData} />
        </Grid>

        <Grid
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="left"
          style={{ marginBottom: 10 }}
        >
          <DataInfo data_info={reportData?.data_info ?? []} />
        </Grid>

        <Grid
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="left"
          style={{ marginBottom: 10 }}
        >
          <ResultSummaryInfo result_summary_info={reportData} />
        </Grid>

        <Grid
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="left"
          style={{ marginBottom: 20 }}
        >
          <ResultInfo result_info={reportData?.result_info} />
        </Grid>




        <Grid
          item
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="left"
          style={{ marginBottom: 3 }}
        >
          <StaticApproval reportData={reportData} />
        </Grid>

      </Grid>
    </Grid>
  );
}
