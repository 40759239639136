import React from "react";
import { ResultRenderer } from "../../../../../components/cellRenderers/ResultRenderer";
import CommonGrid from "../../../../../components/CommonGrid";
import {
  Grid, Typography
} from "@material-ui/core";
import { minWidth } from "@material-ui/system";

export function ResultInfo({ result_info = [] }) {
  result_info?.pop();
  result_info?.shift();

  const columns = [
    {
      field: "parameter",
      headerName: "Parameter",
      With: 300,
    },
    {
      field: "requirments",
      headerName: "Requirement",
      With: 200,
    },
    {
      field: "actual_result",
      headerName: "Result",
      With: 100,
      valueGetter: (params) => parseFloat(params?.data?.actual_result ?? 0).toFixed(2)
    },
    {
      field: "conclusion",
      headerName: "Conclusion",
      minWidth: 400,
    },
    {
      field: "result",
      headerName: "Result",
      with: 200,
      cellRenderer: (params) => {
        return <ResultRenderer value={params?.data?.result} />;
      },
    },
  ];

  function onGridReady(params) {
    var eGridDiv = document.querySelector("#kapparesult");
    eGridDiv.style.width = "100%";
    eGridDiv.style.height = "200px";
    params?.api.setDomLayout();
  }
  return (

    <div style={{
      border: "1px solid black",
      boxSizing: "border-box",
      height: "100%",
    }}>
      <Typography variant="h4"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F8E6D8",
          padding: "5px",
        }}>
        <strong >
          Result
        </strong>
      </Typography>

      <div style={{ padding: "10px" }}>
        <CommonGrid
          // onGridReady={onGridReady}
          needExport={false}
          rows={result_info}
          columns={columns}
          gridheight={520}

        />

      </div>
    </div>
  );
}
