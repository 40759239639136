import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import AddIcon from "@material-ui/icons/Add";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { CToolIssue } from "./CuttingToolIssue";
import { CToolReturn } from "./CuttingToolReturn";
import CommonGrid from "../../../components/CommonGrid";
import { DGDrawer } from "../../../components/DGDrawer";
import cuttingToolService from "../../../services/cuttingToolService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { format } from "date-fns";




export function CuttingToolIssueList() {


    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { globalPlant } = useContext(PlantContext);
    const [rows, setRows] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [toolIssueForm, setToolIssueForm] = useState(false);
    const [toolReturnForm, setToolReturnForm] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const [rowSelected, setRowSelected] = useState(null);



    setHeaderPageTitle("Cutting Tool Issue")

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
        }));

        reloadGridData();
    }, []);

    useEffect(() => {
        reloadGridData()
    }, [globalPlant]);

    function reloadGridData() {

        cuttingToolService.getCuttingToolTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        }).then((response) => {
            setRows(response.data)
        })

    }


    const columns = [


        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 100,
        },

        {

            headerName: "Cutting Tool",
            field: "ctool_name",
            minWidth: 250,
        },

        {

            headerName: " Tool Number",
            field: "ctool_number",
            minWidth: 200,
        },



        {

            headerName: "Tool Life",
            field: "tool_life",
            minWidth: 250,
        },


        {
            headerName: "Issue Details",
            field: "issued_to",
            minWidth: 250,
            autoHeight: true,
            cellRenderer: (params) => {

                if (!params.value) {
                    return '';
                }
                return (
                    <>
                        {params?.data?.issued_to_name}
                        <br />
                        {params?.data?.issue_date
                            ? format(new Date(params.data.issue_date), "MM/dd/yyyy")
                            : "N/A"}
                        <br />
                        {params?.data?.issue_time
                            ? params.data.issue_time.substring(0, 5)
                            : "N/A"}


                    </>
                );

            },
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.3',
                alignContent: 'center'

            },

        },

        {
            headerName: "Returning Details",
            field: "returned_by",
            minWidth: 250,
            autoHeight: true,
            cellRenderer: (params) => {

                if (!params.value) {
                    return '';
                }
                return (
                    <>
                        {params?.data?.returned_name}
                        <br />
                        {params?.data?.issue_date
                            ? format(new Date(params.data.return_date), "MM/dd/yyyy")
                            : "N/A"}
                        <br />
                        {params?.data?.issue_time
                            ? params.data.return_time.substring(0, 5)
                            : "N/A"}


                    </>
                );

            },
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.3',
                alignContent: 'center'

            },

        },



        {
            headerName: "Details",
            field: "part_number",
            minWidth: 250,
            autoHeight: true,
            cellRenderer: (params) => {

                if (!params.value) {
                    return '';
                }
                return (
                    <>
                        {params?.data?.part_number}  {params?.data?.part_name}
                        <br />
                        {params?.data?.machine_number}  {params?.data?.machine_name}
                    </>
                );

            },
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.3',
                alignContent: 'center'

            },

        },


    ]

    function rowSelect(row) {
        if (!row[0].return_date) {
            setRowSelected(row[0])
            setToolReturnForm(true)
        }

    }


    function ButtonComponent() {
        return (

            <>

                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setToolIssueForm(true);
                    }}
                >
                    Issue Tool
                </Button>

            </>
        );
    }


    return (

        <Grid container spacing={2}>

            <Grid item xs={12}>
                <CommonGrid
                    rows={rows}
                    columns={columns}
                    gridApi={gridApi}
                    ButtonComponent={ButtonComponent}
                    gridheight={115}
                    onRowSelect={rowSelect}

                />
            </Grid>

            <DGDrawer
                isOpen={toolIssueForm}
                setOpen={() => {
                    setToolIssueForm(false)
                    reloadGridData()

                }}
                Component={CToolIssue}
                drawerWidth={600}
                title="Issue Cutting Tool"
                needSave

            />

            <DGDrawer
                isOpen={toolReturnForm}
                setOpen={() => {
                    setToolReturnForm(false)
                    reloadGridData()
                    setRowSelected(null)

                }}
                Component={CToolReturn}
                drawerWidth={600}
                title="Return Cutting Tool"
                needSave
                componentProps={rowSelected}

            />

        </Grid>


    )



}