import React, { useContext, useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import AddIcon from '@material-ui/icons/Add';
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../components/CommonGrid";
import { Button, Grid, Radio, RadioGroup, Tooltip, Box } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { format, startOfWeek, startOfMonth, endOfMonth, endOfWeek, set } from 'date-fns'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import qualityAnalyticsService from "../../../services/qualityAnalyticsService";
import { StackedBarGraph } from "../../../components/graphs/StackedBarGraphs";



export function DefectsSummary({
}) {


    const [dateDepth, setDateDepth] = useState(3);
    const [rows, setRows] = useState([]);
    const [rows1, setRows1] = useState([]);
    const [rowsTemp, setRowsTemp] = useState([]);
    const { globalPlant } = useContext(PlantContext);
    const [startDate, setstartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const { currentUser } = useContext(AuthContext);
    const [graphOptions, setGraphOptions] = useState({
        title: {
            text: `Defects Summary : ${globalPlant?.globalPlant?.name}`
        },

        series: [
            {
                yKey: "ppm",
                xKey: "row_number",
                type: "column",
            },
        ],
        legend: {
            enabled: false,
        },

    });


    const [graphOptions1, setGraphOptions1] = useState({
        title: {
            text: `Incident Summary : ${globalPlant?.globalPlant?.name}`
        },

        series: [
            {
                yKey: "ppm",
                xKey: "row_number",
                type: "column",
            },
        ],
        legend: {
            enabled: false,
        }


    });
    const [gridApi, setGridApi] = useState(null);
    const [gridApi1, setGridApi1] = useState(null);
    const [groupField, setGroupField] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);



    useEffect(() => {

        switch (dateDepth) {
            case 1:
                setstartDate(new Date())
                setendDate(new Date())

                break;

            case 2:
                setstartDate(startOfWeek(new Date(), { weekStartsOn: 1 }))
                if (endOfWeek(new Date(), { weekStartsOn: 1 }) > new Date()) {
                    setendDate(new Date())
                } else {
                    setendDate(endOfWeek(new Date(), { weekStartsOn: 1 }))
                }
                break;

            case 3:
                setstartDate(startOfMonth(new Date()))
                // setdataLabel("Month");

                if (endOfMonth(new Date(), { weekStartsOn: 1 }) > new Date()) {
                    setendDate(new Date())
                } else {
                    setendDate(endOfMonth(new Date(), { weekStartsOn: 1 }))
                }

                break;

            default:

                // setdataLabel("User Defined");

                break;
        }

    }, [dateDepth]);


    useEffect(() => {
        reloadGridData();
    }, [endDate, startDate])



    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.addEventListener('firstDataRendered', () => {
            if (params.api.getDisplayedRowCount() > 0) {
                processGraphData(params.api, 0);
            }
        });
    };


    function processGraphData(data1, flag) {
        let rawData
        if (flag === 0) {
            rawData = data1;
        }
        else {
            rawData = gridApi

        }

        const processedData = [];
        rawData.forEachNode((node, index) => {
            const data = node.data;
            const ppm = (data?.total_defect_quantity / data?.production_quantity) * 1000000;
            const fixedPpm = ppm ? parseFloat(ppm.toFixed(0)) : 0;

            const processedRow = {
                ppm: fixedPpm,
                part_name: data.part_name,
                part_number: data.part_number,
                machine_number: data.machine_number,
                operator: data.operator_name,
                part_id: data.part_id,
                machine_id: data.machine_id,
                operator_id: data.operator_id,
            };

            processedData.push(processedRow);
        });


        const sortedData = processedData
            .filter(item => item.ppm !== 0 && item.ppm !== null && item.ppm !== '')
            .sort((a, b) => b.ppm - a.ppm)
            .map((item, index) => ({
                ...item,
                row_number: index + 1,
            }));

        setGraphOptions({
            ...graphOptions,

            subtitle: {
                text:
                    `${format(new Date(startDate,), 'dd/MM/yy')} - ${format(new Date(endDate,), 'dd/MM/yy')}`
            },

            series: [
                {

                    yKey: "ppm",
                    xKey: "row_number",
                    type: "column",
                    tooltip: {
                        renderer: function (params) {
                            return (
                                '<div>' +
                                `<strong>Part:</strong> ${params.datum.part_number + '-' + params.datum.part_name || 'N/A'}<br />` +
                                `<strong>Machine:</strong> ${params.datum.machine_number || 'N/A'}<br />` +
                                `<strong>Operator:</strong> ${params.datum.operator || 'N/A'}<br />` +
                                `<strong>PPM:</strong> ${params.datum.ppm ? params.datum.ppm.toFixed(0) : 'N/A'}` +
                                '</div>'
                            );
                        },
                    },
                },
            ],




            data: sortedData ?? [],
        });
    }


    const onGridReady1 = (params) => {
        setGridApi1(params.api);
        params.api.addEventListener('firstDataRendered', () => {
            if (params.api.getDisplayedRowCount() > 0) {
                processGraphData1(params.api, 0);
            }
        });
    };


    function processGraphData1(data1, flag) {

        let rawData
        if (flag === 0) {
            rawData = data1;
        }
        else {
            rawData = gridApi1

        }
        const processedData = [];
        rawData.forEachNode((node, index) => {
            const data = node.data;
            const ppm = (data.total_defect_quantity / data.production_quantity) * 1000000;
            const fixedPpm = ppm ? parseFloat(ppm.toFixed(0)) : 0;
            const processedRow = {
                ppm: fixedPpm,
                part_name: data.part_name,
                part_number: data.part_number,
                machine_number: data.machine_number,
                operator: data.operator_name,
                defect_name: data.defect_name,
            };

            processedData.push(processedRow);
        });
        const sortedData = processedData
            .filter(item => item.ppm !== 0 && item.ppm !== null && item.ppm !== '')
            .sort((a, b) => b.ppm - a.ppm)
            .map((item, index) => ({
                ...item,
                row_number: index + 1,
            }));

        setGraphOptions1({
            ...graphOptions1,

            subtitle: {
                text:
                    `${format(new Date(startDate,), 'dd/MM/yy')} - ${format(new Date(endDate,), 'dd/MM/yy')}`
            },

            series: [
                {

                    yKey: "ppm",
                    xKey: "row_number",
                    type: "column",
                    tooltip: {
                        renderer: function (params) {
                            return (
                                '<div>' +
                                `<strong>Defect:</strong> ${params.datum.defect_name || 'N/A'}<br />` +
                                `<strong>Part:</strong> ${params.datum.part_number + '-' + params.datum.part_name || 'N/A'}<br />` +
                                `<strong>Machine:</strong> ${params.datum.machine_number || 'N/A'}<br />` +
                                `<strong>Operator:</strong> ${params.datum.operator || 'N/A'}<br />` +
                                `<strong>PPM:</strong> ${params.datum.ppm ? params.datum.ppm.toFixed(0) : 'N/A'}` +
                                '</div>'
                            );
                        },
                    },
                },
            ],

            data: sortedData ?? [],
        });

    }



    function onColumnRowGroupChanged(params) {

        const groupedColumns = params.columnApi.getRowGroupColumns();

        if (groupedColumns.length > 0) {
            const groupedField = groupedColumns[0].getColDef().field;
            setGroupField(groupedField);
        } else {
            setGroupField(null);
            setRows1(rowsTemp);
        }



        const rowGroupColumns = params.columnApi.getAllGridColumns().filter(column => column.isRowGroupActive());
        if (rowGroupColumns.length === 1) {
            const visibleRows = [];
            params.api.forEachNodeAfterFilterAndSort((node) => {
                if (node.group) {
                    visibleRows.push({
                        row_number: node.key,
                        production_quantity: node.aggData?.production_quantity,
                        total_defect_quantity: node.aggData?.total_defect_quantity,
                        ppm: node.aggData?.total_defect_quantity / node.aggData?.production_quantity * 1000000,
                        level: node.level,
                        part_id: node.childrenAfterGroup?.[0]?.data?.part_id || null,
                        machine_id: node.childrenAfterGroup?.[0]?.data?.machine_id || null,
                        operator_id: node.childrenAfterGroup?.[0]?.data?.operator_id || null,
                    });
                }

            });

            visibleRows.sort((a, b) => {
                const ppmA = Number(a.ppm) || 0;
                const ppmB = Number(b.ppm) || 0;
                return ppmB - ppmA;
            });



            setGraphOptions({
                ...graphOptions,

                series: [
                    {
                        yKey: "ppm",
                        xKey: "row_number",
                        type: "column",
                        tooltip: {
                            renderer: function (params) {
                                return (
                                    '<div>' +
                                    `<strong>PPM:</strong> ${params.datum.ppm ? params.datum.ppm.toFixed(0) : 'N/A'}` +
                                    '</div>'
                                );
                            },
                        },
                        listeners: {
                            nodeClick: (event) => {
                                setSelectedGroup(event.datum);

                            },
                        }
                    }],
                data: visibleRows,

            });
        }

        else { processGraphData() }

    };



    function onColumnRowGroupChanged1(params) {

        const groupedColumns = params.columnApi.getRowGroupColumns();

        if (groupedColumns.length > 0) {
            const groupedField = groupedColumns[0].getColDef().field;
            setGroupField(groupedField);
        } else {
            setGroupField(null);
            setRows1(rowsTemp);
        }

        const rowGroupColumns = params.columnApi.getAllGridColumns().filter(column => column.isRowGroupActive());
        if (rowGroupColumns.length === 1) {
            const visibleRows = [];
            params.api.forEachNodeAfterFilterAndSort((node) => {
                if (node.group) {
                    visibleRows.push({
                        row_number: node.key,
                        ppm: node.aggData?.total_defect_quantity / node.aggData?.production_quantity * 1000000,
                        level: node.level,
                    });
                }

            });

            visibleRows.sort((a, b) => {
                const ppmA = Number(a.ppm) || 0;
                const ppmB = Number(b.ppm) || 0;
                return ppmB - ppmA;
            });



            setGraphOptions1({
                ...graphOptions,

                series: [
                    {
                        yKey: "ppm",
                        xKey: "row_number",
                        type: "column",
                        tooltip: {
                            renderer: function (params) {
                                return (
                                    '<div>' +
                                    `<strong>PPM:</strong> ${params.datum.ppm ? params.datum.ppm.toFixed(0) : 'N/A'}` +
                                    '</div>'
                                );
                            },
                        },

                    }],
                data: visibleRows,

            });
        }

        else { processGraphData1() }

    };



    useEffect(() => {

        if (groupField && selectedGroup) {
            let filteredRows = [];

            if (groupField === 'part_number') {
                filteredRows = rowsTemp.filter(item => item.part_id === selectedGroup.part_id);
            } else if (groupField === 'machine_name') {
                filteredRows = rowsTemp.filter(item => item.machine_id === selectedGroup.machine_id);
            } else if (groupField === 'operator_name') {
                filteredRows = rowsTemp.filter(item => item.operator_id === selectedGroup.operator_id);
            } else {
                filteredRows = rowsTemp;
            }

            setRows1(filteredRows);
        }



    }, [selectedGroup])



    function reloadGridData() {
        setRows([]);
        setRows1([]);
        qualityAnalyticsService.getDefectSummaryTable({
            client_id: currentUser.client_id,
            plant_id: globalPlant?.globalPlant?.id,
            start_date: format(new Date(startDate), 'yyyy-MM-dd'),
            end_date: format(new Date(endDate), 'yyyy-MM-dd'),
        }
        )
            .then((res) => {
                const temp = res.data
                if (!temp) {
                    return;
                }
                setRows(temp?.defect_data1 || [])
                setRows1(temp?.defect_data2 || [])
                setRowsTemp(temp?.defect_data2 || [])
            })
    }



    const columns = [
        {
            field: "part_number",
            headerName: "Part Name",
            minWidth: 250,
            sorting: true,
            enableRowGroup: true,
            showRowGroup: true,

            valueGetter: (params) => {
                if (params.data) {
                    return `${params.data.part_number || ''} - ${params.data.part_name || ''}`;
                }
                return null;
            },

            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                }
                return params?.data
                    ? `${params.data.part_number || ''} - ${params.data.part_name || ''}`
                    : '';
            },
        },


        {
            field: "total_defect_quantity",
            headerName: "Rejection Quantity",
            resizable: true,
            minWidth: 250,
            aggFunc: "sum",
            enableValue: true,
        },
        {
            field: "production_quantity",
            headerName: "Production Quantity",
            resizable: true,
            minWidth: 150,
            aggFunc: "sum",
            enableValue: true,

        }
        ,
        {
            field: "ppm",
            headerName: "PPM",
            resizable: true,
            sortable: true,
            minWidth: 150,
            sort: "desc",
            valueGetter: (params) => {
                if (params.node.group) {
                    let totalDefects = 0;
                    let totalProduction = 0;

                    params.node.allLeafChildren.forEach((childNode) => {
                        const { total_defect_quantity, production_quantity } = childNode.data || {};
                        const parsedTotalDefectQuantity = parseInt(total_defect_quantity, 10) || 0;

                        totalDefects += parsedTotalDefectQuantity || 0;
                        totalProduction += production_quantity || 0;
                    });

                    if (totalProduction === 0) {
                        return '';
                    }

                    const ppm = (totalDefects / totalProduction) * 1000000;
                    return parseFloat(ppm.toFixed(0));

                } else if (params.data) {
                    const { total_defect_quantity, production_quantity } = params.data;
                    if (!production_quantity || production_quantity === 0) {
                        return '';
                    }
                    const ppm = (total_defect_quantity / production_quantity) * 1000000;
                    return parseFloat(ppm.toFixed(0));
                }
            },
            cellRenderer: (params) => {
                if (params.value === '') {
                    return '';
                }
                return `${params.value}`;
            },
            enableValue: true,
        },
        {
            field: "machine_name",
            headerName: "Machine",
            minWidth: 150,
            sorting: true,
            enableRowGroup: true,
            showRowGroup: true,
            valueGetter: (params) => {
                if (params.data) {
                    return `${params.data.machine_number || ''} - ${params.data.machine_name || ''}`;
                }
                return null;
            },
            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                }
                return params?.data
                    ? `${params.data.machine_number || ''} - ${params.data.machine_name || ''}`
                    : '';
            },
        }
        ,
        {
            field: "operator_name",
            headerName: "Operator ID",
            resizable: true,
            // filter: "agMultiColumnFilter",
            enableRowGroup: true,
            minWidth: 150,
            sortable: true,
            showRowGroup: true,
            valueGetter: (params) => {
                if (params.data) {
                    return `${params.data.operator_name || ''} - ${params.data.operator_eid || ''}`;
                }
                return null;
            },

            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                }
                return params?.data
                    ? `${params.data.operator_name || ''} - ${params.data.operator_eid || ''}`
                    : '';
            },

        },
        {
            field: "production_date",
            headerName: "Date:Production",
            flex: 1,
            editable: false,
            resizable: true,
            columnGroupShow: 'null',
            minWidth: 150,

            sortable: true,
            enableRowGroup: true,
            valueGetter: (params) => {
                return params?.data?.production_date;
            },
            valueFormatter: (params) => {
                return params.value ? format(new Date(params.value), 'dd/MM/yyyy') : '';
            },
        },
    ]

    const columns1 = [


        {

            field: "defect_name",
            headerName: "Defect Name",
            minWidth: 250,
            sorting: true,
            enableRowGroup: true,
            valueGetter: (params) => {
                if (params.data) {
                    return params.data.defect_name || ''
                }
                return null;
            },

            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                }
                return params?.data
                    ? params.data.defect_name
                    : '';
            },
        },



        {
            field: "total_defect_quantity",
            headerName: "Rejection Quantity",
            resizable: true,
            minWidth: 250,
            aggFunc: "sum",
            enableValue: true,
        },

        {
            field: "production_quantity",
            headerName: "Production Quantity",
            resizable: true,
            minWidth: 150,
            aggFunc: "sum",
            enableValue: true,

        }

        ,


        {
            field: "ppm",
            headerName: "PPM",
            resizable: true,
            minWidth: 150,
            sort: "desc",
            valueGetter: (params) => {
                if (params.node.group) {
                    let totalDefects = 0;
                    let totalProduction = 0;


                    params.node.allLeafChildren.forEach((childNode) => {
                        const { total_defect_quantity, production_quantity } = childNode.data || {};

                        totalDefects += total_defect_quantity || 0;
                        totalProduction += production_quantity || 0;
                    });

                    if (totalProduction === 0) {
                        return '';
                    }

                    const ppm = (totalDefects / totalProduction) * 1000000;
                    return parseFloat(ppm.toFixed(0));
                } else if (params.data) {
                    const { total_defect_quantity, production_quantity } = params.data;
                    if (!production_quantity || production_quantity === 0) {
                        return '';
                    }
                    const ppm = (total_defect_quantity / production_quantity) * 1000000;
                    return parseFloat(ppm.toFixed(0));


                }
            },

            cellRenderer: (params) => {
                if (params.value === '') {
                    return '';
                }
                return `${params.value}`;
            },
            enableValue: true,
        },
        {

            field: "part_number",
            headerName: "Part Name",
            minWidth: 250,
            sorting: true,
            //   enableRowGroup: true,
            valueGetter: (params) => {
                if (params.data) {
                    return `${params.data.part_number || ''} - ${params.data.part_name || ''}`;
                }
                return null;
            },

            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                }
                return params?.data
                    ? `${params.data.part_number || ''} - ${params.data.part_name || ''}`
                    : '';
            },


        },


        {
            field: "machine_name",
            headerName: "Machine",
            minWidth: 150,
            sorting: true,
            //   enableRowGroup: true,
            valueGetter: (params) => {
                if (params.data) {
                    return `${params.data.machine_number || ''} - ${params.data.machine_name || ''}`;
                }
                return null;
            },
            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                }
                return params?.data
                    ? `${params.data.machine_number || ''} - ${params.data.machine_name || ''}`
                    : '';
            },
        }


        ,
        {
            field: "operator_name",
            headerName: "Operator ID",
            resizable: true,
            // filter: "agMultiColumnFilter",
            //  enableRowGroup: true,
            minWidth: 150,
            sortable: true,
            valueGetter: (params) => {
                if (params.data) {
                    return `${params.data.operator_name || ''} - ${params.data.operator_eid || ''}`;
                }
                return null;
            },

            cellRenderer: (params) => {
                if (params.node.group) {
                    return params.value;
                }
                return params?.data
                    ? `${params.data.operator_name || ''} - ${params.data.operator_eid || ''}`
                    : '';
            },

        },

        {
            field: "production_date",
            headerName: "Date:Production",
            flex: 1,
            editable: false,
            resizable: true,
            columnGroupShow: 'null',
            minWidth: 150,
            sortable: true,
            //   enableRowGroup: true,
            valueGetter: (params) => {
                return params?.data?.production_date;
            },
            valueFormatter: (params) => {
                return params.value ? format(new Date(params.value), 'dd/MM/yyyy') : '';
            },
        },


    ]

    return (
        <Grid container spacing={2}>


            <Grid item xs={6}
                style={{
                    "margin-top": "10px",
                    "height": "500px"

                }
                }>
                <StackedBarGraph
                    options={graphOptions}
                />

            </Grid>

            <Grid item xs={6}
                style={{
                    "margin-top": "10px",
                    "height": "500px"

                }
                }>
                <StackedBarGraph
                    options={graphOptions1}
                />

            </Grid>

            <Grid item xs={12} container justifyContent="center" alignItems="flex-left">
                <RadioGroup row
                    style={{ gap: '12px' }}
                    value={dateDepth.toString()}
                    onChange={(event) => {
                        const newValue = parseInt(event.target.value);
                        setDateDepth(newValue);
                    }}
                >
                    {/*                     <FormControlLabel value="1" control={<Radio />} label="Day" />
 */}                    <FormControlLabel value="2" control={<Radio />} label="Week" />
                    <FormControlLabel value="3" control={<Radio />} label="Month" />
                    <FormControlLabel value="4" control={<Radio />} label="User Defined" />
                </RadioGroup>

                <Grid
                    item
                    xs={5}
                    container
                    alignItems="flex-start"
                    spacing={4}
                //justifyContent="space-between"
                >

                    <Grid item xs={5}>
                        <DGDateInput
                            type="date"
                            label="Start Date"
                            value={startDate}
                            onChange={(date) => {
                                setstartDate(date)
                                setDateDepth(4);
                            }}
                            disabled={dateDepth < 4}
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <DGDateInput
                            type="date"
                            label="End Date"
                            value={endDate}
                            onChange={(date) => {
                                setendDate(date);
                            }}
                            disabled={dateDepth < 4}
                        />
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12}>
                <CommonGrid

                    rows={rows}
                    columns={columns}
                    rowGroupPanelShow={'always'}
                    showOpenedGroup={true}
                    suppressRowSticky={true}
                    gridheight={200}
                    groupDisplayType={"singleColumn"}
                    suppressAggFuncInHeader={true}
                    suppressAggAtRootLevel={true}
                    onGridReady={onGridReady}
                    onColumnRowGroupChanged={onColumnRowGroupChanged}
                    onRowDataUpdated={processGraphData}
                    tableName="Defect Summary"


                />

            </Grid>


            <Grid item xs={12}>
                <CommonGrid

                    rows={rows1}
                    columns={columns1}
                    rowGroupPanelShow={'always'}
                    showOpenedGroup={true}
                    suppressRowSticky={true}
                    gridheight={200}
                    groupDisplayType={"multipleColumns"}
                    suppressAggFuncInHeader={true}
                    suppressAggAtRootLevel={true}
                    onGridReady={onGridReady1}
                    onColumnRowGroupChanged={onColumnRowGroupChanged1}
                    onRowDataUpdated={processGraphData1}
                    tableName="Incident Summary"

                />

            </Grid>

        </Grid>
    )

}