import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import { DGDrawer } from "../../../../components/DGDrawer";
import productionInspection from "../../../../services/productionInspection";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { useEffect } from "react";
import { PITrendDetail } from "./PITrendDetail";
import { format, startOfMonth, sub, subMonths } from 'date-fns'
import { DGSelect } from "../../../../components/shared/DGSelect";
import { GlobalSpinner } from "../../../../components/shared/GlobalSpinner";
import Loader from "../../../../components/Loader";

export function PITrendList() {
    const [rows, setRows] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [plantFlag, setPlantFlag] = useState(0)
    const [formOpen, setFormOpen] = useState(false);
    const [subtitle, setSubtitle] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const [mainTitle, setMainTitle] = useState(null);
    const [subTitle, setSubTitle] = useState(null);
    const [monthSelected, setMonthSelected] = useState(3)
    const [startDate, setStartDate,] = useState(null)
    //   const [endDate, setEndDate] = useState(new Date());
    const [gridApi, setGridApi] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(true);

    useEffect(() => {
        if (globalPlant.globalPlant.id === currentUser?.client_id) {
            setPlantFlag(1)
        }
    }, [globalPlant]);




    const onGridReady = (params) => {
        setGridApi(params.api);
        if (!rows) {
            params.api.showLoadingOverlay();
        }
    };


    useEffect(() => {
        if (!rows) {
            return
        }
        const tempDate = subMonths(new Date(), monthSelected)
        setStartDate(startOfMonth(tempDate))
    }, [monthSelected])

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadgridData,
        }));
        if (globalPlant && globalPlant?.globalPlant?.id) {
            reloadgridData();
        }
    }, [startDate, globalPlant]);

    function reloadgridData() {

        if (startDate === null) {
            return
        }

        productionInspection
            .getPITrendList({
                client_id: currentUser?.client_id,
                query_id: globalPlant?.globalPlant?.id,
                start_date: startDate,
                //end_date: endDate

            })
            .then((res) => {
                setRows(res?.data ?? []);
                setDataLoaded(false);
            });
    }

    const columns = [
        {
            headerName: "Sl No.",
            valueGetter: "node.rowIndex + 1",
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            maxWidth: 105,
            pinned: "left",
        },
        {
            field: "part_number",
            headerName: "Part Number",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            // sort: "asc",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => {
                return (
                    `${params?.data?.part_number}-${params?.data?.part_name}`
                )
            }
        },
        {
            headerName: "Specification",
            field: "character_name",
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            minWidth: 200,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            LoadDetailForm(params?.data);
                            titleDetails(params?.data);
                        }}>

                        {`${params?.data?.character_name} ${"  "}
                        ${params?.data?.specification ? params.data.specification : ""}
                        ${params?.data?.upper_specification ? params.data.upper_specification : ""}
                        ${params?.data?.lower_specification ? params.data.lower_specification : ""} `}
                    </Button>
                );
            },

        },

        /*         {
                    headerName: "Evaluation Count",
                    resizable: true,
                    field: "count_character",
                    minWidth: 40,
                },
        */
        {
            headerName: "Latest Date",
            field: "latest_date",
            sort: "desc",
            valueGetter: (params) => {
                if (params?.data?.latest_date) {
                    return new Date(params?.data?.latest_date);
                }
                return null;
            },
            valueFormatter: (params) => {
                if (params?.value) {
                    return format(new Date(params?.value), 'dd/MM/yyyy');
                }
                return "";
            },
        },
    ];


    function LoadDetailForm(data) {
        const updatedData = { ...data };
        updatedData.only_graph = 0;
        setSelectedRow(updatedData);
        setFormOpen(true);
    }

    function titleDetails(data) {
        setMainTitle(
            `${data?.character_name} ${"  "}
                        ${data?.specification ? data.specification : ""}
                        ${data?.upper_specification ? data.upper_specification : ""}
                        ${data?.lower_specification ? data.lower_specification : ""} `)
        setSubTitle(`${data?.part_number}-${data?.part_name}`)

    }

    const monthOptions = [
        { value: 3, label: "3 Months" },
        { value: 6, label: "6 Months" },
        { value: 12, label: "12 Months" },
        { value: 100, label: "All" }
    ];

    const handleChangeMonth = (selectedOption) => {
        setMonthSelected(selectedOption.value);
        const tempDate = subMonths(new Date(), selectedOption.value)
        //console.log("tempDate", tempDate)
        setStartDate(startOfMonth(tempDate))
    };

    function ButtonComponent() {
        return (
            <Grid item xs={6} >
                <DGSelect
                    id="MonthsData"
                    value={monthOptions.find(option => option.value === monthSelected)}
                    onChange={handleChangeMonth}
                    options={monthOptions}

                    getOptionLabel={(opt) => opt?.label || ""}
                />
            </Grid>


        )
    }

    if (dataLoaded) {
        return <Loader />;
    }


    return (
        <>
            <CommonGrid
                rows={rows}
                columns={columns}
                gridheight={115}
                HeaderComponent={ButtonComponent}
                onGridReady={onGridReady}
            // onRowSelect={onRowSelection}
            />

            <DGDrawer
                Component={PITrendDetail}
                title={subTitle}
                //subtitle={}
                isOpen={formOpen}
                setOpen={(state) => {
                    setSubtitle("");
                    setFormOpen(state);
                }}

                drawerWidth={1200}
                componentProps={selectedRow}
            />
        </>
    );
}

var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
};
