import http from "./httpService";

const createProductionInspectionPlan = (data) => {
  return http.post("/production-inplan-initiate", data);
};

const getProductionFilter = (data) => {
  return http.get("/prod-inspect-select", { params: { ...data } });
};

const createProductionInspection = (data) => {
  return http.post("/prod-inspect-request", data);
};

const updateProductionInspectionRequest = async (id, newStatus) => {
  return http.patch(`/production-inspection/${id}`, newStatus);
};

const updateProductionInspection = async (id, comments) => {
  return http.patch(`/production-inspection/${id}`, comments);
};

const getInspectionRequest = (request_id) => {
  return http.get("/prod-inspect-measure", {
    params: { request_id: request_id },
  });
};

const getInspectionReport = (request_id) => {
  return http.get("/prod-inspect-report", {
    params: { request_id: request_id },
  });
};

const getInspectTablePlant = (data) => {
  return http.get("/prod-inspect-plant-table", { params: { ...data } });
};

const getInspectPartPlan = (part_id) => {
  return http.get("/prod-inspect-part-plan", {
    params: {
      part_id: part_id,
    },
  });
};

const getInspectPartResult = (part_id) => {
  return http.get("/prod-inspect-part-result", {
    params: {
      part_id: part_id,
    },
  });
};

const getInspectCharResult = (data) => {
  return http.get("/prod-inspect-char-result", { params: { ...data } })
}

const getInspectCharChart = (character_id) => {
  return http.get("/prod-inspect-char-graph", {
    params: {
      character_id: character_id,
    },
  });
};

const getInspectionPlanResult = (client_id, plant_id) => {
  return http.get("/prod_inspect_plant_result", {
    params: {
      client_id: client_id,
      plant_id: plant_id,
    },
  });
};

const createInspectionRecord = (data) => {
  return http.post("/prod-inspect-request-record", { request_id: data });
};

const createInspectionEvaluate = (data) => {
  return http.post("/prod-inspect-request-evaluate", data);
};

const getInspectPlanView = (production_inspection_plan_id) => {
  return http.get("/prod-inspect-plan-view", {
    params: {
      production_inspection_plan_id: production_inspection_plan_id,
    },
  });
};

const updateInspectionPlan = async (id, newStatus) => {
  return http.patch(`/production-inspection-plan/${id}`, newStatus);
};

const getPDCharGraph = (data) => {
  return http.get("/prod-inspect-char-graph", { params: { ...data } })
}

const getInspectionPlanChar = (part_id) => {
  return http.get("/prod-inspect-select1", {
    params: {
      part_id: part_id,
    },
  });
};

const getInspectionPlanCharGauge = (
  character_id,
  product_char_flag,
  client_id
) => {
  return http.get("/prod-inspect-select2", {
    params: {
      character_id: character_id,
      product_char_flag: product_char_flag,
      client_id: client_id,
    },
  });
};

const getInspectionPlan = (part_id) => {
  return http.get("/prod-inspect-select3", {
    params: {
      part_id: part_id,
    },
  });
};

const getDefectCatalogue = (part_id) => {
  return http.get("/prod-inspect-catalogue", {
    params: {
      part_id: part_id,
    },
  });
};

const getCharacterSpindle = (character_id) => {
  return http.get("/prod-inspection-select1", {
    params: {
      character_id: character_id,
    },
  });
};

const getPITrendList = (data) => {
  return http.get("/production-inspection-charlist", { params: { ...data } })
}

const getPITrendList2 = (data) => {
  return http.get("/production-inspection-charlist2", { params: { ...data } })
}


export default {
  createProductionInspectionPlan,
  getProductionFilter,
  createProductionInspection,
  getInspectionRequest,
  getInspectTablePlant,
  getInspectPartPlan,
  getInspectPartResult,
  getInspectCharResult,
  getInspectCharChart,
  getInspectionPlanResult,
  createInspectionRecord,
  createInspectionEvaluate,
  getInspectPlanView,
  updateInspectionPlan,
  updateProductionInspectionRequest,
  getPDCharGraph,
  getInspectionPlanChar,
  getInspectionPlanCharGauge,
  getInspectionPlan,
  getDefectCatalogue,
  getCharacterSpindle,
  getPITrendList,
  getPITrendList2,
  updateProductionInspection,
  getInspectionReport
};
